import { useEffect } from 'react';
import { useRouter } from 'next/compat/router';

// This hook should've been used to restore scroll state after clicking browser back button
// It should've been used on pages such as SSR -- as SSG seems to have it work correctly and calling this hook these pages may result in unexpected behaviour
export const useScrollRestoration = () => {
  const router = useRouter();
  useEffect(() => {
    router.beforePopState((state) => {
      state.options.scroll = false;
      return true;
    });
  }, []);
};
