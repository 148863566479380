import { useHeaderContext } from '../../../context/HeaderContext';
import { TestId } from '../../../enums';
import { useKeyPress } from '../../../hooks';
import { cn, openDesktopNavToDataLayer } from '../../../utils';
import { normalizeNavigationItem } from '../../../utils/normalizeNavigationItem';
import ListContainer from '../../common/ui/ListContainer';

import DesktopOverflow, { maxItemsBeforeOverflow } from './DesktopOverflow';
import Level1Item from './Menu/Level1Item';
import Level2Menu from './Menu/Level2Menu';
import { useMenuContext } from './Menu/MenuContext';

import type { FeaturedColumnProps, NavigationItem } from '../../../types';

type Props = {
  className?: HTMLElement['className'];
  navExpanded: boolean;
  navigation: NavigationItem[];
  featuredColumn: FeaturedColumnProps;
  secondaryNavigation: NavigationItem[][];
  handleNavExpanded: (expanded: boolean) => void;
};

/**
 * Renders the desktop version of the mega navigation component.
 */
const MegaNavDesktop = ({
  className,
  navExpanded,
  navigation,
  featuredColumn,
  secondaryNavigation,
  handleNavExpanded,
}: Props) => {
  const { handleClose } = useMenuContext();
  useKeyPress('Escape', handleClose);

  if (!Array.isArray(navigation)) {
    console.error('MegaNavDesktop: navigation is not an array');
    return null;
  }

  const handleOverflowOpen = () => {
    handleNavExpanded(true);
  };

  const handleOverflowClose = () => {
    handleNavExpanded(false);
  };

  const secondaryNavLists: [NavigationItem[], boolean][] = secondaryNavigation.map((navList) => [
    navList,
    false,
  ]);

  return (
    <div id="mega-nav-desktop" className={className}>
      <nav onMouseEnter={() => openDesktopNavToDataLayer()} onMouseLeave={handleClose}>
        <ul className="relative flex list-none flex-row items-center">
          {/* Level 1 Navigation Items */}
          <MegaNavListItems
            navigation={navigation}
            featuredColumn={featuredColumn}
            handleOverflowOpen={handleOverflowOpen}
            handleOverflowClose={handleOverflowClose}
          />
          {/* Overflow Menu */}
          <Level1Item
            name="More"
            className="relative p-0 2xl:relative"
            icon="chevron-down"
            menuAlign="right"
            iconClassName="scale-50"
            handleOverflowOpen={handleOverflowOpen}
            handleOverflowClose={handleOverflowClose}
          >
            <ListContainer data-testid={TestId.OverflowMenu}>
              <DesktopOverflow
                lists={[[navigation, true], ...secondaryNavLists]}
                expanded={navExpanded}
              />
            </ListContainer>
          </Level1Item>
        </ul>
      </nav>
    </div>
  );
};

type ListItemProps = {
  handleOverflowOpen: () => void;
  handleOverflowClose: () => void;
} & Pick<Props, 'navigation' | 'featuredColumn'>;

/**
 * Renders the MegaNav items for the desktop version (excl. "More").
 */
const MegaNavListItems = ({
  navigation,
  featuredColumn,
  handleOverflowOpen,
  handleOverflowClose,
}: ListItemProps) => {
  const { homeLink } = useHeaderContext();
  if (!Array.isArray(navigation)) return null;

  return (
    <>
      {navigation.map((lvl1Item: NavigationItem, index: number) => {
        const formattedItem = normalizeNavigationItem(lvl1Item);

        const config = lvl1Item?.desktop ?? null;
        const displayLvl1Icons = config?.decorators?.lvl1Decorator === 'icon';
        const displayLvl2Icons = config?.decorators?.lvl2Decorator === 'icon';

        const lvl1Name = lvl1Item?.nameSEO ?? lvl1Item?.name;
        const lvl1Icon = displayLvl1Icons ? lvl1Item?.icon : undefined;

        const hasChildren = Array.isArray(lvl1Item?.children) ? !!lvl1Item.children.length : false;

        return (
          <Level1Item
            key={lvl1Item.name}
            {...formattedItem}
            icon={lvl1Icon}
            iconSize={16}
            handleOverflowOpen={() => {
              if (!hasChildren) return;
              handleOverflowOpen();
            }}
            handleOverflowClose={() => {
              if (!hasChildren) return;
              handleOverflowClose();
            }}
            className={cn('hidden', {
              'lg:block': index < maxItemsBeforeOverflow.lg,
              'xl:block': index < maxItemsBeforeOverflow.xl,
              '2xl:block': index < maxItemsBeforeOverflow['2xl'],
            })}
          >
            {hasChildren && (
              <ListContainer noPadding>
                <Level2Menu
                  item={formattedItem}
                  displayIcon={displayLvl2Icons}
                  homeLink={homeLink}
                  parentNameList={[lvl1Name]}
                  featuredColumn={featuredColumn}
                />
              </ListContainer>
            )}
          </Level1Item>
        );
      })}
    </>
  );
};

export default MegaNavDesktop;
