export { Provider } from 'urql';
import {
  // https://formidable.com/open-source/urql/docs/architecture/#the-exchanges
  Client,
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from '@urql/core';
import { retryExchange } from '@urql/exchange-retry';

import retry from './retry.config';

export const createUrqlClient = (): Client =>
  createClient({
    url: process.env.NEXT_PUBLIC_PRODUCTS_API_URL,
    exchanges: [dedupExchange, cacheExchange, retryExchange(retry), fetchExchange],
    fetchOptions: () => {
      return {
        headers: {
          // https://www.apollographql.com/docs/studio/metrics/client-awareness/
          'apollographql-client-name': 'web-frontend-client',
          'apollographql-client-version': '1',
        },
      };
    },
  });
