import { useRouter } from 'next/router';
import { useRef, useEffect, type CSSProperties } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../../layout/Button';
import { SideDrawer } from '../../layout/SideDrawer';
import { IconSquircle } from '../../icons/IconSquircle';
import { LinkButton } from '../../navigation/LinkButton';
import { DisplayHeading } from '../../typography/DisplayHeading';

import { StepIndicator } from './components/StepIndicator';
import { GiftFinderCard } from './components/GiftFinderCard';
import { GiftFinderTrack } from './components/CardTrack';

import { useGiftFinderContext } from './context/GiftFinderContext';

type TransformMap = Record<number, string>;

const SQUIRCLE_TRANSFORM_MAP: TransformMap = Object.freeze({
  0: '50%',
  1: '35%',
  2: '20%',
});

enum AriaLabel {
  Close = 'close gift-finder',
  Back = 'back to previous step',
  Continue = 'continue to next step',
  Submit = 'redirect to gift-finder results',
}

export const GiftFinder = () => {
  const closeButtonRef = useRef<HTMLElement>(null);

  const router = useRouter();

  const { state, finalStep, resultsParams, handleOpen, handleClose, handleNextStep, handleSubmit } =
    useGiftFinderContext();
  const { active, title, quiz, step } = state;

  // focus on the close button when the gift-finder initially opens
  useEffect(() => {
    if (!closeButtonRef?.current) return;
    closeButtonRef.current[active ? 'focus' : 'blur']?.();
  }, [active]);

  // ensure the gift-finder is closed when the user navigates away from the page
  useEffect(() => {
    router.events.on('routeChangeStart', handleClose);
  }, [router.events, handleClose]);

  // TODO LP-5065 this is a temporary custom event listener for the DY split-test
  useEffect(() => {
    function handleDyCustomEvent(e: CustomEvent) {
      const { detail } = e;
      if (!detail?.location) console.error('[Gift Finder]: Location missing in DY Custom Event');
      else handleOpen(detail.location);
    }

    document.addEventListener('gift-finder-open', handleDyCustomEvent);
    return () => {
      document.removeEventListener('gift-finder-open', handleDyCustomEvent);
    };
  }, [handleOpen]);

  return (
    <SideDrawer
      style={
        {
          '--gf-width': 'min(100vw, 440px)',
          '--gf-gutter': '32px',
          '--gf-card-width': 'calc(var(--gf-width) - (var(--gf-gutter) * 2))',
        } as CSSProperties
      }
      className="bg-background-primary-faded-lighter max-w-[var(--gf-width)] overflow-hidden md:max-w-[var(--gf-width)]"
      headerClass="py-6 px-8 md:px-8 bg-transparent"
      contentClass="p-0 md:p-0"
      allowEventDefault
      drawerTitle={DrawerTitle}
      hideDrawer={handleClose}
      closeIconColor="var(--text-neutral-strong)"
      isOpen={active}
      stickRight
      tabIndex={active ? 0 : -1}
    >
      <section
        className={cn(
          'grid h-full w-[inherit] grid-cols-[var(--gf-width)] overflow-hidden p-0',
          title ? 'grid-rows-[auto_1fr_auto]' : 'grid-rows-[1fr_auto]'
        )}
      >
        {!!title && (
          <header className="grid grid-cols-2 px-8 py-0">
            <DisplayHeading
              className="text-neutral col-start-1 col-end-3 font-semibold"
              size="2"
              leading="base"
            >
              {title}
            </DisplayHeading>
          </header>
        )}
        <form>
          <GiftFinderTrack>
            {quiz?.map((question) => {
              return <GiftFinderCard key={question.uuid} {...question} />;
            })}
          </GiftFinderTrack>
        </form>
        <footer className="flex flex-col flex-nowrap items-center gap-6 px-8 pb-6">
          <StepIndicator />
          {finalStep ? (
            <LinkButton
              className={styles.nextButton}
              href={`/gift-finder/results${resultsParams}`}
              variant="primary"
              size="xl"
              aria-label={AriaLabel.Submit}
              onClick={handleSubmit}
            >
              Find me a gift
            </LinkButton>
          ) : (
            <Button
              className={styles.nextButton}
              onClick={handleNextStep}
              variant="primary"
              size="xl"
              aria-label={AriaLabel.Continue}
            >
              Continue
            </Button>
          )}
        </footer>
        <IconSquircle
          className={cn('absolute bottom-0 left-1/2 -z-[1] w-[724px]')}
          style={{
            transform: `translate(-50%, ${SQUIRCLE_TRANSFORM_MAP?.[step] || '20%'}) rotate(40deg)`,
          }}
          fill="var(--background-primary-faded)"
        />
      </section>
    </SideDrawer>
  );
};

const styles = {
  nextButton: 'w-full',
};

const DrawerTitle = () => {
  const { state, handlePrevStep } = useGiftFinderContext();
  const { step } = state;

  return (
    <Button
      className={cn(
        'invisible flex items-center gap-1 self-start border-0 p-0 text-base leading-none hover:bg-[initial]',
        'text-neutral-faded focus:outline-text-neutral-strong cursor-pointer select-none font-semibold focus:outline-2',
        step > 0 && 'pointer-events-auto visible select-all'
      )}
      aria-label={AriaLabel.Back}
      variant="invisible"
      onClick={handlePrevStep}
    >
      <BackIcon />
      <span className="pointer-events-none select-none">Back</span>
    </Button>
  );
};

const BackIcon = ({ width = 7, height = 12 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85672 0.351784C7.08196 0.577099 7.2085 0.88265 7.2085 1.20125C7.2085 1.51984 7.08196 1.82539 6.85672 2.05071L2.90017 6.00725L6.85672 9.9638C7.07558 10.1904 7.19668 10.4939 7.19395 10.8089C7.19121 11.124 7.06485 11.4253 6.84208 11.6481C6.61931 11.8709 6.31796 11.9972 6.00293 12C5.6879 12.0027 5.3844 11.8816 5.15779 11.6627L0.351784 6.85672C0.126537 6.6314 0 6.32585 0 6.00725C0 5.68866 0.126537 5.38311 0.351784 5.15779L5.15779 0.351784C5.38311 0.126537 5.68866 0 6.00725 0C6.32585 0 6.6314 0.126537 6.85672 0.351784Z"
      fill="var(--text-neutral-faded)"
    />
  </svg>
);
