import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';

type Ref<T> = {
  current: T;
};

const createArrowDownEvent = () =>
  new KeyboardEvent('keydown', {
    key: 'ArrowDown',
    keyCode: 40,
    which: 40,
  });
const createEnterEvent = () =>
  new KeyboardEvent('keydown', {
    key: 'Enter',
    keyCode: 13,
    which: 13,
  });

/**
 * This hook is meant to take in a ref to a Google Places Autocomplete <input>
 * element and inject the following custom behavior relating to user controls:
 *
 * 1. Upon the user pressing Enter, the Autocomplete will automatically select
 * the first result displayed.
 * 2. Upon the user pressing an explicit Search button (exposed as a
 * selectFirstResult method), the Autocomplete will automatically select the
 * first result displayed.
 *
 * The chosen implementation is to hi-jack the event listeners that Autocomplete
 * registers and add some extra conditional behavior.
 * {@see https://stackoverflow.com/a/37166162}
 */
type UseControllableAutocompleteOptions = {
  /**
   * If true, pressing Enter will automatically select the first result.
   */
  selectFirstResultOnEnter?: boolean;
  /**
   * The minimum number of characters in the search string before a network
   * request is made.
   */
  minimumSearchLength?: number;
  /**
   * If set, user input is debounced with the given number (in milliseconds)
   * before a network request is made.
   */
  debounceTime?: number;
};
export const useControllableAutocomplete = (
  inputRef: Ref<HTMLInputElement>,
  options?: UseControllableAutocompleteOptions
) => {
  const [listenerContext, setListenerContext] = useState(null);

  /**
   * Once our inputRef renders, we want to hi-jack the listeners that Google
   * Places Autocomplete will register so that we can wrap them with our own
   * custom behavior.
   */
  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;

    const boundAddEventListener = input.addEventListener.bind(input);

    /**
     * Prepare an alternate version of addEventListener that wraps listeners
     * with custom behavior before registering them.
     */
    const wrappedAddEventListener = (type, listener) => {
      const boundListener = listener.bind(input);

      // general case: wrapped listener has no custom behavior
      let wrappedListener = listener;

      // special cases
      switch (type) {
        // keydown listener: in charge of Enter and ArrowDown presses
        case 'keydown': {
          /**
           * Save context for the keydown listener, so we can trigger it on-demand
           * with {@link selectFirstResult}.
           */
          if (!listenerContext) setListenerContext({ input, listener });

          /**
           * Wrap the keydown listener in some custom behavior:
           * - selectFirstResultOnEnter: if the Enter key is pressed without a
           *   previous result being selected, automatically select the first
           *   result.
           *
           * This is done by simply simulating the first arrow down keypress,
           * because Google Places Autocomplete already knows how to handle that.
           *
           * The existence of a selected result is based on the .pac-item-selected
           * class, which is publicly documented and exposed by the Google Places
           * Autocomplete API (albeit technically for styling purposes).
           * {@see https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete}
           */
          wrappedListener = (event) => {
            // exception case: if the user presses Enter but the input is empty, do nothing.
            if (event.which === 13 && !input.value) return;

            /**
             * Case: selectFirstResultOnEnter
             * If the user presses Enter but no result is selected yet,
             * programmatically trigger an Arrow Down key press beforehand
             */
            if (options?.selectFirstResultOnEnter) {
              const selected = document.querySelector('.pac-item-selected');
              if (event.which === 13 && !selected) {
                boundListener(createArrowDownEvent());
              }
            }

            // pass through original event
            boundListener(event);
          };

          break;
        }

        // input listener: in charge of sending network requests as user types
        case 'input': {
          const debouncedListener =
            options?.debounceTime == null
              ? boundListener
              : debounce(boundListener, options.debounceTime);

          /**
           * Wrap the input listener in some custom behavior:
           * - minimumSearchLength: only start sending network requests if the
           *   user has typed a minimum number of characters.
           * - debounceTime: debounce the search so it only sends a single
           *   network request after the user types several characters rapidly.
           */
          wrappedListener = (event) => {
            /**
             * Case: minimumSearchLength
             * If the input contains less characters than minimumSearchLength, do
             * nothing.
             */
            if (options?.minimumSearchLength) {
              if (!input.value?.length) return;
              if (input.value.length < options.minimumSearchLength) return;
            }

            debouncedListener(event);
          };

          break;
        }
      }

      // add the wrapped listener instead
      boundAddEventListener(type, wrappedListener);
    };

    // Replace addEventListener with our own version
    input.addEventListener = wrappedAddEventListener;
  }, []);

  /**
   * Expose a function that allows selecting the first result of the
   * Autocomplete input. This is done because we have the context of the keydown
   * listener in state, so we can programmatically trigger any events we want.
   */
  const selectFirstResult = useCallback(() => {
    if (!listenerContext) return;
    const { input, listener } = listenerContext;

    // if no result is selected yet,
    // programmatically trigger an Arrow Down key press beforehand
    const selected = document.querySelector('.pac-item-selected');
    if (!selected) listener.apply(input, [createArrowDownEvent()]);

    // programmatically trigger an Enter key press
    listener.apply(input, [createEnterEvent()]);
  }, [listenerContext]);

  return { selectFirstResult };
};
