import type { SvgElementProps } from '../../../types';

const IconHeartSmall = ({
  width = 14,
  height = 14,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M13 4.5C13 2.84333 11.6007 1.5 9.87467 1.5C8.58467 1.5 7.47667 2.25067 7 3.322C6.52333 2.25067 5.41533 1.5 4.12467 1.5C2.4 1.5 1 2.84333 1 4.5C1 9.31333 7 12.5 7 12.5C7 12.5 13 9.31333 13 4.5Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconHeartSmall;
