import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '../../utils';

const BlurScreen = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('fixed inset-0 top-[120px] backdrop-blur-sm lg:top-0', className)}
    >
      <div className="absolute inset-0 bg-gray-800 opacity-20" />
    </div>
  )
);

export default BlurScreen;
