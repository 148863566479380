export const B2B_ROUTE_STORAGE_KEY = 'b2bRoute';
export const B2B_REFERRAL = 'corporate';
export const B2B_SOURCE_CODE = '05WEBS';
export const BSCO_COOKIE = 'brand_sco';

export const VALID_B2B_ROUTES = [
  'for-business',
  'corporate-gifts',
  'employee-gifts',
  'business-gifts',
  'long-service-awards',
  'retirement-gifts',
];
