import { ButtonHTMLAttributes } from 'react';

import { Button } from '../buttons/Button';

type Prop = {
  ctaTitle: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const CTAButton = ({ onClick, ctaTitle }: Prop) => {
  if (!onClick) return null;

  return (
    <Button
      className="ml-2 flex items-center gap-1 bg-transparent p-0 text-sm font-semibold leading-none text-link md:ml-0 md:mr-2"
      onClick={onClick}
      variant="invisible"
      data-testid="cta-button-desktop"
    >
      {ctaTitle}
    </Button>
  );
};
