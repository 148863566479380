import { ButtonHTMLAttributes } from 'react';

import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@virginexperiencedays/tailwind';

const buttonVariants = cva(
  'text-center align-middle select-none transition-colors font-semibold border-2 border-transparent rounded cursor-pointer focus:outline-0 focus:border-tones-info-500 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'text-neutral-strong bg-background-primary-faded hover:bg-background-primary-faded-hover focus:bg-primary-faded',
        primary:
          'text-white bg-background-primary hover:bg-background-primary-highlight focus:bg-background-primary',
        reverse:
          'text-neutral-strong bg-white hover:bg-background-neutral-faded focus:bg-white shadow-sm',
        gradient:
          'text-white bg-gradient-to-r from-background-primary to-background-primary-highlight bg-primary-600',
        invisible: 'text-neutral-strong bg-transparent hover:text-brand focus:text-brand',
        outline:
          'text-neutral-strong bg-transparent border-background-neutral-strong border hover:text-white hover:bg-background-primary hover:border-background-primary focus:text-neutral-strong focus:bg-transparent focus:border-grey-800 active:text-neutral-strong active:bg-transparent active:border-grey-800',
      },
      size: {
        small: 'px-4 py-2 leading-3 text-xs',
        regular: 'px-6 py-3 text-sm leading-[14px]',
        large: 'px-10 py-3.5 text-base leading-none',
        xl: 'px-10 py-3.5 text-base leading-normal',
        none: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'regular',
    },
  }
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Used to adjust the main style of the button based on variant options
   */
  variant?: VariantProps<typeof buttonVariants>['variant'];
  /**
   * Size of the button
   */
  size?: VariantProps<typeof buttonVariants>['size'];
  /**
   * Flag if the button should be fullWidth
   */
  fullWidth?: boolean;
  className?: string;
};

export const Button = ({
  children,
  onClick,
  fullWidth,
  variant = 'default',
  size = 'regular',
  className = null,
  ...rest
}: ButtonProps) => (
  <button
    className={cn(buttonVariants({ variant, size, className }), fullWidth && 'w-full', className)}
    onClick={onClick}
    data-testid="button"
    {...rest}
  >
    {children}
  </button>
);
