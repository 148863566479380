type NavigationType = { main: { navigation: unknown[] }; sub: { navigation: unknown[] } };

interface IJsonResponse {
  header: NavigationType;
  footer: NavigationType;
}

/**
 * Fetches navigation items from the specified URL.
 *
 * @param {string} url - A valid HTTP/S URL for the Navigation Service to fetch the navigation items from.
 * @returns {Promise<IJsonResponse>} A Promise that resolves to an object of footer and header navigation items.
 * @throws {Error} If the URL is not a valid HTTP or HTTPS URL string or if an error occurs during the fetch operation.
 */
const fetchVedNavigationItems = async (navigationServiceUrl: string): Promise<IJsonResponse> => {
  try {
    const httpRegex = /^https?:\/\//i;
    if (!httpRegex.test(navigationServiceUrl)) {
      throw new Error(`'url' must be a valid HTTP or HTTPS URL string`);
    }

    const res = await fetch(navigationServiceUrl);
    const json = await res.json();

    validateJsonResponse(json, ['header', 'footer']);

    return json;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};

export { fetchVedNavigationItems };

function validateJsonResponse(
  json: Record<string, { main: { navigation: unknown }; sub: { navigation: unknown } }>,
  keys: string[]
): void {
  keys.forEach((key: string) => {
    // ensure the top level keys exist
    if (!json.hasOwnProperty(key)) {
      throw new Error(`value for '${key}' is missing from response`);
    }

    // ensure each key has a main key which includes a navigation array
    if (!Array.isArray(json[key].main?.navigation)) {
      throw new Error(`'${key}.main.navigation' is not of type 'Array'`);
    }

    // ensure each key has a sub key which includes a navigation array
    if (!Array.isArray(json[key]?.sub?.navigation)) {
      throw new Error(`'${key}.sub.navigation' is not of type 'Array'`);
    }
  });
}
