import { HTMLAttributes, ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@virginexperiencedays/tailwind';

const bodyVariants = cva('font-inter', {
  variants: {
    size: {
      normal: 'text-base',
      extraSmall: 'text-xs',
      small: 'text-sm',
      large: 'text-xl',
      extraLarge: 'text-2xl',
    },
    leading: {
      none: 'leading-none',
      base: 'leading-base',
      snug: 'leading-tight',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
    },
  },
  defaultVariants: {
    size: 'normal',
    leading: 'normal',
  },
});

export type BodyTextProps = {
  children?: ReactNode;
  size?: VariantProps<typeof bodyVariants>['size'];
  leading?: VariantProps<typeof bodyVariants>['leading'];
  className?: string;
  as?: 'p' | 'span' | 'div' | 'dd' | 'dt' | 'td' | 'th';
} & HTMLAttributes<HTMLParagraphElement>;

export const BodyText = ({
  children,
  className,
  size = 'normal',
  leading = 'normal',
  as = 'p',
  ...rest
}: BodyTextProps) => {
  const Tag = as;
  return (
    <Tag
      className={cn(bodyVariants({ size, leading }), className)}
      data-testid="body-text"
      {...rest}
    >
      {children}
    </Tag>
  );
};
