// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://4bd01722fd6c4c9ca8252ac5bb77c67a@o454520.ingest.sentry.io/6041165',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2, // reduced the usage to 20% for now
  // NOTE: if we found a scenario/endpoint with high number of request
  // we can consider implementing the case below
  // tracesSampler: (samplerContext) => {
  //   switch (samplerContext.transactionContext.name) {
  //     case '/':
  //       return 0.05;
  //     case '/download-error':
  //       return 0.2;
  //     default:
  //       return 1;
  //   }
  // },
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: [
        process.env.NEXT_PUBLIC_HOME_LINK,
        process.env.PRODUCTS_API_URL,
        process.env.CATEGORIES_API_BASE_URL,
      ],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
