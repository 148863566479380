import React, { useEffect } from 'react';

/**
 * A custom React hook that adds scroll lock functionality to the page by preventing scrolling of the document body.
 *
 * @param lock - An optional boolean value that indicates whether or not to lock the scroll.
 * @returns An object containing two functions - lockScroll and unlockScroll - that respectively lock and unlock the scroll.
 */
export const useScrollLock = (lock?: boolean | boolean[]) => {
  /**
   * A function that locks the scroll by hiding the overflow of the body element and adding padding to the right
   * to offset the scrollbar width.
   */
  const lockScroll = React.useCallback(() => {
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollBarWidth}px`;
  }, []);

  /**
   * A function that unlocks the scroll by resetting the padding and overflow styles of the body element.
   */
  const unlockScroll = React.useCallback(() => {
    document.body.style.paddingRight = `0px`;
    document.body.style.overflow = '';
  }, []);

  // Run the lockScroll or unlockScroll functions based on the value of lock
  useEffect(() => {
    if (typeof window === 'undefined' || typeof lock === 'undefined') return;

    if (typeof lock === 'boolean') {
      lock ? lockScroll() : unlockScroll();
    } else if (Array.isArray(lock)) {
      lock.some((bool) => bool) ? lockScroll() : unlockScroll();
    } else {
      unlockScroll();
    }
  }, [lock, lockScroll, unlockScroll]);

  // Return an object containing the lockScroll and unlockScroll functions
  return { lockScroll, unlockScroll };
};
