import { useState, useEffect, useCallback } from 'react';

import { Cookie } from '../enums';
import { PromoService, vedPromosConfig } from '../promos';
import { getCookieMap } from '../utils/cookie';
import {
  ViewedProductItem,
  ViewedProductCard,
  ViewedProductMeta,
} from '../utils/getViewedProducts';
import {
  fetchVPApi,
  VIEWED_PRODUCTS as vpApiQuery,
  ProductResponse,
  Product,
} from '../utils/productFetch';
import { getLocationInfo, getPriceInfo } from '../utils/productInfo';

import type { HeaderProps } from '../types';

export enum LocalStorageKeys {
  RECENTLY_VIEWED_PRODUCTS = 'vp',
}

export enum CookieKeys {
  RECENTLY_VIEWED_PRODUCTS = 'recently_viewed_products',
}

// this hook sets a validated date key the first time it runs and validates all eligible products
// if the key is present and it has todays date, the products are considered validated
// and future loads for the rest of the calendar day will return products straight from local storage

export const useValidateProducts = (
  viewedProductsKey: string,
  apiUrl: string,
  router?: HeaderProps['NextRouter']
) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<[string, ViewedProductItem][]>([]);

  const revalidateProducts = useCallback(async () => {
    setLoading(true);
    try {
      // local storage products
      const currentProducts = getStorageItem(viewedProductsKey) as [string, ViewedProductItem][];
      const currentProductsRecord = currentProducts && Object.fromEntries(currentProducts);
      // cookie product IDs
      const cookieProductIdsString = getCookieMap()?.get(Cookie.RecentlyViewedProducts) ?? '';
      const cookieProductIds = parseJSON<ViewedProductMeta[]>(cookieProductIdsString) ?? [];
      // convenience timestamp
      const now = new Date();

      /**
       * Cookie IDs are the source of truth, as it is shared across all apps.
       *
       * We want a product in validProducts if:
       * - product id exists in the cookie
       * - AND product exists in local storage
       * - AND `lastValidated` is within today
       *
       * This means that the product is indeed recently viewed (as it exists in
       * the cookie) and has been recently validated, with full details in local
       * storage.
       */
      const validProducts: [string, ViewedProductItem][] = [];
      /**
       * We want a product in productsToRevalidate if:
       * - case:
       *   - product id exists in the cookie
       *   - AND product exists in local storage
       *   - AND `lastValidated` is older than today
       * - OR, case:
       *   - product id exists in the cookie
       *   - AND product does not exist in local storage
       *
       * The first case means that the product is indeed recently viewed (as it
       * exists in the cookie), but the details in local storage may be outdated
       * already.
       *
       * The second case means that the product is recently viewed, but has
       * never had its full details fetched (e.g. the cookie has been updated in
       * a different app).
       */
      const productsToRevalidate: [string, ViewedProductItem][] = [];

      /**
       * Iterate over cookieProductIds and determine whether the corresponding
       * product should be in validProducts or productsToRevalidate according to
       * the above rules.
       */
      cookieProductIds.forEach((cookieProduct) => {
        // condition 1 implicitly satisfied: product exists in cookie
        const localStorageProduct = currentProductsRecord?.[cookieProduct.id];

        // condition 2: does product exist in local storage?
        if (!localStorageProduct) {
          // if not, mark for revalidation
          productsToRevalidate.push([cookieProduct.id, cookieProduct]);
          return;
        }

        // condition 3: is 'lastValidated' within today?
        const lastValidated = localStorageProduct.lastValidated ?? 0;
        const lastValidatedDate: Date = new Date(lastValidated);
        if (!isToday(lastValidatedDate, now)) {
          // if not, mark for revalidation
          productsToRevalidate.push([cookieProduct.id, localStorageProduct]);
          return;
        }

        // all conditions have been met: mark as valid
        validProducts?.push([cookieProduct.id, localStorageProduct]);
      });

      // if there are no products to revalidate, we can just use whats in local storage
      if (!productsToRevalidate?.length) {
        const filteredProducts = cookieProductIds.map(
          (p) => [p.id, currentProductsRecord?.[p.id]] as [string, ViewedProductMeta]
        );
        setProducts(filteredProducts);
        return;
      }

      const apiVariables = {
        productIds: productsToRevalidate.map((item) => item?.[0]),
      };

      const productsFromApi = await fetchVPApi(apiUrl, vpApiQuery, apiVariables);
      const updatedProducts = validateProductItems(
        productsFromApi,
        productsToRevalidate,
        validProducts
      );
      setProducts(updatedProducts);
      setStorageItem(viewedProductsKey, updatedProducts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [viewedProductsKey, apiUrl]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    revalidateProducts();
  }, [revalidateProducts]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (
      router &&
      (router.asPath?.startsWith('/collection') || router.asPath?.startsWith('/product'))
    ) {
      revalidateProducts();
    }
  }, [router?.asPath]);

  return { setStorageItem, setProducts, loading, products };
};

// remove any non-active items and replace all product card data with data from the API
const validateProductItems = (
  response: ProductResponse,
  itemsToRevalidate: [string, ViewedProductItem][],
  validItems: [string, ViewedProductItem][]
) => {
  const activeProducts = response?.products?.filter((product) => product.status === 'active');
  const toRevalidate = Object.fromEntries(itemsToRevalidate);
  const updatedItems: [string, ViewedProductItem][] = [];
  activeProducts?.forEach((product) => {
    const updatedProduct = updateProductItem(toRevalidate, product);
    if (updatedProduct) updatedItems?.push(updatedProduct);
  });

  return validItems.concat(updatedItems);
};

const updateProductItem = (
  toRevalidateObject: { [key: string]: ViewedProductItem },
  activeProduct: Product
) => {
  const id: string = activeProduct.id.toString();

  if (id in toRevalidateObject) {
    const cardRoute = activeProduct.isCollectionProduct
      ? `/collection/${activeProduct.slug}`
      : `/product/${activeProduct.slug}`;

    const { currentPrice, pastPrice, badge } = getPriceInfo(activeProduct) ?? {};

    const promoService = new PromoService({
      activePromos: process.env.NEXT_PUBLIC_ACTIVE_PROMOS as string,
      promosConfiguration: vedPromosConfig,
    });

    const card: ViewedProductCard = {
      id: activeProduct.id,
      cardRoute,
      // used by variation A (old product card)
      cardImage: activeProduct.media.mainImage.url,
      // used by variation B (product card 2024)
      cardImages: [activeProduct.media.mainImage, ...(activeProduct?.media?.images || [])].map(
        (img) => ({
          src: img?.url || '',
          alt: img?.alt || activeProduct.title,
        })
      ),
      cardFullSku: activeProduct.promocode
        ? `${activeProduct.sku} ${activeProduct.promocode}`
        : activeProduct.sku,
      cardTitle: activeProduct.title,
      currentPrice: currentPrice ?? 0,
      pastPrice: pastPrice ?? undefined,
      percentOff: activeProduct.price?.percentOff ?? 0,
      locations: getLocationInfo(activeProduct) ?? undefined,
      reviews: activeProduct.reviews,
      dateActivated: activeProduct.dateActivated,
      isExclusive: activeProduct.isExclusive,
      productPromo: promoService.getProductPromo(activeProduct),
      badge: badge ?? undefined,
    };

    // keep existing date added to preserve recently viewed order
    const updatedProduct = [
      id,
      {
        vpCard: card,
        added: toRevalidateObject?.[id]?.added,
        lastValidated: Date.now(),
      },
    ] as [string, ViewedProductItem];
    return updatedProduct;
  }
};

function isToday(date: Date, now: Date) {
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

const getStorageItem = (key: string): unknown => {
  const item = window.localStorage.getItem(key);
  return parseJSON(item);
};

const setStorageItem = (key: string, value: string | number | object): void => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

function parseJSON<T>(value: string | null): T | undefined {
  try {
    if (typeof value !== 'string' || value.trim() === '') return undefined;
    return JSON.parse(value) as T;
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error('[parseJSON]: Error parsing JSON:', error.message);
    } else {
      console.error('[parseJSON]: An unexpected error occurred:', error);
    }
    return undefined;
  }
}
