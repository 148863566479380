import type { QuizConfig } from '../types';

export const config: QuizConfig = {
  title: "Let's find you a gift...",
  quiz: [
    {
      step: 1,
      uuid: 'recipient',
      componentProps: { type: 'single' },
      question: "Who's it for?",
      options: [
        { uuid: 'for-him', text: 'For Him' },
        { uuid: 'for-her', text: 'For Her' },
        { uuid: 'couples', text: 'For Couples' },
        { uuid: 'kids', text: 'For Kids' },
      ],
      skip: true,
    },
    {
      step: 2,
      uuid: 'personality',
      componentProps: { type: 'multiple', minSelections: 1, maxSelections: 3 },
      question: 'What describes them?',
      options: [
        { uuid: 'drink-buff', text: 'Drink Buff' },
        { uuid: 'thrill-seeker', text: 'Thrill Seeker' },
        { uuid: 'relaxation-guru', text: 'Relaxation Guru' },
        { uuid: 'foodie', text: 'Foodie' },
        { uuid: 'lover-of-luxury', text: 'Lover of Luxury' },
        { uuid: 'animal-lover', text: 'Animal Lover' },
        { uuid: 'curious-mind', text: 'Curious Mind' },
        { uuid: 'petrol-head', text: 'Petrol Head' },
        { uuid: 'music-lover', text: 'Music Lover' },
        { uuid: 'sports-fan', text: 'Sports Fan' },
        { uuid: 'explorer', text: 'Explorer' },
        { uuid: 'wine-connoisseur', text: 'Wine Connoisseur' },
        { uuid: 'beer-connoisseur', text: 'Beer Connoisseur' },
        { uuid: 'creative', text: 'Creative' },
        { uuid: 'aspiring-chef', text: 'Aspiring Chef' },
        { uuid: 'tea-drinker', text: 'Tea Drinker' },
      ],
      subText: 'Please select up to 3 options',
    },
    {
      step: 3,
      uuid: 'price',
      componentProps: {
        type: 'range',
        min: 0,
        max: 5,
        step: 1,
        stepValues: [0, 50, 100, 150, 200, 500],
        fromValue: 1,
        toValue: 3,
      },
      question: "What's your budget?",
      subText: 'Average spend with your selection is £90',
    },
  ],
};
