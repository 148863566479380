export type NormalizedNavItem = {
  name: string;
  nameSEO?: string;
  route?: string;
  shopAll?: boolean;
  icon?: string;
  image?: string;
  childItems?: NormalizedNavItem[];
};

type OriginalNavItem = NormalizedNavItem & { children?: unknown }; // ? cannot have name `children` due to JSX conflict

/**
 * Formats the given item to remove the children prop in place of childItems.
 * @param item The item to be formatted.
 * @returns The formatted item.
 */
export function normalizeNavigationItem(item: OriginalNavItem): NormalizedNavItem {
  return {
    ...item,
    childItems: item?.children,
    children: undefined,
  } as NormalizedNavItem;
}
