import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../../utils';

import type { HTMLAttributes, ReactNode } from 'react';

const displayHeadingVariants = cva('m-0 font-inter font-bold', {
  variants: {
    size: {
      '1': 'text-3xl',
      '2': 'text-2xl',
      '3': 'text-xl',
      '4': 'text-base',
      '5': 'text-sm',
      '6': 'text-xs',
    },
    leading: {
      snug: 'leading-tight',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      none: 'leading-none',
    },
    defaultVariants: {
      size: '2',
      leading: 'snug',
    },
  },
});

export type DisplayHeadingProps = {
  children: ReactNode;
  className?: string;
  size?: VariantProps<typeof displayHeadingVariants>['size'];
  leading?: VariantProps<typeof displayHeadingVariants>['leading'];
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & HTMLAttributes<HTMLHeadingElement>;

export const DisplayHeading = ({
  className,
  as,
  children,
  size = '1',
  leading = 'snug',
  ...rest
}: DisplayHeadingProps) => {
  const Tag = as ?? `h${size ?? 1}`;

  return (
    <Tag
      data-testid="display-heading"
      className={cn(displayHeadingVariants({ size, leading }), className)}
      {...rest}
    >
      {children}
    </Tag>
  );
};
