import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '../../../../utils';
import Link from '../../../common/Link';

type MenuItemButtonProps = {
  href?: string;
  level?: number;
  as?: string;
  prefetch?: boolean;
  hasChildren?: boolean;
  children?: ReactNode;
} & HTMLAttributes<HTMLElement>;

const ItemLink = ({
  children,
  className,
  href,
  onClick,
  level,
  prefetch = false,
  ...rest
}: MenuItemButtonProps) =>
  href ? (
    <Link
      prefetch={prefetch}
      href={href}
      onClick={onClick}
      className={className}
      data-aria-level={level}
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={cn('w-full', className)} data-aria-level={level} {...rest}>
      {children}
    </button>
  );

const MenuItemButton = (props: MenuItemButtonProps) => {
  switch (props.level) {
    case 1:
      return (
        <h2>
          <ItemLink {...props}>{props?.children}</ItemLink>
        </h2>
      );
    case 2:
      return (
        <h3>
          <ItemLink {...props}>{props?.children}</ItemLink>
        </h3>
      );
    default:
      return <ItemLink {...props}>{props?.children}</ItemLink>;
  }
};

export default MenuItemButton;
