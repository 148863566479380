import { cn } from '../../../utils';

import type { SvgElementProps } from '../../../types';

const IconGiftFinder = ({
  className = '',
  iconClass = '',
  width = 20,
  height = 20,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={cn(className, iconClass)}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_3428_2662)">
      <path
        d="M3.35608 1L4.79413 2.71381"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M15.6608 2.71387L17.0988 1.00006"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M16.9 10.9362L19.0023 11.7013"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M1 11.8326L3.10229 11.0675"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M10 18.8977V16.6605"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M9.11056 2.21032C9.39335 1.34217 10.6216 1.34217 10.9034 2.21032L11.912 5.3134C11.9736 5.50238 12.0934 5.66705 12.2542 5.78386C12.4151 5.90068 12.6087 5.96366 12.8075 5.96381H16.0708C16.9842 5.96381 17.3632 7.13265 16.6251 7.66994L13.9858 9.58722C13.8246 9.70413 13.7046 9.86907 13.643 10.0584C13.5813 10.2477 13.5812 10.4517 13.6427 10.6411L14.6512 13.7441C14.934 14.6123 13.9396 15.3353 13.1996 14.798L10.5603 12.8807C10.3993 12.7638 10.2055 12.7009 10.0065 12.7009C9.80757 12.7009 9.61372 12.7638 9.45273 12.8807L6.81341 14.798C6.0744 15.3353 5.08089 14.6123 5.36273 13.7441L6.37132 10.6411C6.43276 10.4517 6.43265 10.2477 6.37101 10.0584C6.30937 9.86907 6.18937 9.70413 6.02821 9.58722L3.38984 7.67088C2.65177 7.13359 3.03164 5.96475 3.94409 5.96475H7.20648C7.40542 5.9648 7.59927 5.90191 7.76029 5.78508C7.92131 5.66826 8.04124 5.50348 8.10291 5.31434L9.1115 2.21126L9.11056 2.21032Z"
        stroke={themeColor ?? fill}
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_3428_2662">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconGiftFinder;
