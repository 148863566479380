export const isDeviceAppleSafari = (): boolean => {
  const userAgent = window?.navigator?.userAgent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const iOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream;
  const macOS = /Macintosh/.test(userAgent);
  const webkit = /WebKit|Safari/.test(userAgent);
  const otherWebkit = /Chrome|CriOS|FxiOS|OPiOS|mercury/.test(userAgent);

  return (iOS || macOS) && webkit && !otherWebkit;
};
