/**
 * The project's Prismic Link Resolver. This function determines the URL for a
 * given Prismic document.
 *
 * NOTE: we are in lib/cms, so ostensibly we should be agnostic to whatever
 * routing structure a consuming project will have. In practice, though, we need
 * this logic to resolve links within our slices (see the multiple uses of
 * {@link createLinkedPath} in our slices, for example).
 *
 * Moving forward, if we ever add more than one app package (other than
 * packages/apps/web) with its own routing structure, linkResolver must be
 * defined in each individual app. We'll need to refactor our slice components
 * to do dependency injection instead: linkResolver will be passed down from the
 * consuming project.
 *
 * @type {prismicH.LinkResolverFunction}
 */
export function linkResolver(doc) {
  switch (doc.type) {
    /** HOME PAGE */
    case 'home_page':
    case 'home_page_repeatable':
    // ? Remove this, once we have a separate instance / locale on prismic for concorde
    case 'veg_homepage':
      return '/';

    /** CUSTOM SITE */
    case 'custom_site_page':
    case 'veg_custom_site_page': // ? remove this once we have a separate instance
      return `/${doc.uid}`;
    /** THINGS TO DO */
    case 'post':
    case 'post_listing_page':
    case 'ttd_search_page': // actually should be hardcoded
    case 'ttd_counties_page': // actually should be hardcoded
      return `/things-to-do/${doc.uid}`;
    case 'ttd_homepage':
      return `/things-to-do`;

    /** EXPERIENCE BLOG */
    case 'blog_listing_page':
    case 'blog_author_page':
    case 'blog_post_page':
      return `/experience-blog/${doc.uid}`;
    case 'blog_home_page':
      return `/experience-blog`;

    /** CLPs */
    // ? Remove this, once we have a separate instance / locale on prismic for concorde
    case 'veg_category_listing_page': // falls through
      return `c/${doc.uid}`;
    // NOTE: use old URL schema for convenience; rely on middleware routing

    case 'category_listing_page': // falls through
    case 'location_listing_page': // falls through
    case 'rating_listing_page': // falls through
    case 'price_range_listing_page': {
      // remove variation id after last -- using a capture group
      const slug = doc.data?.is_variation ? /^(.*)--(.*?)$/.exec(doc.uid)?.[1] : doc.uid;
      return `/${slug}`;
    }
    case 'category_override_listing_page': {
      // remove variation id after last --
      const slug = doc.data?.is_variation ? /^(.*)--(.*?)$/.exec(doc.uid)?.[1] : doc.uid;

      // the way its stored in prismic is seperated by double hyphens, instead we replace them with just slashes
      // as prismic does not allows to use / for uids
      return `/${slug.replace(/--/g, '/')}`;
    }

    /** SRP */
    case 'search_listing_page':
      return '/search';

    default:
      // Capture if the given uid is full URL
      if (doc.uid?.match(/https?:\/\//)) {
        return doc.uid;
      }
      // Fallback for other types, in case new custom types get created
      return `/${doc.uid}`;
  }
}
