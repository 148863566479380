/**
 * Retrieves an array of valid navigation items from an object, based on a specified property name.
 *
 * @param property - The name of the property that contains the navigation items.
 * @param object - The object from which to retrieve the navigation items.
 * @returns An array of valid navigation items, or null if no valid items are found.
 */
function getValidNavItems(property: string, object: Record<string, unknown>): unknown {
  if (!object) return null;
  if (typeof object === 'object' && property in object) return object[property];
  return null;
}

/**
 * Retrieves the valid navigation items from the initial and revalidated data.
 *
 * @param initialData - The initial data object from which to retrieve navigation items.
 * @param revalidatedData - The revalidated data object from which to retrieve navigation items.
 * @returns An object containing the header and footer navigation items.
 */
function getValidNavigationItems(
  initialData: unknown,
  revalidatedData: unknown
): {
  header: Record<string, unknown>;
  footer: Record<string, unknown>;
} {
  const initialHeaderNavData = getValidNavItems('header', initialData as Record<string, unknown>);
  const initialFooterNavData = getValidNavItems('footer', initialData as Record<string, unknown>);

  const revalidatedHeaderNavData = getValidNavItems(
    'header',
    revalidatedData as Record<string, unknown>
  );
  const revalidatedFooterNavData = getValidNavItems(
    'footer',
    revalidatedData as Record<string, unknown>
  );

  return {
    header: (revalidatedHeaderNavData ?? initialHeaderNavData ?? {}) as Record<string, unknown>,
    footer: (revalidatedFooterNavData ?? initialFooterNavData ?? {}) as Record<string, unknown>,
  };
}

export { getValidNavigationItems };
