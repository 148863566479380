import { cn } from './cn';

export type AspectRatio = '1/1' | '4/3' | '16/9' | '3/4' | '2/1' | '3/2';

export type ResponsiveAspectRatio =
  | AspectRatio
  | {
      mobile?: AspectRatio;
      tablet?: AspectRatio;
      desktop?: AspectRatio;
      wide?: AspectRatio;
    };

export const aspectRatioConfig = {
  '1/1': 'aspect-square',
  '4/3': 'aspect-[4/3]',
  '16/9': 'aspect-video',
  '3/4': 'aspect-[3/4]',
  '2/1': 'aspect-[2/1]',
  '3/2': 'aspect-[3/2]',
};

const responsiveAspectRatioConfig = {
  mobile: aspectRatioConfig,
  tablet: {
    '1/1': 'md:aspect-square',
    '4/3': 'md:aspect-[4/3]',
    '16/9': 'md:aspect-video',
    '3/4': 'md:aspect-[3/4]',
    '2/1': 'md:aspect-[2/1]',
    '3/2': 'md:aspect-[3/2]',
  },
  desktop: {
    '1/1': 'lg:aspect-square',
    '4/3': 'lg:aspect-[4/3]',
    '16/9': 'lg:aspect-video',
    '3/4': 'lg:aspect-[3/4]',
    '2/1': 'lg:aspect-[2/1]',
    '3/2': 'lg:aspect-[3/2]',
  },
  wide: {
    '1/1': 'xl:aspect-square',
    '4/3': 'xl:aspect-[4/3]',
    '16/9': 'xl:aspect-video',
    '3/4': 'xl:aspect-[3/4]',
    '2/1': 'xl:aspect-[2/1]',
    '3/2': 'xl:aspect-[3/2]',
  },
};

export const aspectRatioPolyfillConfig = {
  '1/1': 'pt-[calc(1/(1/1))]',
  '4/3': 'pt-[calc(1/(4/3))]',
  '16/9': 'pt-[calc(1/(16/9))]',
  '3/4': 'pt-[calc(1/(3/4))]',
  '2/1': 'pt-[calc(1/(2/1))]',
  '3/2': 'pt-[calc(1/(3/2))]',
};

export const responsiveAspectRatioPolyfillConfig = {
  mobile: aspectRatioPolyfillConfig,
  tablet: {
    '1/1': 'md:pt-[calc(1/(1/1))]',
    '4/3': 'md:pt-[calc(1/(4/3))]',
    '16/9': 'md:pt-[calc(1/(16/9))]',
    '3/4': 'md:pt-[calc(1/(3/4))]',
    '2/1': 'md:pt-[calc(1/(2/1))]',
    '3/2': 'md:pt-[calc(1/(3/2))]',
  },
  desktop: {
    '1/1': 'lg:pt-[calc(1/(1/1))]',
    '4/3': 'lg:pt-[calc(1/(4/3))]',
    '16/9': 'lg:pt-[calc(1/(16/9))]',
    '3/4': 'lg:pt-[calc(1/(3/4))]',
    '2/1': 'lg:pt-[calc(1/(2/1))]',
    '3/2': 'lg:pt-[calc(1/(3/2))]',
  },
  wide: {
    '1/1': 'xl:pt-[calc(1/(1/1))]',
    '4/3': 'xl:pt-[calc(1/(4/3))]',
    '16/9': 'xl:pt-[calc(1/(16/9))]',
    '3/4': 'xl:pt-[calc(1/(3/4))]',
    '2/1': 'xl:pt-[calc(1/(2/1))]',
    '3/2': 'xl:pt-[calc(1/(3/2))]',
  },
};

export const mapAspectRatio = (ratio: AspectRatio | ResponsiveAspectRatio): string => {
  if (typeof ratio === 'string') {
    return aspectRatioConfig[ratio];
  }

  const { mobile = '4/3', tablet, desktop, wide } = ratio;

  const mobileStyle = responsiveAspectRatioConfig.mobile[mobile];
  const tabletStyle = tablet ? responsiveAspectRatioConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveAspectRatioConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveAspectRatioConfig.wide[wide] : null;

  return cn(mobileStyle, tabletStyle, desktopStyle, wideStyle);
};

export const mapAspectRatioPolyfill = (ratio: AspectRatio | ResponsiveAspectRatio): string => {
  if (typeof ratio === 'string') {
    return aspectRatioPolyfillConfig[ratio];
  }

  const { mobile = '4/3', tablet, desktop, wide } = ratio;

  const mobileStyle = responsiveAspectRatioPolyfillConfig.mobile[mobile];
  const tabletStyle = tablet ? responsiveAspectRatioPolyfillConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveAspectRatioPolyfillConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveAspectRatioPolyfillConfig.wide[wide] : null;

  return cn(mobileStyle, tabletStyle, desktopStyle, wideStyle);
};
