import { useEffect, useState } from 'react';

/**
 * Custom hook to determine if the current device is running a specified iOS version.
 *
 * @param iOSVersions - An array of iOS versions (as strings or numbers) to check against the user agent.
 * @returns A boolean indicating whether the current device is running one of the specified iOS versions.
 *
 * @example
 * ```typescript
 * const isIphoneOrIpad = useIsIos();
 * const isIos16 = useIsIos([16]);
 * const isIos16Or15 = useIsIos([16, 15]);
 * ```
 */
export const useIsIos = (iOSVersions?: (string | number)[]) => {
  const [isTargetIosVersion, setIsTargetIOSVersion] = useState<boolean>(false);

  const isIos = (ua = ''): boolean => {
    const userAgent = !!ua ? ua : window?.navigator?.userAgent;

    const iOS = /iPad|iPhone|iPod/.test(userAgent);

    // also used by ipads
    const macOS = /Macintosh/.test(userAgent);

    return (iOS || macOS) && !(window as any)?.MSStream;
  };

  const isIosSafari = (ua = ''): boolean => {
    const userAgent = !!ua ? ua : window?.navigator?.userAgent;

    const iOS = isIos(ua || userAgent);
    const webkit = /WebKit|Safari/.test(userAgent);
    const otherWebkit = /Chrome|CriOS|FxiOS|OPiOS|mercury/.test(userAgent);

    return iOS && webkit && !otherWebkit;
  };

  const getIosVersion = (ua = '') => {
    const userAgent = ua || window?.navigator?.userAgent;

    const version = userAgent.match(/(Version\/|OS )(\d+)(?:[._](\d+))?(?:[._](\d+))?/);

    if (!version) return null;

    const formattedVersions = version
      .map((v) => {
        if (!v) return null;
        const cleanVersion = v.replace('Version/', '').replace('OS ', '').replace('_', '.');
        return {
          major: cleanVersion.split('.')?.[0],
          full: cleanVersion,
        };
      })
      .filter((v) => !!v);

    return formattedVersions?.[0];
  };

  useEffect(() => {
    // case 1: generic iOS check
    if (!iOSVersions || !Array.isArray(iOSVersions)) {
      setIsTargetIOSVersion(isIos());
      return;
    }

    // case 2: specific iOS major version check
    const currentVersion = getIosVersion();
    if (isIos() && currentVersion !== null) {
      const targetVersion = iOSVersions.some((version) => `${version}` === currentVersion.major);

      setIsTargetIOSVersion(targetVersion);

      return;
    }

    setIsTargetIOSVersion(false);
  }, [iOSVersions]);

  return {
    isTargetIosVersion,
    isIos,
    isIosSafari,
    getIosVersion,
  };
};
