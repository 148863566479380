type Params = string | string[][] | Record<string, string> | URLSearchParams | undefined;

const getApi = async (serviceUrl: string, queryParams?: Params) => {
  try {
    const httpRegex = /^https?:\/\//i;
    if (!httpRegex.test(serviceUrl)) {
      throw new Error(`'url' must be a valid HTTP or HTTPS URL string`);
    }
    const searchParams = new URLSearchParams(queryParams);
    const res = await fetch(`${serviceUrl}${searchParams.toString()}`);

    if (res.status !== 200) {
      console.error(await res.text());
      throw new Error('Failed to fetch API');
    }

    const json = await res.json();

    return json;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Unknown error in "/utils/getApi"');
  }
};

export { getApi };
