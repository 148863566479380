import { HTMLAttributes } from 'react';

import { cn } from '../../../utils';
import { roundStarRating } from '../../../utils/productRating';
import RenderIcon from '../icons/RenderIcon';

export type StarRatingProps = {
  rating: number;
  shouldRoundUp?: boolean;
  shadedColor?: string;
  emptyColor?: string;
} & HTMLAttributes<HTMLElement>;

const StarRating = ({
  className,
  rating,
  shouldRoundUp,
  shadedColor,
  emptyColor,
}: StarRatingProps) => {
  // Round rating to nearest 0.5 (rounding up if flag set) to account for half stars
  const roundedRating = roundStarRating(rating, { shouldRoundUp });

  const starRatings = [
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
  ];
  if (!roundedRating) return null;

  for (let i = 0; i < roundedRating; i++) {
    if (roundedRating % 1 === 0 || roundedRating > i + 0.5) {
      starRatings.splice(i, 1, { type: 'full' });
    } else {
      starRatings.splice(i, 1, { type: 'half' });
    }
  }

  return (
    <div className={cn('flex items-center', className)} data-rating={rating}>
      {[...Array(5)].map((_, i) => (
        <div key={i}>
          <RenderIcon
            name="star"
            type={starRatings[i].type as 'full' | 'half' | 'empty'}
            shadedColor={shadedColor}
            emptyColor={emptyColor}
          />
        </div>
      ))}
    </div>
  );
};

export default StarRating;
