import { AriaLabel } from '../../../enums';
import { useIsMounted } from '../../../hooks';
import { cn, addTrackingEntryToLinks } from '../../../utils';
import RenderIcon from '../icons/RenderIcon';
import PortableText from '../PortableText';

import type { PromoBannerProps } from '../../../types';
import type { HTMLAttributes } from 'react';

type Props = {
  dismissible?: boolean;
  dismissed?: boolean;
  handleDismiss?: () => void;
} & PromoBannerProps &
  HTMLAttributes<HTMLDivElement>;

const PromoBanner = ({
  portableText = [],
  variation = 'grey',
  alignContent = 'left',
  dismissible,
  dismissed,
  className,
  handleDismiss,
  ...props
}: Props) => {
  const isMounted = useIsMounted();
  const iconColor = variation === 'red' ? 'var(--persistent-text-dark)' : 'var(--text-neutral)';

  return (
    <div
      {...props}
      className={cn(
        'flex w-full py-2 text-xs leading-none',
        {
          [style.container.red]: variation === 'red',
          [style.container.grey]: variation === 'grey',
        },
        { [style.container.dismissible]: dismissible },
        { [style.container.display]: !dismissed && isMounted },
        className
      )}
    >
      <div
        className={cn('mx-auto flex w-full max-w-[1440px] lg:gap-4 lg:px-[40px] xl:px-[80px]', {
          [style.container.left]: alignContent === 'left',
          [style.container.center]: alignContent === 'center',
          [style.container.right]: alignContent === 'right',
        })}
      >
        <PortableText
          className="flex gap-4"
          blocks={addTrackingEntryToLinks(portableText, 'promoBanner')}
        />
        {dismissible && (
          <button onClick={handleDismiss} aria-label={AriaLabel.ClosePBanner}>
            <RenderIcon name="cross" width={8} height={8} fill={iconColor} />
          </button>
        )}
      </div>
    </div>
  );
};

export default PromoBanner;

const style = {
  container: {
    red: 'bg-persistent-background-primary-faded text-persistent-text-dark',
    grey: 'bg-background-neutral-faded text-neutral',
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
    dismissible:
      'absolute inset-0 -translate-y-full transition-transform duration-300 ease-in-out delay-300',
    display: 'translate-y-0',
  },
};
