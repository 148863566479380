import { useEffect } from 'react';

// iOS bfcache causes issues with the Quick Basket state when navigating back using the browser back button
// this hook causes a page refresh when the user navigates back to the page, utilising the pageshow event
// https://developer.mozilla.org/en-US/docs/Web/API/Window/pageshow_event
export function useForceRefreshOnPageShow(callback: () => void) {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const forceRefresh = (e: PageTransitionEvent) => {
      if (e.persisted) location.reload();
    };

    window.addEventListener('pageshow', forceRefresh);
    return () => {
      callback();
      window.removeEventListener('pageshow', forceRefresh);
    };
  }, []);
}
