import type { IconProps as Props } from './types';

export const IconCloseModal = ({
  className,
  title,
  width = '1em',
  height = '1em',
  fill = 'currentColor',
  ...rest
}: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 14"
      aria-labelledby={title ? 'title desc' : null}
      fill={fill}
      {...rest}
    >
      {title && <title id="title">{title}</title>}
      <desc id="desc">Black cross</desc>
      <path
        role="presentation"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.293 1.293a1 1 0 0 1 1.414 0L6 5.586l4.293-4.293a1 1 0 1 1 1.414 1.414L7.414 7l4.293 4.293a1 1 0 0 1-1.414 1.414L6 8.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L4.586 7 .293 2.707a1 1 0 0 1 0-1.414Z"
      />
    </svg>
  );
};
