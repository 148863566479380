import { cn } from '../../../../utils';
import RenderIcon from '../../../common/icons/RenderIcon';
import Link from '../../../common/Link';

import type { HTMLAttributes } from 'react';

type BackButtonNavProps = {
  parentName: string;
  viewAllRoute?: string;
  handleClickViewAll: () => void;
  onClick: () => void;
} & HTMLAttributes<HTMLElement>;

const BackButtonNav = ({
  className,
  parentName,
  viewAllRoute,
  handleClickViewAll,
  onClick,
}: BackButtonNavProps) => (
  <div className={cn(style.backButton._, className)}>
    <button className="flex items-center gap-3 overflow-hidden p-2.5 pl-0" onClick={onClick}>
      <RenderIcon
        name="chevron-left"
        className="rotate-90 scale-50"
        themeColor="var(--text-neutral)"
        width={20}
        height={15}
      />
      <span className="truncate text-lg font-semibold">{parentName}</span>
    </button>
    {viewAllRoute && (
      <Link
        href={viewAllRoute}
        onClick={handleClickViewAll}
        className="text-nowrap p-2 text-base font-medium text-neutral underline underline-offset-2 hover:text-neutral"
      >
        View All
      </Link>
    )}
  </div>
);

export default BackButtonNav;

const style = {
  backButton: {
    _: 'flex items-center justify-between pb-2',
  },
};
