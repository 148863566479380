import { footerHelpLinkToDataLayer } from '../../../utils/addToDataLayer';
import Link from '../../common/Link';

import type { ItemsProps } from '../../../types';

const HelpLinks = ({ items = [] }: { items: Omit<ItemsProps, 'children'>[] }) => (
  <div className="order-2 flex justify-center lg:items-start lg:justify-start lg:pt-1">
    <ul className="m-0 flex list-none flex-wrap justify-center pl-0 pt-2 text-xs lg:justify-start lg:text-sm">
      {items.map(({ name, route }, index) => (
        <li
          key={`footerTag-${route}-${name}-${index}`}
          className="m-2 lg:m-0 lg:mb-2 lg:mr-4 lg:first-of-type:ml-0 lg:last-of-type:mr-0"
        >
          <Link
            href={route}
            onClick={() => footerHelpLinkToDataLayer(name)}
            className="flex text-xs leading-base text-primary-100 no-underline hover:text-primary-100 hover:underline"
          >
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default HelpLinks;
