import { useEffect, useState } from 'react';

let _detectedAspectRatioSupported = false;
let _isAspectRatioSupported = false;

const checkAspectRatioSupported = (): boolean => {
  if (_detectedAspectRatioSupported) {
    return _isAspectRatioSupported;
  }

  // create fixed width container with aspect ratio
  const container = document.createElement('div');
  container.style.width = '4px';
  container.style.aspectRatio = '4/3';

  // append to the DOM (needed to obtain height)
  document.body.appendChild(container);
  const isSupported = container.scrollHeight === 3; // container should be 4px/3px
  container?.parentNode?.removeChild(container);

  _detectedAspectRatioSupported = true;
  _isAspectRatioSupported = isSupported;

  return isSupported;
};

export const useAspectRatioSupported = () => {
  const [isSupported, setIsAspectRatioSupported] = useState<boolean>(true);

  useEffect(() => {
    setIsAspectRatioSupported(checkAspectRatioSupported());
  }, []);

  return { isSupported };
};
