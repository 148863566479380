import type { PromoConfig } from './types';

export const vedPromosConfig: Map<string, PromoConfig> = new Map([
  [
    'black-friday',
    {
      facetPaths: ['facets.features', 'facets.featureHierarchies.lvl0'],
      text: 'Black Friday',
      styles: 'bg-white text-persistent-text-dark',
    },
  ],
  [
    'cyber-monday',
    {
      facetPaths: ['facets.features', 'facets.featureHierarchies.lvl0'],
      facetIdentifiers: ['black-friday'],
      text: 'Cyber Monday',
      styles: 'bg-white text-persistent-text-dark',
    },
  ],
  [
    'christmas-deals',
    {
      facetPaths: ['facets.features', 'facets.featureHierarchies.lvl0'],
      text: 'Christmas Deal',
      styles: 'bg-white text-persistent-text-dark',
    },
  ],
  [
    'fathers-day-special-offers',
    {
      facetPaths: ['facets.occasions'],
      text: 'Dad Approved',
      styles: 'bg-background-page text-neutral',
      legacyPromoIdentifier: 'isFathersDayProduct',
    },
  ],
  [
    'virgin-loves',
    {
      facetPaths: ['facets.features', 'facets.featureHierarchies.lvl0'],
      text: 'Virgin Loves',
      styles: 'bg-white text-persistent-text-dark',
    },
  ],
  [
    'exclusive-to-us',
    {
      facetPaths: ['facets.features', 'facets.featureHierarchies.lvl0'],
      text: 'Exclusive',
      styles: 'bg-white text-persistent-text-dark',
    },
  ],
]);
