import { type PortableTextTrackingFn, portableTextTracking } from '../../utils';

import Link from './Link';

import type { PortableTextBlock } from '../../types';
import type { ReactNode, HTMLAttributes } from 'react';

type Props = {
  blocks: PortableTextBlock[];
} & HTMLAttributes<HTMLDivElement>;

const PortableText = ({ blocks, ...props }: Props) => {
  if (!Array.isArray(blocks)) return null;
  return <div {...props}>{renderBlocks(blocks)}</div>;
};

type RenderBlockProps = Props['blocks'];

const renderBlocks = (blocks: RenderBlockProps): ReactNode => {
  if (!Array.isArray(blocks)) return null;

  return blocks.map((block, index: number) => {
    if (!block) return null;

    const { _type, children, text, href, trackingFn } = block;
    const renderChild = () => (children ? renderBlocks(children) : text);

    switch (_type) {
      case 'paragraph':
        return <p key={index}>{renderChild()}</p>;
      case 'text':
        return <span key={index}>{renderChild()}</span>;
      case 'strong':
        return <strong key={index}>{renderChild()}</strong>;
      case 'link': {
        const handleClick = () => {
          const key = trackingFn as PortableTextTrackingFn;
          portableTextTracking?.[key]?.(`Linked To | ${href}`);
        };
        return href ? (
          <Link key={index} href={href} onClick={handleClick} className="underline">
            {renderChild()}
          </Link>
        ) : null;
      }
      default:
        return text ? <span key={index}>{text}</span> : null;
    }
  });
};

export default PortableText;
