import { TestId } from '../../../../enums';
import { cn } from '../../../../utils';
import RenderIcon from '../../../common/icons/RenderIcon';

import MenuItemButton from './MenuItemButton';
import NavList, { type NavListComponentProps } from './NavList';

type Props = {
  currentParent: string;
  isActiveNavItems: boolean;
} & NavListComponentProps;

const IconNavList = ({
  activeNavStack,
  cta,
  currentParent,
  decorators,
  isActiveNavItems,
  navItems = [],
  navLvl,
  parentNameList = [],
  setActiveNavStack,
  handleRestoreScroll,
  handleCloseAndResetNav,
}: Props) => (
  <ul
    data-testid={TestId.IconNavList}
    className={cn({
      'mb-4 grid grid-cols-2 gap-4 md:grid-cols-4':
        isActiveNavItems && parentNameList.length === activeNavStack.selectedStack.length,
    })}
  >
    {navItems?.map((item, index) => {
      const name = item.nameSEO ?? item.name;
      const mobileCta = item?.mobile?.cta;

      const hasChildren = !!item?.children?.length;
      const isLink = !hasChildren && item?.route;

      const href = isLink ? item.route : undefined;

      const handleClick = () => {
        if (isLink) handleCloseAndResetNav();
        else {
          setActiveNavStack({
            currentLvl: activeNavStack.currentLvl + 1,
            selectedStack: [...activeNavStack.selectedStack, name],
          });
        }
        handleRestoreScroll?.();
      };

      return (
        <li key={`navigation-${navLvl}-${item.name}-${item.route}-${index}`}>
          <MenuItemButton
            onClick={handleClick}
            href={href}
            className={cn(
              'flex h-[114px] flex-col items-center justify-center gap-2 rounded border border-solid border-border-neutral px-1 text-neutral hover:text-neutral',
              {
                'sr-only':
                  navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
              }
            )}
          >
            <RenderIcon
              className="min-h-8"
              name={item?.icon ?? 'photo'}
              stroke="var(--text-neutral)"
              width={32}
              height={32}
            />
            <span className="font-neutral-strong line-clamp-2 text-center text-base font-semibold">
              {name}
            </span>
          </MenuItemButton>
          {hasChildren && (
            <NavList
              activeNavStack={activeNavStack}
              cta={mobileCta ?? cta}
              decorators={decorators}
              navItems={item?.children ?? []}
              navLvl={navLvl + 1}
              parentNameList={[...parentNameList, name]}
              shopAll={item?.shopAll}
              viewAllRoute={item.route}
              setActiveNavStack={setActiveNavStack}
              handleRestoreScroll={handleRestoreScroll}
              handleCloseAndResetNav={handleCloseAndResetNav}
            />
          )}
        </li>
      );
    })}
  </ul>
);

export default IconNavList;
