import { useEffect, useState } from 'react';
import { checkAspectRatioSupported } from './checkAspectRatio';
import { aspectRatio, aspectRatioPolyfill } from './aspectRatio';

export const useAspectRatio = () => {
  const [isAspectRatioSupported, setIsAspectRatioSupported] = useState<boolean>(true);

  const handleAspectRatioPolyfill = (aspectRatioString: string) => {
    return isAspectRatioSupported
      ? aspectRatio(aspectRatioString)
      : aspectRatioPolyfill(aspectRatioString);
  };

  useEffect(() => {
    setIsAspectRatioSupported(checkAspectRatioSupported());
  }, []);

  return { handleAspectRatioPolyfill, isAspectRatioSupported };
};
