import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';
import { B2B_REFERRAL } from './constants';
import { addB2bCookie } from './addB2bCookie';

/**
 * A hook that checks if the user has the b2b referral query param and adds a cookie
 * @returns void
 *
 */
export const useB2bQuery = () => {
  const router = useRouter();

  useEffect(() => {
    router?.query?.referall === B2B_REFERRAL && addB2bCookie();
  }, [router?.query?.referall]);
};
