import { cn } from '../../../utils';

import type { RibbonType } from '../../../types';
import type { HTMLAttributes } from 'react';

type PromoRibbonProps = {
  type: RibbonType['type'];
  device: 'mobile' | 'desktop';
} & HTMLAttributes<HTMLDivElement>;

const PromoRibbon = ({
  type = 'sale',
  device = 'mobile',
  children,
  className,
}: PromoRibbonProps) => {
  if (!children) return null;

  return (
    <div
      className={cn(
        'antialiased',
        style.ribbon[device],
        {
          [style.ribbon.sale._]: type === 'sale',
          [style.ribbon.sale.dark]: type === 'sale',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default PromoRibbon;

const style = {
  ribbon: {
    mobile: 'p-1 text-xs leading-none tracking-[0.5px] font-bold uppercase rounded',
    desktop: 'p-0.5 ml-1 text-xs rounded font-bold leading-none',
    sale: {
      _: 'bg-background-primary text-white',
      dark: 'dark:bg-background-primary dark:text-white',
    },
  },
};
