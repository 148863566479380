import { useEffect, useRef, useCallback, type RefObject } from 'react';

export const useScrollToRef = <T extends HTMLDivElement>(): {
  ref: RefObject<T>;
  scrollToRef: () => void;
} => {
  const ref = useRef<T>(null); // T is the generic type, defaulting to HTMLElement
  const timeoutRef = useRef(null);

  const scrollToRef = useCallback(() => {
    if (!ref.current) return;

    const scroll = () => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    scroll();

    // Clear previous timeout before setting a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Handle potential layout shifts due to lazy loading
    timeoutRef.current = setTimeout(scroll, 1500);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { ref, scrollToRef };
};
