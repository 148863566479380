import { HTMLAttributes } from 'react';

import { cn } from '../../../utils';

const ClearSearchHistoryButton = ({ className, ...props }: HTMLAttributes<HTMLButtonElement>) => (
  <button
    className={cn(
      'mt-4 block cursor-pointer border-none bg-background-page p-0 text-sm font-normal leading-none text-neutral-faded underline lg:ml-auto lg:mt-0',
      className
    )}
    {...props}
  >
    Clear all
  </button>
);

export default ClearSearchHistoryButton;
