import { useHeaderContext } from '../../../context/HeaderContext';
import { cn, promoCardWidgetToDataLayer } from '../../../utils';
import Link from '../../common/Link';
import Image from '../../common/ui/Image';
import { useMenuContext } from '../Desktop/Menu/MenuContext';

import type { FeaturedColumnProps, PromoCardProps } from '../../../types';
import type { HTMLAttributes } from 'react';

type PromoCardWidgetProps = {
  featuredColumn: FeaturedColumnProps;
} & HTMLAttributes<HTMLDivElement>;

type PromoCardDimensions = {
  width: number;
  height: number;
};
type PromoCardPropsWithDimensions = PromoCardProps & PromoCardDimensions;

const bannerTop: PromoCardProps = {
  imageUrl:
    'https://images.prismic.io/virginexperiencedays/3d96fd70-b853-48ac-9fa5-557dceea56fb_birthday_gifts_x2.png',
  imageAlt: 'Birthday Gifts - Be A Gift Hero - Shop Now',
  route: '/birthday-gifts',
};

const bannerBottom: PromoCardProps = {
  imageUrl:
    'https://images.prismic.io/virginexperiencedays/a9ca5e7b-98bb-4cba-9c6a-2d1b21180885_family_days_x2.png',
  imageAlt: 'Family Days Out - Days To Remember - Shop Now',
  route: '/family-days-out',
};

const PromoCardWidget = ({ className, featuredColumn }: PromoCardWidgetProps) => {
  const { isFeaturedColumnFallback } = useHeaderContext();

  if (!featuredColumn) return null;
  const displayContentFallback = [bannerTop, bannerBottom];

  const displayContent =
    isFeaturedColumnFallback !== '1' && featuredColumn?.content?.length
      ? featuredColumn.content.slice(0, displayContentFallback.length)
      : displayContentFallback;

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {displayContent.map((item, index) => {
        const displayItem = { ...item };
        if (!displayItem?.imageUrl) {
          displayItem.imageUrl = displayContentFallback[index].imageUrl;
        }

        return (
          <PromoCard
            {...displayItem}
            width={320}
            height={200}
            key={`${displayItem.imageUrl}${index}`}
          />
        );
      })}
    </div>
  );
};

const PromoCard = ({ width, height, imageUrl, route, imageAlt }: PromoCardPropsWithDimensions) => {
  const { handleClose: handleForceCloseNav } = useMenuContext();

  if (!imageUrl) return null;

  const handleClick = () => {
    handleForceCloseNav();
    promoCardWidgetToDataLayer(`Linked To | ${route}`);
  };

  return (
    <div style={{ width, height }}>
      {route ? (
        <Link href={route} onClick={handleClick}>
          {/* UIUX prefers more HD than our default 80 */}
          <Image src={imageUrl} alt={imageAlt} width={width} height={height} quality={100} />
        </Link>
      ) : (
        <Image
          src={imageUrl}
          alt={imageAlt}
          width={width}
          height={height}
          quality={100}
          className="cursor-default"
        />
      )}
    </div>
  );
};

export default PromoCardWidget;
