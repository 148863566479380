import type { SvgElementProps } from '../../../../types';

const IconMobileMultiChoice = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  strokeWidth = '1.5',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.5 12H15.5M12.5 13.875H15.5M3.75 16.25H16.25C16.7473 16.25 17.2242 16.0525 17.5758 15.7008C17.9275 15.3492 18.125 14.8723 18.125 14.375V5.625C18.125 5.12772 17.9275 4.65081 17.5758 4.29917C17.2242 3.94754 16.7473 3.75 16.25 3.75H3.75C3.25272 3.75 2.77581 3.94754 2.42417 4.29917C2.07254 4.65081 1.875 5.12772 1.875 5.625V14.375C1.875 14.8723 2.07254 15.3492 2.42417 15.7008C2.77581 16.0525 3.25272 16.25 3.75 16.25ZM6.82833 10.0142L6.5 11L6.17167 10.0142C6.07962 9.73802 5.92454 9.48711 5.71872 9.28128C5.51289 9.07546 5.26197 8.92038 4.98583 8.82833L4 8.5L4.98583 8.17167C5.26197 8.07962 5.51289 7.92454 5.71872 7.71872C5.92454 7.51289 6.07962 7.26197 6.17167 6.98583L6.5 6L6.82833 6.98583C6.92038 7.26197 7.07546 7.51289 7.28128 7.71872C7.48711 7.92454 7.73802 8.07962 8.01417 8.17167L9 8.5L8.01417 8.82833C7.73802 8.92038 7.48711 9.07546 7.28128 9.28128C7.07546 9.48711 6.92038 9.73802 6.82833 10.0142Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobileMultiChoice;
