import { useState, type RefObject, useEffect } from 'react';

export const useHasOverflow = (ref: RefObject<HTMLElement>) => {
  const [hasYOverflow, setHasYOverflow] = useState(false);
  const [hasXOverflow, setHasXOverflow] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;

    function handleOverflow() {
      const { scrollHeight, scrollWidth, clientHeight, clientWidth } = ref.current;
      setHasYOverflow(scrollHeight > clientHeight);
      setHasXOverflow(scrollWidth > clientWidth);
    }
    handleOverflow();

    window.addEventListener('resize', handleOverflow);
    return () => window.removeEventListener('resize', handleOverflow);
  }, [ref]);

  return { x: hasXOverflow, y: hasYOverflow };
};
