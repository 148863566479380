import type { SvgElementProps } from '../../../types';

const IconMultiChoice = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  strokeWidth = '1.5',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M16.8 19.2H24.8M20 22.2H24.8M6 26H26C26.7956 26 27.5587 25.6839 28.1213 25.1213C28.6839 24.5587 29 23.7956 29 23V9C29 8.20435 28.6839 7.44129 28.1213 6.87868C27.5587 6.31607 26.7956 6 26 6H6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V23C3 23.7956 3.31607 24.5587 3.87868 25.1213C4.44129 25.6839 5.20435 26 6 26ZM10.9253 16.0227L10.4 17.6L9.87467 16.0227C9.72739 15.5808 9.47926 15.1794 9.14995 14.8501C8.82063 14.5207 8.41916 14.2726 7.97733 14.1253L6.4 13.6L7.97733 13.0747C8.41916 12.9274 8.82063 12.6793 9.14995 12.3499C9.47926 12.0206 9.72739 11.6192 9.87467 11.1773L10.4 9.6L10.9253 11.1773C11.0726 11.6192 11.3207 12.0206 11.6501 12.3499C11.9794 12.6793 12.3808 12.9274 12.8227 13.0747L14.4 13.6L12.8227 14.1253C12.3808 14.2726 11.9794 14.5207 11.6501 14.8501C11.3207 15.1794 11.0726 15.5808 10.9253 16.0227Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMultiChoice;
