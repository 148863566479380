import { useTheme } from '../../../../context/ThemeContext';
import { cn } from '../../../../utils';
import RenderIcon from '../../icons/RenderIcon';

import type { ButtonHTMLAttributes } from 'react';

type Props = {
  placeholder?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SearchBarButton = ({ placeholder, className, ...props }: Props) => {
  const { immersive } = useTheme();

  return (
    <button
      {...props}
      className={cn(
        'nowrap space-between dark:text-neutral-faded-high-contrast flex h-12 flex-row items-center gap-4 overflow-hidden border border-r-0 border-solid border-border-neutral bg-transparent px-4 text-grey-500',
        'lg:h-10 lg:rounded lg:border-r',
        { 'bg-white': immersive },
        className
      )}
    >
      <span className="flex-1 text-left text-base leading-none lg:text-sm lg:leading-none">
        {placeholder}
      </span>
      <RenderIcon name="search" fill={immersive ? '#2b3240' : 'var(--text-neutral-strong)'} />
    </button>
  );
};

export default SearchBarButton;
