import { type FormEvent, useRef, useEffect, CSSProperties } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import type { GiftFinderRangeComponent } from '../../types';
import { useRangeDefaults } from '../../hooks/useRangeDefaults';

type Props = {
  handleAnswer: (e: FormEvent) => void;
  name: string;
  onTouchStart?: (e: TouchEvent) => void;
  onTouchEnd?: (e: TouchEvent) => void;
} & Omit<GiftFinderRangeComponent, 'type'>;

const THUMB_SIZE = 24;
const DISABLED_INTERACTIONS = 'select-none pointer-events-none';

export const RangeSlider = ({
  name,
  min,
  max,
  step,
  fromValue,
  toValue,
  stepValues,
  handleAnswer,
  onTouchStart,
  onTouchEnd,
}: Props) => {
  useRangeDefaults({ fromValue, toValue, name, stepValues, handleAnswer });

  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    const { name, value } = target;

    const isFrom = name.includes('from');
    const isTo = name.includes('to');

    const validValue = (isFrom && Number(value) < toValue) || (isTo && Number(value) > fromValue);
    if (!validValue) return;

    const newValue = stepValues?.length ? stepValues?.[value] : value;
    const newEvent = { ...e, target: { ...target, type: 'range', name, value: String(newValue) } };

    handleAnswer(newEvent);
  };

  const sharedProps = { min, max, step, stepValues, handleChange, onTouchStart, onTouchEnd };
  const fromPercent = ((fromValue - min) * 100) / (max - min);
  const toPercent = ((toValue - min) * 100) / (max - min);

  return (
    <div
      className="py-4 md:py-8"
      style={
        {
          '--track-height': '4px',
          '--from-slider-z': 1,
          '--to-slider-z': 2,
          '--range-fill-z': 0,
          '--thumb-size': `${THUMB_SIZE}px`,
          '--range-group-height': `calc(${THUMB_SIZE}px * 2)`,
        } as CSSProperties
      }
    >
      <div className="relative h-[var(--range-group-height)] w-full">
        <Slider
          rangeInputClassName="z-[1] top-0.5 h-0"
          name={`${name}.from`}
          value={fromValue}
          {...sharedProps}
        />
        <Slider rangeInputClassName="z-2" name={`${name}.to`} value={toValue} {...sharedProps} />
        <div
          className={cn('absolute left-0 top-0 z-0 h-1 w-full rounded', DISABLED_INTERACTIONS)}
          style={{
            // inline as it would be hard to write such gradient with just tailwind whilst injecting dynamic values
            // It is still possible to use tailwind `from, via, to` properties
            backgroundImage: `linear-gradient(to right, 
							var(--background-primary-faded) 0%, 
							var(--background-primary-faded) ${fromPercent}%, 
							var(--text-brand) ${fromPercent}%, 
							var(--text-brand) ${toPercent}%, 
							var(--background-primary-faded) ${toPercent}%, 
							var(--background-primary-faded) 100%)`,
          }}
        />
      </div>
    </div>
  );
};

type SliderProps = {
  value: number;
  handleChange: Props['handleAnswer'];
  onTouchStart?: Props['onTouchStart'];
  onTouchEnd?: Props['onTouchEnd'];
  rangeInputClassName?: string;
} & Pick<Props, 'name' | 'min' | 'max' | 'step' | 'stepValues'>;

const Slider = ({
  name,
  min,
  max,
  step,
  value,
  stepValues,
  rangeInputClassName,
  handleChange,
  onTouchStart,
  onTouchEnd,
}: SliderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef?.current) return;
    const el = inputRef.current;

    onTouchStart && el.addEventListener('touchstart', onTouchStart);
    onTouchStart && el.addEventListener('touchmove', onTouchStart);
    onTouchEnd && el.addEventListener('touchend', onTouchEnd);

    return () => {
      onTouchStart && el.removeEventListener('touchstart', onTouchStart);
      onTouchStart && el.removeEventListener('touchmove', onTouchStart);
      onTouchEnd && el.removeEventListener('touchend', onTouchEnd);
    };
  }, [onTouchStart, onTouchEnd]);

  useEffect(() => {
    if (!inputRef?.current || !containerRef?.current) return;

    const min = Number(inputRef.current.min);
    const max = Number(inputRef.current.max);
    const value = Number(inputRef.current.value);

    const percent = ((value - min) * 100) / (max - min);
    const offset = (THUMB_SIZE / 100) * percent;

    containerRef.current.style.setProperty('--thumb-left', `calc(${percent}% - ${offset}px)`);
  }, [value]);

  let align: 'left' | 'right' | 'center' = 'center';
  if (value === min) align = 'left';
  else if (value === max) align = 'right';

  const stepValue = stepValues?.length ? stepValues?.[value] : value;

  return (
    <div ref={containerRef} className="absolute left-0 h-[var(--range-group-height)] w-full">
      <input
        className={cn(
          'absolute h-1 w-full appearance-none bg-transparent focus:outline-none',
          // we can technically extract it into something more reusable in the config
          // fe. ved-thumb
          // thumb
          '[&::-webkit-slider-thumb]:border-background-primary [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:h-[var(--thumb-size)] [&::-webkit-slider-thumb]:w-[var(--thumb-size)] [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:border-4 [&::-webkit-slider-thumb]:border-solid [&::-webkit-slider-thumb]:bg-white',
          // moz thumb
          'focus:outline-none [&::-moz-range-thumb]:h-[var(--thumb-size)] [&::-moz-range-thumb]:w-[var(--thumb-size)] [&::-moz-range-thumb]:cursor-pointer [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:border-4 [&::-moz-range-thumb]:bg-white',
          DISABLED_INTERACTIONS,
          rangeInputClassName
        )}
        ref={inputRef}
        type="range"
        name={name}
        min={min}
        max={max}
        step={step}
        value={value}
        aria-labelledby={`${name}-slider`}
        onChange={handleChange}
      />
      <div
        data-testid="false-thumb"
        className={cn('absolute bg-transparent', DISABLED_INTERACTIONS)}
        style={{
          top: 'calc((var(--thumb-size) / 2) * -1)',
          left: 'var(--thumb-left)',
          width: 'var(--thumb-size)',
          height: 'var(--thumb-size)',
        }}
      >
        <output
          id={`${name}-slider`}
          className={cn(
            'text-neutral-strong absolute top-0 top-[calc(var(--thumb-size)_+_5px)] w-[calc(1em_*_2.8)] text-base',
            align === 'center' && 'left-1/2 -translate-x-1/2 transform text-center', // default
            align === 'left' && 'left-0 text-left',
            align === 'right' && 'right-0 text-right',
            DISABLED_INTERACTIONS
          )}
        >
          £{stepValue}
        </output>
      </div>
    </div>
  );
};
