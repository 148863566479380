import type { SvgElementProps } from '../../../types';

const IconUserSmall = ({
  width = 32,
  height = 32,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M22.2858 9.22222C22.2858 12.6587 19.4715 15.4444 16.0001 15.4444C12.5286 15.4444 9.71436 12.6587 9.71436 9.22222C9.71436 5.78578 12.5286 3 16.0001 3C19.4715 3 22.2858 5.78578 22.2858 9.22222Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 18.1111C9.92487 18.1111 5 22.9862 5 29H27C27 22.9862 22.0751 18.1111 16 18.1111Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconUserSmall;
