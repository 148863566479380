import { HTMLAttributes } from 'react';

import { cn } from '../../utils';

const SaleBadge = ({ children, className }: HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      'bg-primary-500 p-0.5 text-xs font-semibold uppercase leading-none text-white',
      className
    )}
  >
    {children}
  </span>
);

export default SaleBadge;
