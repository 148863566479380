import NestedMenuItem from './NestedMenuItem';

import type { NormalizedNavItem } from '../../../../utils/normalizeNavigationItem';
import type { HTMLAttributes } from 'react';

type Level2ItemProps = {
  item: NormalizedNavItem;
  parentNameList: string[];
  displayIcon?: boolean;
  forceHoverStyle?: boolean;
  handleInteract: () => void;
} & HTMLAttributes<HTMLLIElement>;

const Level2Item = ({
  item,
  parentNameList,
  displayIcon,
  forceHoverStyle,
  handleInteract,
  children,
  ...props
}: Level2ItemProps) => (
  <li {...props}>
    <NestedMenuItem
      {...item}
      bold
      displayIcon={displayIcon}
      forceHoverStyle={forceHoverStyle}
      parentNameList={parentNameList}
      onClick={handleInteract}
      onFocus={handleInteract}
      onMouseEnter={handleInteract}
    />
    {children}
  </li>
);

export default Level2Item;
