import type { SvgElementProps } from '../../../types';

const IconMobilePaintBrush = ({
  width = 32,
  height = 32,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.9575 14.6146L17.3314 19.9885M10.4992 28.4877C8.5549 30.4321 4.845 29.946 2 29.946C3.45821 27.101 1.51388 23.3911 3.45821 21.4467C5.40253 19.5024 8.5549 19.5024 10.4992 21.4467C12.4435 23.3911 12.4435 26.5434 10.4992 28.4877ZM16.1135 21.3037L29.111 7.26641C30.2558 6.03002 30.2189 4.10996 29.0274 2.91848C27.836 1.727 25.9159 1.69009 24.6795 2.83489L10.6422 15.8324C9.91678 16.5041 9.55409 16.8399 9.34256 17.1981C8.83532 18.0569 8.81491 19.1187 9.28877 19.9964C9.48638 20.3624 9.83589 20.712 10.5349 21.411C11.234 22.11 11.5835 22.4595 11.9495 22.6572C12.8272 23.131 13.889 23.1106 14.7478 22.6034C15.106 22.3918 15.4418 22.0291 16.1135 21.3037Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobilePaintBrush;
