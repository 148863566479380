import { useHeaderContext } from '../../../context/HeaderContext';
import { useTheme } from '../../../context/ThemeContext';

import Image from './Image';

type Props = {
  src: string | { red: string; white: string };
  enforceRed?: boolean;
};

const Logo = ({ src, enforceRed }: Props) => {
  const { immersive } = useTheme();
  const { homeLink } = useHeaderContext();

  if (!src) throw new Error('VED logo is required');
  if (typeof src !== 'string' && !src.red) throw new Error('Red VED logo is required');

  const variantSrc = typeof src === 'string' ? src : immersive && !enforceRed ? src.white : src.red;

  const normalizedSrc = variantSrc.startsWith('/') ? variantSrc : `/${variantSrc}`;

  return (
    <Image
      src={`${homeLink}${normalizedSrc}`}
      alt="Virgin Experience Days Logo"
      width={100}
      height={40}
      loading="eager"
    />
  );
};

export default Logo;
