import type { Product } from '@virginexperiencedays/products';
import { ImpressionType } from './types';

export const impression = (
  { sku, searchIndex, title, suppliers, categoryHierarchy, promocode, price }: Product,
  position: number,
  list: string
) => {
  const { lvl0 } = categoryHierarchy ?? {};

  return {
    name: title,
    position,
    category: lvl0,
    list,
    metric6: 0,
    id: promocode ? `${sku} ${promocode}` : sku,
    searchIndex,
    brand: (suppliers?.length > 1 ? 'Multiple Suppliers' : suppliers?.[0]?.name) ?? null,
    price: price?.displayPrice,
  } as ImpressionType;
};

export function addToDataLayer(obj: any) {
  if (!obj) return;

  global.dataLayer = global.dataLayer || [];

  global.dataLayer.push(obj);
}
