import type { SvgElementProps } from '../../../types';

const IconLocationPin = ({
  className = '',
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.7707 11.1045L8.94202 13.9331C8.81831 14.057 8.67142 14.1552 8.50972 14.2222C8.34803 14.2892 8.17472 14.3237 7.99968 14.3237C7.82465 14.3237 7.65134 14.2892 7.48965 14.2222C7.32795 14.1552 7.18106 14.057 7.05735 13.9331L4.22802 11.1045C3.48217 10.3586 2.97424 9.40827 2.76848 8.37371C2.56271 7.33916 2.66834 6.26681 3.07202 5.29229C3.4757 4.31777 4.15928 3.48483 5.03634 2.89881C5.9134 2.31279 6.94453 2 7.99935 2C9.05417 2 10.0853 2.31279 10.9624 2.89881C11.8394 3.48483 12.523 4.31777 12.9267 5.29229C13.3304 6.26681 13.436 7.33916 13.2302 8.37371C13.0245 9.40827 12.5165 10.3586 11.7707 11.1045Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 7.33314C9.99935 7.86357 9.78864 8.37228 9.41357 8.74735C9.03849 9.12243 8.52978 9.33314 7.99935 9.33314C7.46892 9.33314 6.96021 9.12243 6.58514 8.74735C6.21006 8.37228 5.99935 7.86357 5.99935 7.33314C5.99935 6.80271 6.21006 6.294 6.58514 5.91893C6.96021 5.54385 7.46892 5.33314 7.99935 5.33314C8.52978 5.33314 9.03849 5.54385 9.41357 5.91893C9.78864 6.294 9.99935 6.80271 9.99935 7.33314Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconLocationPin;
