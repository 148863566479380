import type { FormEvent } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import type { GiftFinderState, QuizStep } from '../../../types';
import type { ReturnType } from '../../../hooks/useGiftFinder';
import { choiceLabelSpanStyles, choiceLabelInputStyles } from '../../shared-styles';

import { ChoiceContainer } from './ChoiceContainer';

type Props = {
  name: QuizStep['uuid'];
  options: QuizStep['options'];
  answers: GiftFinderState['answers'];
  handleAnswer: ReturnType['handleAnswer'];
};

export const SingleChoice = ({ answers, handleAnswer, name, options }: Props) => {
  const answer = answers?.[name]?.[0];

  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;

    // If the selected radio button is clicked again, set to null
    const newValue: string | null = answer === target.value ? null : target.value;
    handleAnswer(e, newValue);
  };

  return (
    <ChoiceContainer className="flex-col flex-nowrap gap-y-2">
      {options?.map(({ uuid, text }) => (
        <label key={uuid} className="relative">
          <input
            type="radio"
            name={name}
            value={uuid}
            className={choiceLabelInputStyles}
            checked={answer === uuid}
            onClick={handleChange}
            onChange={handleChange}
          />
          <span className={cn(choiceLabelSpanStyles, 'px-4 py-3 leading-tight')}>{text}</span>
        </label>
      ))}
    </ChoiceContainer>
  );
};
