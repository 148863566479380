import { useEffect, useState } from 'react';

const BREAKPOINT_DESKTOP = 768;

export const useWindowWidth = () => {
  const [width, setWidth] = useState<number>();
  useEffect(() => {
    setWidth(window.innerWidth);

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // detect by window width
  const isDesktop = () => {
    return width >= BREAKPOINT_DESKTOP;
  };

  // detect by window width AND touch events so we know if we are on a mobile or mobile-like device
  const isDesktopMouse = () => {
    return isDesktop() && window !== undefined && window?.ontouchstart !== undefined;
  };

  return { width, isDesktop, isDesktopMouse };
};
