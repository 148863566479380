import { useEffect, useState } from 'react';
import { checkFlexGapSupported } from './checkFlexGap';
import { flexGap, flexGapPolyfill } from './flexgap';

export const useFlexGap = () => {
  const [isFlexGapSupported, setIsFlexGapSupported] = useState<boolean>(true);

  const handleFlexGapPolyfill = (gap: string) => {
    return isFlexGapSupported ? flexGap(gap) : flexGapPolyfill(gap);
  };

  useEffect(() => {
    setIsFlexGapSupported(checkFlexGapSupported());
  }, []);

  return { handleFlexGapPolyfill, isFlexGapSupported };
};
