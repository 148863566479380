import type { SvgElementProps } from '../../../types';

const IconMobileSailboat = ({
  width = 29,
  height = 29,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.71744 23.089C1.6551 22.9254 1.77595 22.75 1.95106 22.75H27.0489C27.224 22.75 27.3449 22.9254 27.2826 23.089L25.3778 28.089C25.3409 28.1859 25.2479 28.25 25.1442 28.25H3.85582C3.75208 28.25 3.65913 28.1859 3.6222 28.089L1.71744 23.089Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
    <path
      d="M14.2002 17.3333H26.9039L14.2002 2"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2036 17.3333H1.49991L14 6.5"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.939 1C14.939 0.585786 14.6032 0.25 14.189 0.25C13.7748 0.25 13.439 0.585786 13.439 1L14.939 1ZM13.439 1L13.439 23L14.939 23L14.939 1L13.439 1Z"
      fill={themeColor || stroke}
    />
  </svg>
);

export default IconMobileSailboat;
