import { useState } from 'react';

type ValidateTypes = 'email';
type ValidityType = { isValid: boolean; message: string };
type InputFuncType = (value: string) => void;
type ReturnType = {
  value: string;
  valid: ValidityType;
  handleChange: InputFuncType;
  validate: () => ValidityType;
};

const initialValidState: ValidityType = { isValid: null, message: '' };

export const useValidateInputType = (type: ValidateTypes): ReturnType => {
  const [string, setString] = useState('');
  const [valid, setValid] = useState<ValidityType>(initialValidState);

  const handleChange: InputFuncType = (value) => {
    if (!valid.isValid) setValid(initialValidState);
    setString(value);
  };

  const validate = (): ValidityType => {
    // TODO replace with switch as types increase
    if (type === 'email') {
      const result = validateEmail(string);
      setValid(result);
      return result;
    }
  };

  return { value: string, valid, handleChange, validate };
};

type ValidatorsReturnType = { isValid: boolean; message: string };

// validator functions
export function validateEmail(value: string): ValidatorsReturnType {
  const isValid = /^(?=.{1,254}@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  return { isValid, message: isValid ? 'Email is valid' : 'Please enter a valid email address' };
}
