import Link from '../../../components/common/Link';
import { useHeaderContext } from '../../../context/HeaderContext';
import { generateRoute } from '../../../utils/generateRoute';
import Image from '../../common/ui/Image';

const Logo = ({ route = '/' }) => {
  const { NextLink, homeLink, siteLogoWhite } = useHeaderContext();
  const normalizedSrc = siteLogoWhite.startsWith('/') ? siteLogoWhite : `/${siteLogoWhite}`;

  return (
    <div className="order-3 mt-[56px] flex items-baseline lg:order-1 lg:mr-6 lg:mt-0">
      <Link
        href={generateRoute(route, { isNextLink: !!NextLink, homeLink })}
        className="flex h-10 w-[100px] lg:w-[126px] lg:border-r-2 lg:border-primary-600 lg:pr-6"
      >
        <Image
          src={`${homeLink}${normalizedSrc}`}
          alt="Virgin Experience Days Logo"
          loading="eager"
          fill
          objectFit="cover"
        />
      </Link>
    </div>
  );
};

export default Logo;
