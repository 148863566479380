import { useEffect } from 'react';

export const usePageShow = (callback: (e: PageTransitionEvent) => void) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('pageshow', callback);

    return () => {
      window.removeEventListener('pageshow', callback);
    };
  }, [callback]);
};
