const screens = {
  sm: '576px', // largeMobileUp
  md: '768px', // tabletUp
  lg: '992px', // largeTabletUp
  xl: '1200px', // smallDesktopUp
  '2xl': '1440px', // desktopUp
};
/**
 * Object containing breakpoints defined in the base tailwind config theme.
 * Each breakpoint is represented as a key-value pair, where the key is the breakpoint name
 * and the value is the numeric value in pixels.
 */
export const tailwindBreakpoints: Record<string, number> = Object.entries(screens)?.reduce(
  (acc, [key, value]) => {
    if (typeof value !== 'string' || !value.endsWith('px')) return acc;

    const numericValue = Number(value.replace('px', ''));
    if (!isNaN(numericValue)) acc[key] = numericValue;

    return acc;
  },
  {} as Record<string, number>
);
