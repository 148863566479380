import type { SvgElementProps } from '../../../types';

const IconLocationPinSmall = ({
  width = 14,
  height = 15,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.7707 10.1045L7.94202 12.9331C7.81831 13.057 7.67142 13.1552 7.50972 13.2222C7.34803 13.2892 7.17472 13.3237 6.99968 13.3237C6.82465 13.3237 6.65134 13.2892 6.48965 13.2222C6.32795 13.1552 6.18106 13.057 6.05735 12.9331L3.22802 10.1045C2.48217 9.35858 1.97424 8.40827 1.76848 7.37371C1.56271 6.33916 1.66834 5.26681 2.07202 4.29229C2.4757 3.31777 3.15928 2.48483 4.03634 1.89881C4.9134 1.31279 5.94453 1 6.99935 1C8.05417 1 9.08531 1.31279 9.96236 1.89881C10.8394 2.48483 11.523 3.31777 11.9267 4.29229C12.3304 5.26681 12.436 6.33916 12.2302 7.37371C12.0245 8.40827 11.5165 9.35858 10.7707 10.1045Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99935 6.33314C8.99935 6.86357 8.78864 7.37228 8.41357 7.74735C8.03849 8.12243 7.52978 8.33314 6.99935 8.33314C6.46892 8.33314 5.96021 8.12243 5.58514 7.74735C5.21006 7.37228 4.99935 6.86357 4.99935 6.33314C4.99935 5.80271 5.21006 5.294 5.58514 4.91893C5.96021 4.54385 6.46892 4.33314 6.99935 4.33314C7.52978 4.33314 8.03849 4.54385 8.41357 4.91893C8.78864 5.294 8.99935 5.80271 8.99935 6.33314Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconLocationPinSmall;
