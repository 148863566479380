import { FooterProps } from '../../types';

import Carousels from './Carousels';
import { Navigation } from './Navigation';
import { PersistentFooter } from './PersistentFooter';

export const Footer = ({ footerNav, footerSlider, footerPersistent }: FooterProps) => (
  <footer id="footer-2024" className="relative mt-20 grow bg-background-page">
    <div className="relative flex flex-col">
      <Carousels footerSlider={footerSlider} />
      <Navigation items={footerNav} />
      <PersistentFooter {...footerPersistent} />
    </div>
  </footer>
);

export default Footer;
