import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/compat/router';
import { useState, useEffect } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

// UI
import { CookiesModal } from '@virginexperiencedays/components-v2/src/modals/Cookie';
import { Image } from '@virginexperiencedays/components-v2/src/layout/Image';
import { LinkWrap } from '@virginexperiencedays/components-v2/src/navigation/LinkWrap';

// Header
import {
  Header,
  HeaderProvider,
  hasNavigationArray,
  useSwrNavigationCache,
  useValidateProducts,
  LocalStorage,
  FeaturedColumnProps,
} from '@virginexperiencedays/header-2024/src';
import { envVariables, navigationServiceUrl } from './envVariables';

// Footer
import { Footer } from '@virginexperiencedays/header-2024/src/components/Footer/Footer';
import { formatFooterRecentlyViewed } from '@virginexperiencedays/header-2024/src/utils/formatFooterRecentlyViewed';
import { formatFooterTopProducts } from '@virginexperiencedays/header-2024/src/utils/footerTopProducts/formatFooterTopProducts';

// Hooks
import { useScrollLock } from '@virginexperiencedays/hooks';

// Google Tag
import { setDomainCookie } from '../../utils/cookie';

// Tracking
import useFeatureFlags from '@virginexperiencedays/feature-flags/src/useFlags';
import { FBPixel } from '../tracking/fb';
import { useB2bQuery } from '../../utils/b2b/useB2bQuery';
import { useReportCfSplitTest } from '../tracking/useReportCfSplitTest';
import { addToDataLayer } from '../tracking/gtm/utils';

// Gift Finder - Replace with Dynamic Import once AB test is complete
import GiftFinder, {
  useGiftFinderContext,
} from '@virginexperiencedays/components-v2/src/drawers/GiftFinder';

import type { LayoutProps } from '.';

// Discount modal
const DiscountModal = dynamic(() => import('../DiscountModal'), {
  ssr: false,
});

const dyCampaignSelector = process.env.NEXT_PUBLIC_GLOBAL_CAMPAIGN_SELECTOR;

const Layout = ({
  children,
  preview = false,
  initialNavItems = [],
  topProducts,
  trackingPageType,
}: LayoutProps) => {
  const router = useRouter();
  const [isEnabled] = useFeatureFlags();

  // Cookie Consent
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const cookieConsent = 'cookie-consent';

  const setCookieConsent = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(cookieConsent, 'true');
      setShowCookieConsent(false);
    }
  };

  // Navigation Service
  const { header, footer } = useSwrNavigationCache(navigationServiceUrl, initialNavItems);

  const { main: headerMain, sub: headerSub, promo } = header;
  const { main: footerMain, sub: footerSub } = footer;
  // header navigation
  const navItems = hasNavigationArray(headerMain) ? headerMain.navigation : [];
  const featuredColumn =
    (headerMain as { featuredColumn?: FeaturedColumnProps })?.featuredColumn || null;
  const secondaryNavItems = hasNavigationArray(headerSub) ? headerSub.navigation : [];
  const promoBanner = promo as Record<string, any>;
  const links = (headerSub as { links?: Record<string, string | null> })?.links || {};
  // footer navigation
  const footerNav = hasNavigationArray(footerMain) ? footerMain.navigation : [];
  const helpLinks = hasNavigationArray(footerSub) ? footerSub.navigation : [];

  // Gift Finder
  const {
    state: { active: giftFinderActive },
  } = useGiftFinderContext();
  useScrollLock(giftFinderActive);

  useReportCfSplitTest({
    cookieKey: '_ved_cf_recorded',
    dyCampaignSelector,
    callback: addToDataLayer,
  });

  // Products
  const { products, loading, setStorageItem, setProducts } = useValidateProducts(
    LocalStorage.RecentlyViewedProducts,
    envVariables.productApiUrl,
    router
  );

  // Top Products
  const topProductsMapped = topProducts
    ? formatFooterTopProducts(topProducts, process.env.NEXT_PUBLIC_HOME_LINK, {
        topProductsOverwrite: { column: 'right' },
      })
    : null;

  // Recently Viewed
  const recentlyViewedMapped = formatFooterRecentlyViewed({
    products,
    loading,
    setStorageItem,
    setProducts,
    cookieDomain: envVariables.cookieDomain,
  });

  // Below useEffect is to fix the collision with C#, checking on the value of a `vp` cookie, if it matches the format [{"id": "7876", "added": 555}, {"id": "9182", "added": 5321}] we wanna clear it, otherwise we wanna leave it
  useEffect(() => {
    try {
      const cookies = global.document.cookie
        // split into an Array of key=value strings
        .split(';')
        // split each, so we have an Array of entries ([key, value])
        .map((str) => str.split('=').map((s) => s.trim()) as [string, string]);

      if (!cookies) return;

      for (const [key, value] of cookies) {
        if (key === 'vp') {
          const parsed = JSON.parse(value);
          if (parsed.length > 0 && parsed.some((item) => item.id && item.added)) {
            setDomainCookie('vp', '', { maxAge: 5 });
          }
        }
      }
    } catch (e) {
      // do nothing
    }
  }, []);

  // close modal on accept or URL change
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem(cookieConsent) === 'true';
    const currentPath = router.asPath;

    const handleRouteChange = ({ url }) => {
      if (showCookieConsent === true && url !== currentPath) {
        setCookieConsent();
      }
    };

    router?.events?.on('routeChangeStart', handleRouteChange);
    handleRouteChange({ url: currentPath });

    setShowCookieConsent(!cookiesAccepted);

    return () => {
      router?.events?.off('routeChangeStart', handleRouteChange);
    };
  }, [showCookieConsent, router]);

  const [affinityQuery, setAffinityQuery] = useState<string>();
  useEffect(() => {
    if (router?.query?.bid !== null) {
      setAffinityQuery(router?.query?.bid as string);
    }
  }, [router?.query?.bid]);

  // Set b2b cookie if query param is present
  useB2bQuery();

  return (
    <>
      {process.env.NEXT_PUBLIC_FB_PIXEL_ID && isEnabled('FF_fb_pixel') && <FBPixel />}
      {affinityQuery && <DiscountModal affinityQuery={affinityQuery} />}
      {preview && (
        <div className="bg-primary p-6 font-bold">
          THIS IS PREVIEW MODE [<LinkWrap href="/api/endpreview">close</LinkWrap>]
        </div>
      )}
      <HeaderProvider
        NextImage={Image}
        NextLink={Link}
        {...envVariables}
        trackingPageType={trackingPageType}
      >
        <Header
          theme="light"
          immersive={false}
          navigation={{ navItems, featuredColumn, secondaryNavItems, promoBanner, links }}
        />
        <main data-testid="site-main">{children}</main>
        {/* Container to keep background for cookie consent */}
        <div>
          <CookiesModal show={showCookieConsent} onClick={setCookieConsent} />
          <div
            className={cn(
              'absolute inset-0 z-[600] h-full w-full bg-transparent',
              showCookieConsent ? 'initial' : 'hidden',
              'md:hidden'
            )}
          />
        </div>
        <Footer
          footerTags={null}
          footerNav={footerNav}
          footerSlider={
            [
              {
                sliderMapKey: 'footer-slider-map-key-1',
                slider: recentlyViewedMapped,
              },
              {
                sliderMapKey: 'footer-slider-map-key-2',
                slider: { ...topProductsMapped, displayEndCard: true },
              },
            ] as any
          }
          footerPersistent={{
            helpLinks,
            copyright: '© Virgin Experience Days Ltd',
          }}
        />
      </HeaderProvider>
      <GiftFinder />
    </>
  );
};

export default Layout;
