import type { SvgElementProps } from '../../../types';

const IconMobileCocktail = ({
  width = 32,
  height = 32,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M16 19.5L28.7002 3.6247C29.2241 2.96993 28.7579 2 27.9194 2H4.08062C3.24212 2 2.77594 2.96993 3.29976 3.62469L16 19.5ZM16 19.5V30M7.6 9H24.4M7.25 30H24.75"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobileCocktail;
