import { TestId } from '../../../../enums';
import { cn } from '../../../../utils';
import Image from '../../../common/ui/Image';

import MenuItemButton from './MenuItemButton';
import NavList, { type NavListComponentProps } from './NavList';

const IMAGE_FALLBACK =
  'https://images.prismic.io/virginexperiencedays-dev/f9da06a2-a101-4cb5-9f7d-4b594b80df13_cardMedia.png?auto=compress,format';

type Props = {
  currentParent: string;
  isActiveNavItems: boolean;
} & NavListComponentProps;

const ImageNavList = ({
  activeNavStack,
  cta,
  currentParent,
  decorators,
  isActiveNavItems,
  navItems = [],
  navLvl,
  parentNameList = [],
  setActiveNavStack,
  handleRestoreScroll,
  handleCloseAndResetNav,
}: Props) => (
  <ul
    data-testid={TestId.ImageNavList}
    className={cn({
      'mb-4 grid grid-cols-2 gap-x-2 gap-y-4 md:grid-cols-4':
        isActiveNavItems && parentNameList.length === activeNavStack.selectedStack.length,
    })}
  >
    {navItems?.map((item, index) => {
      const name = item.nameSEO ?? item.name;
      const mobileCta = item?.mobile?.cta;

      const hasChildren = !!item?.children?.length;
      const isLink = !hasChildren && item?.route;

      const href = isLink ? item.route : undefined;

      const handleClick = () => {
        if (isLink) handleCloseAndResetNav();
        else {
          setActiveNavStack({
            currentLvl: activeNavStack.currentLvl + 1,
            selectedStack: [...activeNavStack.selectedStack, name],
          });
        }
        handleRestoreScroll?.();
      };

      return (
        <li key={`navigation-${navLvl}-${item.name}-${item.route}-${index}`}>
          <MenuItemButton
            onClick={handleClick}
            href={href}
            className={cn(
              'flex flex-col items-center justify-center gap-2 text-neutral hover:text-neutral',
              {
                'sr-only':
                  navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
              }
            )}
          >
            <div className="w-full overflow-hidden rounded ">
              <Image
                src={typeof item?.image === 'string' ? item?.image : IMAGE_FALLBACK}
                alt={name}
                loading="eager"
                priority
                className="aspect-video"
                objectFit="cover"
                // for non-next apps
                width={171}
                height={114}
                // for next13 images
                fill
              />
            </div>
            <span className={cn('font-neutral-strong text-center text-base font-semibold')}>
              {name}
            </span>
          </MenuItemButton>
          {hasChildren && (
            <NavList
              activeNavStack={activeNavStack}
              cta={mobileCta ?? cta}
              decorators={decorators}
              navItems={item.children}
              navLvl={navLvl + 1}
              parentNameList={[...parentNameList, name]}
              shopAll={item?.shopAll}
              viewAllRoute={item.route}
              setActiveNavStack={setActiveNavStack}
              handleRestoreScroll={handleRestoreScroll}
              handleCloseAndResetNav={handleCloseAndResetNav}
            />
          )}
        </li>
      );
    })}
  </ul>
);

export default ImageNavList;
