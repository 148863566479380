import { useHeaderContext } from '../../../context/HeaderContext';
import useAlgoliasearch from '../../../hooks/useAlgoliasearch';
import { cn } from '../../../utils';
import { searchToDataLayer } from '../../../utils/addToDataLayer';
import { generateRoute } from '../../../utils/generateRoute';
import { highlightSearch } from '../../../utils/highlightSearch';

import SearchSectionTitle from './SearchSectionTitle';

export type SuggestionsProps = { name?: string; route?: string };
export type RenderSuggestionsProps = {
  title?: string | null;
  searchSuggestions?: SuggestionsProps[];
  defaultSuggestions?: SuggestionsProps[];
};

const SearchTermSuggestions = ({
  title,
  searchSuggestions,
  defaultSuggestions,
}: RenderSuggestionsProps) => {
  const { searchState } = useHeaderContext();

  return (
    <div className="w-full lg:w-[320px] lg:max-w-[320px]">
      {title && <SearchSectionTitle>{title}</SearchSectionTitle>}
      <ul className={style.termSuggestions.list}>
        <RenderSuggestions
          searchSuggestions={searchSuggestions}
          defaultSuggestions={defaultSuggestions}
          searchTerm={searchState.searchTerm}
        />
      </ul>
    </div>
  );
};

const RenderSuggestions = ({
  searchSuggestions = [],
  defaultSuggestions = [],
  searchTerm = '',
}: { searchTerm?: string } & Omit<RenderSuggestionsProps, 'title'>) => {
  const { NextLink, homeLink, handleUpdateSearchState } = useHeaderContext();
  const { siteSearch } = useAlgoliasearch();
  const clearedState = {
    searchTerm: '',
    searchOverlay: false,
    showPageOverlay: false,
  };

  const initialRoute = generateRoute('search', { isNextLink: !!NextLink, homeLink });

  // BEFORE search, show default suggestions
  if (searchTerm.length < 3 && defaultSuggestions.length > 0) {
    return (
      <>
        {defaultSuggestions.map(({ name = '' }, index: number) => (
          <li
            className={style.termSuggestions.listItem}
            key={`termSuggestionItem-${name}-${index}`}
          >
            <button
              onClick={() => {
                handleUpdateSearchState(clearedState);
                searchToDataLayer(name);
                siteSearch(name, initialRoute).catch((error) => console.error(error));
              }}
              className={style.termSuggestions.listItemButton}
            >
              <span>{name}</span>
            </button>
          </li>
        ))}
      </>
    );
  }
  // AFTER search, if ANY search suggestions, show them
  if (searchSuggestions.length > 0 && searchTerm.length >= 3) {
    return (
      <>
        {searchSuggestions.map(({ name = '' }, index: number) => (
          <li
            className={style.termSuggestions.listItem}
            key={`termSuggestionItem-${name}-${index}`}
          >
            <button
              className={cn(style.termSuggestions.listItemButton, {
                'font-normal': searchSuggestions.length,
              })}
              dangerouslySetInnerHTML={{ __html: highlightSearch(name, searchTerm) }}
              onClick={() => {
                handleUpdateSearchState(clearedState);
                searchToDataLayer(name);
                siteSearch(name, initialRoute).catch((error) => console.error(error));
              }}
            />
          </li>
        ))}
      </>
    );
  }
  // AFTER search, if NO search suggestions, show SRP link
  if (searchTerm.length >= 3 && searchSuggestions.length <= 0) {
    return (
      <li className={style.termSuggestions.listItem}>
        <button
          className="text-center leading-6 lg:text-left xl:break-all"
          onClick={() => {
            handleUpdateSearchState(clearedState);
            siteSearch(searchTerm, initialRoute).catch((error) => console.error(error));
          }}
        >
          <span className="font-semibold">&lsquo;{searchTerm}&rsquo;</span>
        </button>
      </li>
    );
  }
  return null;
};

export default SearchTermSuggestions;

const style = {
  termSuggestions: {
    list: 'flex flex-col p-0 m-0 list-none',
    listItem: 'mb-4 last-of-type:mb-0',
    listItemButton:
      'text-left cursor-pointer break-words border-0 bg-none p-0 font-bold text-neutral hover:text-primary-500 lg:leading-tight',
  },
};
