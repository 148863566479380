import type { SvgElementProps } from '../../../types';

const IconSaleTag = ({
  width = 16,
  height = 16,
  fill = 'var(--text-neutral)',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.66667 4.66667H4.67333H4.66667ZM4.66667 2H8C8.34133 2 8.68267 2.13 8.94267 2.39067L13.6093 7.05733C13.8593 7.30737 13.9997 7.64645 13.9997 8C13.9997 8.35355 13.8593 8.69263 13.6093 8.94267L8.94267 13.6093C8.69263 13.8593 8.35355 13.9997 8 13.9997C7.64645 13.9997 7.30737 13.8593 7.05733 13.6093L2.39067 8.94267C2.26667 8.81901 2.16833 8.67208 2.10128 8.51031C2.03424 8.34854 1.99982 8.17511 2 8V4.66667C2 3.95942 2.28095 3.28115 2.78105 2.78105C3.28115 2.28095 3.95942 2 4.66667 2V2Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSaleTag;
