import { useEffect, type ReactNode, useRef, useState } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { useGiftFinderContext } from '../context';

type Props = {
  children: ReactNode;
};

export const GiftFinderTrack = ({ children }: Props) => {
  const [displayChildren, setDisplayChildren] = useState(false);
  const {
    state: { active, quiz, step, trackSwipable },
  } = useGiftFinderContext();
  const steps = quiz.length;

  const ref = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  // scroll the track to the current step
  useEffect(() => {
    if (!ref?.current) return;

    const trackWidth = ref.current.scrollWidth;
    const viewWidth = trackWidth / steps;

    ref.current.scrollTo({ left: viewWidth * step, behavior: 'smooth' });
  }, [step, steps]);

  // disable scrolling on the track
  // ? this is required as `overflow: hidden` impacts the smooth scroll and card transitions behaviour on iOS 15
  useEffect(() => {
    if (!trackRef?.current) return;
    const element = trackRef.current;

    const disableTouchMove = (e: MouseEvent) => e.preventDefault();

    element.addEventListener('touchmove', disableTouchMove, { passive: true });
    return () => element.removeEventListener('touchmove', disableTouchMove);
  }, []);

  // when active is true display instantly, when active is false display after 200ms
  // ? this is required as we need to unmount the form components to reset their state
  useEffect(() => {
    let t: any;
    if (active) setDisplayChildren(true);
    else t = setTimeout(() => setDisplayChildren(false), 200);

    return () => clearTimeout(t);
  }, [active]);

  return (
    <div
      ref={ref}
      className={cn(
        'hide-scrollbar h-full w-full snap-x snap-mandatory scroll-pl-[var(--gf-gutter)] overflow-y-hidden scroll-smooth pl-[var(--gf-gutter)]',
        trackSwipable ? 'overflow-x-auto' : 'overflow-x-hidden' // ios 15 safari
      )}
    >
      <div
        ref={trackRef}
        className="grid gap-x-8 py-4 md:py-8"
        style={{
          gridTemplateColumns: `repeat(${steps}, var(--gf-card-width))`,
          width: `calc(var(--gf-card-width) * ${steps} + var(--gf-gutter) * 3)`,
        }}
      >
        {displayChildren && children}
      </div>
    </div>
  );
};
