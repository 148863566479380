import { useEffect, useState } from 'react';

const fetchConfig: RequestInit = {
  cache: 'no-cache',
  credentials: 'include',
  redirect: 'follow',
  referrer: 'no-referrer',
};

const useBasketCount = (homeUrl: string) => {
  const [basketCount, setBasketCount] = useState(0);

  if (!homeUrl) throw new Error('Failed to fetch basket: missing home url');
  const httpRegex = /^https?:\/\//i;

  if (!httpRegex.test(homeUrl)) {
    throw new Error(`'homeUrl' must be a valid HTTP or HTTPS URL string`);
  }

  useEffect(() => {
    const getBasket = async () => {
      await fetch(`${homeUrl.replace(/\/$/, '')}/basket/data/`, fetchConfig)
        .then((res: Response) => {
          if (res.ok) return res.json();
          else
            throw new Error(
              `Failed to fetch basket: fetch call returned ${res.status} ${res.statusText}`
            );
        })
        .then((responseJSON) => {
          setBasketCount(responseJSON.ItemCount);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    };

    getBasket();
  }, [homeUrl]);

  const handleSetBasketCount = (count: number) => {
    setBasketCount(count);
  };

  return { basketCount, handleSetBasketCount };
};

export default useBasketCount;
