import type { SvgElementProps } from '../../../types';

const IconMobileTeaCup = ({
  width = 28,
  height = 30,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28 30"
    fill="none"
    {...rest}
  >
    <path
      d="M1 10.2352C1 17.415 4.54546 23.2352 11.6364 23.2352C18.7273 23.2352 22.2727 17.415 22.2727 10.2352H1Z"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6362 6.09886V1.37158"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5454 6.09887V3.73523"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.72705 6.09887V3.73523"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2036 16.5122C21.7885 14.9854 22.1335 13.2708 22.2385 11.4471C22.4424 11.4272 22.6512 11.4169 22.8638 11.4169C25.1483 11.4169 27.0002 12.6074 27.0002 14.076C27.0002 15.5446 25.1483 16.7351 22.8638 16.7351C22.2733 16.7351 21.7118 16.6555 21.2036 16.5122Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 25.0588H18.1765"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default IconMobileTeaCup;
