import Link from '../../../components/common/Link';
import SaleBadge from '../../../components/common/SaleBadge';
import { useHeaderContext } from '../../../context/HeaderContext';
import { cn } from '../../../utils';
import { enabledPromotions } from '../../../utils/enabledPromotions';
import { generateSrcSet } from '../../../utils/generateSrcSet';
import { roundPrice } from '../../../utils/roundPrice';
import {
  BlackFridayBadge,
  CyberMondayBadge,
  ChristmasBadge,
  ValentinesBadge,
} from '../../common/icons/PromotionBadge';
import Image from '../../common/ui/Image';

import type { SearchResultCardProps } from '../../../types';
import type { ReactNode } from 'react';

const SearchResultCard = ({
  cardRoute,
  cardImage,
  cardTitle,
  currentPrice,
  pastPrice,
  onClick,
  badge = '',
  isBlackFridayProduct,
  isChristmasProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
}: SearchResultCardProps) => {
  const { NextImage, blackFridayBadge, cyberMondayBadge, christmasBadge, valentinesDayBadge } =
    useHeaderContext();
  const {
    isBlackFriday,
    isCyberMonday,
    isChristmas,
    isEaster,
    isMothersDay,
    isFathersDay,
    isValentines,
  } = enabledPromotions({
    isBlackFridayProduct,
    isChristmasProduct,
    isEasterProduct,
    isMothersDayProduct,
    isFathersDayProduct,
    isValentinesProduct,
  });

  let promoBadgeText = null;
  if (isFathersDay) promoBadgeText = 'DAD APPROVED';
  else if (isMothersDay) promoBadgeText = `MOTHER'S DAY SPECIAL`;
  else if (isEaster) promoBadgeText = 'EASTER GIFTING';

  // Priority Order: Christmas, Cyber Monday, Black Friday
  let PromoBadge = null;
  switch (true) {
    case isValentines:
      PromoBadge = ValentinesBadge;
      break;
    case isChristmas:
      PromoBadge = ChristmasBadge;
      break;
    case isCyberMonday:
      PromoBadge = CyberMondayBadge;
      break;
    case isBlackFriday:
      PromoBadge = BlackFridayBadge;
      break;
  }

  const roundedCurrentPrice = roundPrice(currentPrice);
  const roundedPastPrice =
    typeof pastPrice === 'number' && pastPrice > 0 ? roundPrice(pastPrice) : null;
  const displayReduction = pastPrice !== currentPrice;

  return (
    <Link
      className="group flex no-underline lg:flex-col"
      href={cardRoute}
      onClick={() => onClick?.(cardTitle)}
    >
      <div className="relative mr-3 inline-flex h-20 w-20 shrink-0 overflow-hidden rounded lg:mb-auto lg:mr-0 lg:aspect-square lg:h-auto lg:max-h-[188px] lg:w-auto">
        <Image
          src={cardImage}
          srcSet={generateSrcSet(cardImage, [
            { width: 128, breakpoint: '640w' },
            { width: 256, breakpoint: '992w' },
            { width: 384, breakpoint: '1450w' },
            { width: 640, breakpoint: '2560w' },
          ])}
          alt={cardTitle}
          fill
          objectFit="cover"
        />
        {!!promoBadgeText ? (
          <PromotionTag isOverlay>{promoBadgeText}</PromotionTag>
        ) : (
          <>
            {badge && <SaleBadge className="absolute left-0 top-0">{badge}</SaleBadge>}
            {!!PromoBadge && (
              <PromoBadge
                NextImage={NextImage}
                envVariables={{
                  blackFridayBadge,
                  cyberMondayBadge,
                  christmasBadge,
                  valentinesDayBadge,
                }}
                className={cn(style.promoBadge._, {
                  [style.promoBadge.isOnEdgeRight]: !!isChristmas,
                })}
              />
            )}
          </>
        )}
      </div>
      <div className="flex flex-col">
        {!!promoBadgeText && (
          <div>
            <PromotionTag>{promoBadgeText}</PromotionTag>
          </div>
        )}
        <h5 className="mb-4 mt-2 text-sm font-semibold leading-normal text-neutral-strong group-hover:text-primary-500">
          {cardTitle}
        </h5>
        <div className="flex items-baseline">
          <div className="font-semibold leading-none text-neutral-strong">
            {roundedCurrentPrice}
          </div>
          {displayReduction && (
            <div className="ml-1 text-sm leading-none text-neutral-faded line-through">
              {roundedPastPrice}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
export default SearchResultCard;

const PromotionTag = ({ children, isOverlay }: { children: ReactNode; isOverlay?: boolean }) => (
  <p
    className={cn(
      'm-0 rounded bg-background-neutral-strong px-1 py-0.5 text-xs font-semibold uppercase leading-base text-background-page',
      isOverlay ? 'absolute left-1 top-1 my-0 ml-0 mr-1 hidden lg:block' : 'inline-block lg:hidden',
      // ! NOTE : Change according to active promo theme color
      style.promoTag.fathersDay
    )}
  >
    {children}
  </p>
);

const style = {
  promoBadge: {
    _: 'absolute bottom-2 w-1/2 max-w-[100px] right-2',
    isOnEdgeRight: 'right-0',
  },
  promoTag: {
    generic: 'bg-primary-100 text-grey-700', // mothers day, easter
    blackFriday: 'bg-black text-white',
    fathersDay: 'bg-background-page text-neutral border border-solid border-border-neutral',
  },
};
