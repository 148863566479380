import { type ReactNode, useRef } from 'react';

import { useHasOverflow } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

type Props = {
  children: ReactNode;
  className?: string;
};

export const ChoiceContainer = ({ children, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { y: hasOverflow } = useHasOverflow(ref);

  return (
    <div
      className={cn(
        'gf-scrollbar flex h-full w-full overflow-y-auto overflow-x-hidden',
        hasOverflow && 'pr-4',
        className
      )}
      ref={ref}
    >
      {children}
    </div>
  );
};
