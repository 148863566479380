import { type FormEvent, useEffect } from 'react';
import { GiftFinderRangeComponent } from '../types';

type HookParams = {
  name: string;
  handleAnswer: (e: FormEvent) => void;
} & Pick<GiftFinderRangeComponent, 'fromValue' | 'toValue' | 'stepValues'>;

/**
 * Ensures the initial values of a range component are registered as answers
 *
 * @param name - The name of the range input.
 * @param fromValue - The default starting value for the range input.
 * @param toValue - The default ending value for the range input.
 * @param stepValues - An array of possible value overrides for the range input steps.
 * @param handleAnswer - A function to handle the range input answer.
 */
export const useRangeDefaults = ({
  name,
  fromValue,
  toValue,
  stepValues,
  handleAnswer,
}: HookParams): void => {
  useEffect(() => {
    if (!fromValue || !toValue) return;

    const from = stepValues?.[fromValue] || fromValue;
    const to = stepValues?.[toValue] || toValue;

    const mockFromEvent = {
      target: { type: 'range', name: `${name}.from`, value: String(from) },
    } as unknown as FormEvent;

    const mockToEvent = {
      target: { type: 'range', name: `${name}.to`, value: String(to) },
    } as unknown as FormEvent;

    handleAnswer(mockFromEvent);
    handleAnswer(mockToEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
