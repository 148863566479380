import { useState } from 'react';

import { DyId } from '../../../../enums';
import { useIsMounted } from '../../../../hooks';
import { cn } from '../../../../utils';
import {
  type NormalizedNavItem,
  normalizeNavigationItem,
} from '../../../../utils/normalizeNavigationItem';
import { MIN_LIST_WIDTH } from '../../../common/ui/ListContainer';
import PromoCardWidget from '../../Widgets/PromoCardWidget';

import ActiveLvl3List from './ActiveLvl3List';
import Level2Item from './Level2Item';
import Level3Menu from './Level3Menu';

import type { EnvVariables, FeaturedColumnProps, HeaderProps } from '../../../../types';

type Props = {
  item: Omit<NormalizedNavItem, 'children'>;
  featuredColumn: FeaturedColumnProps;
  parentNameList: string[];
  displayIcon?: boolean;
  homeLink: EnvVariables['homeLink'];
} & Pick<HeaderProps, 'NextImage' | 'NextLink'>;

/**
 * Renders the Level 2 list component and child components.
 */
const Level2Menu = ({ item, featuredColumn, displayIcon, parentNameList = [] }: Props) => {
  const isMounted = useIsMounted();
  const { childItems: level2 } = item;

  const defaultFocusedLvl2 = level2?.[0]?.name ?? null;
  const [focusedLvl2, setFocusedLvl2] = useState<string | null>(defaultFocusedLvl2);

  const handleFocusedLvl2 = (name?: string) => {
    setFocusedLvl2(name ?? null);
  };

  const handleMouseLeave = () => {
    setFocusedLvl2(defaultFocusedLvl2);
  };

  if (!Array.isArray(level2)) return null;
  return (
    <div
      className={cn(
        '-m-px mt-[-9px] grid w-full grid-cols-[repeat(3,auto)] pt-[9px]',
        'before:absolute before:right-[calc(100%-1px)] before:top-0 before:h-full before:w-10 before:bg-transparent',
        'after:absolute after:left-[calc(100%-1px)] after:top-0 after:h-full after:w-10 after:bg-transparent'
      )}
      onMouseLeave={handleMouseLeave}
    >
      <div className={cn(style.list.container)}>
        <ul className={cn(style.list._)} role="list">
          {level2.map((lvl2, index: number) => {
            const fmtLvl2 = normalizeNavigationItem(lvl2);
            const itemName = fmtLvl2?.nameSEO ?? fmtLvl2?.name;

            const handleInteract = () => handleFocusedLvl2(fmtLvl2.name);

            return (
              <Level2Item
                key={`${fmtLvl2.name} - ${index}`}
                item={fmtLvl2}
                parentNameList={[...parentNameList, itemName]}
                displayIcon={displayIcon}
                handleInteract={handleInteract}
                forceHoverStyle={focusedLvl2 === fmtLvl2.name}
              >
                <Level3Menu
                  {...fmtLvl2}
                  className="sr-only"
                  activeLvl2={focusedLvl2}
                  parentNameList={[...parentNameList, itemName]}
                  parentRoute={fmtLvl2?.route}
                />
              </Level2Item>
            );
          })}
        </ul>
      </div>
      {isMounted && (
        <div className={cn(style.list.container, 'lg:border-r')}>
          <div className={cn(style.list._)}>
            <ActiveLvl3List {...item} activeLvl2={focusedLvl2} parentNameList={parentNameList} />
          </div>
        </div>
      )}
      <div data-dy={DyId.NavWidgets} className={cn(style.list.widgets, 'border-none')}>
        <PromoCardWidget className={style.list._} featuredColumn={featuredColumn} />
      </div>
    </div>
  );
};

export default Level2Menu;

const style = {
  list: {
    _: `h-[580px] max-h-[70dvh] overflow-y-auto mega-nav-scrollbar ${MIN_LIST_WIDTH}`,
    container: 'p-4 w-72 border-solid border-r border-border-neutral h-full',
    widgets: 'p-4',
  },
};
