import {
  forwardRef,
  useImperativeHandle,
  useRef,
  type Dispatch,
  type ForwardedRef,
  type SetStateAction,
} from 'react';

import { TestId } from '../../../enums';
import { ctaLinkToDataLayer } from '../../../utils';
import LinkList from '../../common/LinkList';
import StarRating from '../../common/StarRating';
import ButtonLink from '../../common/ui/buttons/ButtonLink';
import Divider from '../../common/ui/Divider';
import { DEFAULT_NAV_STATE } from '../../Header/HeaderMenu';

import NavList, { NavStackState } from './NavigationBar/NavList';

import type { HeaderProps, LinkType } from '../../../types';

type MegaNavMobileProps = {
  navItems: HeaderProps['navigation']['navItems'];
  secondaryNavItems: HeaderProps['navigation']['secondaryNavItems'];
  links: HeaderProps['navigation']['links'];
  mobileNavOpen: boolean;
  activeNavStack: NavStackState;
  setActiveNavStack: Dispatch<SetStateAction<NavStackState>>;
  handleToggleMobileNav: () => void;
};

const MegaNavMobile = forwardRef(
  (
    {
      navItems = [],
      secondaryNavItems = [],
      links,
      mobileNavOpen,
      activeNavStack,
      setActiveNavStack,
      handleToggleMobileNav,
    }: MegaNavMobileProps,
    forwardedRef?: ForwardedRef<HTMLElement>
  ) => {
    const asideRef = useRef<HTMLElement>(null);

    const handleCloseAndResetNav = () => {
      setActiveNavStack(DEFAULT_NAV_STATE);
      handleToggleMobileNav();
    };

    const handleTrackCtaAndReset = (value: string, route: string) => {
      ctaLinkToDataLayer(value, route);
      handleCloseAndResetNav();
    };

    const handleRestoreScroll = () => {
      if (!asideRef?.current) return;
      asideRef.current.scrollTop = 0;
    };

    // Expose the aside element's DOM node via forwarded ref
    useImperativeHandle(forwardedRef, () => asideRef.current as HTMLElement);

    const myVoucherLink = links?.myVoucher;
    const bestSellersLink = links?.bestSellers;

    return (
      <aside
        id="mega-nav-mobile"
        ref={asideRef}
        tabIndex={mobileNavOpen ? 0 : -1}
        className={style.sidebar._}
      >
        <div className="relative p-4 pb-10">
          <nav data-testid={TestId.MobileNavigation} className="relative">
            <NavList
              navItems={navItems}
              activeNavStack={activeNavStack}
              navLvl={DEFAULT_NAV_STATE.currentLvl}
              setActiveNavStack={setActiveNavStack}
              handleRestoreScroll={handleRestoreScroll}
              handleCloseAndResetNav={handleCloseAndResetNav}
            />
          </nav>
          {activeNavStack.currentLvl === 1 && (
            <>
              <Divider className="my-2" />
              <div>
                <LinkList
                  links={secondaryNavItems.flat() as LinkType[]}
                  data-testid={TestId.SecondaryNavList}
                  handleCloseAndResetNav={handleCloseAndResetNav}
                />
                <Divider className="mb-6" />
                <StarRating
                  title="Loved by over 1 million customers"
                  rating={5}
                  className="mb-4"
                  shadedColor="#2b3240"
                  data-testid={TestId.MainUsp}
                />
                {!!myVoucherLink && (
                  <ButtonLink
                    data-testid={TestId.VoucherLink}
                    href={myVoucherLink}
                    onClick={() =>
                      handleTrackCtaAndReset('Already have a voucher?', myVoucherLink as string)
                    }
                    className="mb-2"
                  >
                    Already have a voucher?
                  </ButtonLink>
                )}
                {!!bestSellersLink && (
                  <ButtonLink
                    href={bestSellersLink}
                    isPrimary
                    data-testid={TestId.MainCtaLink}
                    onClick={() =>
                      handleTrackCtaAndReset('Shop Bestsellers', bestSellersLink as string)
                    }
                  >
                    Shop Bestsellers
                  </ButtonLink>
                )}
                <p className="mt-4 text-center text-[13px] font-semibold leading-[13px] text-neutral">
                  Free instant email delivery available
                </p>
              </div>
            </>
          )}
        </div>
      </aside>
    );
  }
);

export default MegaNavMobile;

const style = {
  sidebar: {
    _: 'fixed inset-0 top-[120px] z-50 -translate-x-full overflow-y-auto overflow-x-hidden bg-background-page text-neutral transition-transform duration-300 lg:hidden',
    open: 'translate-x-0',
  },
};
