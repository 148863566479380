import { useEffect, MutableRefObject } from 'react';

type HookProps = {
  ref: MutableRefObject<Element | null>;
  scrollTo?: [number, number];
  deps?: any[];
};

export const useRefScrollTo = ({ ref, scrollTo = [0, 0], deps = [] }: HookProps) => {
  useEffect(() => {
    if (!ref?.current || typeof ref.current?.scrollTo !== 'function') return;
    ref.current?.scrollTo(scrollTo[0], scrollTo[1]);
  }, [ref, ...deps]);
};
