import { AnchorHTMLAttributes } from 'react';

import { useTheme } from '../../../../context/ThemeContext';
import { cn } from '../../../../utils';
import Link, { type NextLinkProps } from '../../Link';

type Props = {
  isPrimary?: boolean;
} & NextLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

const ButtonLink = ({ children, href, className, isPrimary, ...rest }: Props) => {
  const { immersive } = useTheme();

  return (
    <Link
      className={cn(
        'border-text-neutral flex items-center justify-center rounded border border-solid p-4 text-lg font-semibold leading-none text-neutral hover:text-neutral',
        'lg:hover:border-background-primary lg:hover:bg-background-primary-highlight lg:hover:text-white',
        { [style.button.primary]: isPrimary, [style.button.secondary]: !isPrimary },
        { [style.button.immersive]: immersive },
        className
      )}
      href={href}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;

const style = {
  button: {
    primary: 'border-background-primary bg-background-primary text-white hover:text-white',
    secondary: 'border-border-neutral-inverse bg-background-page text-neutral hover:text-neutral',
    immersive: 'lg:bg-transparent lg:border-persistent-text-light lg:text-persistent-text-light',
  },
};
