import type { SvgElementProps } from '../../../types';

const IconPaintbrushSmall = ({
  width = 18,
  height = 18,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6.68998 8.20834L9.76078 11.2791M5.8567 16.1359C4.74566 17.2469 2.62572 16.9691 1 16.9691C1.83326 15.3434 0.722218 13.2235 1.83326 12.1124C2.9443 11.0014 4.74566 11.0014 5.8567 12.1124C6.96774 13.2235 6.96774 15.0248 5.8567 16.1359ZM9.06485 12.0307L16.492 4.00938C17.1462 3.30287 17.1251 2.20569 16.4442 1.52485C15.7634 0.844003 14.6662 0.822909 13.9597 1.47708L5.93838 8.90425C5.52388 9.28805 5.31662 9.47995 5.19575 9.68461C4.9059 10.1754 4.89423 10.7821 5.16501 11.2837C5.27793 11.4928 5.47765 11.6925 5.8771 12.092C6.27655 12.4914 6.47627 12.6912 6.68543 12.8041C7.18698 13.0749 7.79371 13.0632 8.28448 12.7733C8.48915 12.6525 8.68105 12.4452 9.06485 12.0307Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconPaintbrushSmall;
