import { cn } from '../../utils';
import { roundStarRating } from '../../utils/productRating';

import RenderIcon from './icons/RenderIcon';

export type StarRatingProps = {
  title: string;
  rating: number;
  className?: string;
  shouldRoundUp?: boolean;
  shadedColor?: string;
  emptyColor?: string;
};

const StarRating = ({
  title,
  rating,
  className,
  shouldRoundUp,
  shadedColor,
  emptyColor,
}: StarRatingProps) => {
  // Round rating to nearest 0.5 (rounding up if flag set) to account for half stars
  const roundedRating = roundStarRating(rating, { shouldRoundUp }) ?? 0;

  // create full stars equal to rating
  const fullStars = Array.from({ length: Math.floor(roundedRating) }, () => ({ type: 'full' }));

  // add a half star if rating has a non-zero decimal part
  const halfStars = roundedRating % 1 === 0 ? [] : [{ type: 'half' }];

  // create empty stars up to 5
  const emptyStars = Array.from({ length: 5 - fullStars.length - halfStars.length }, () => ({
    type: 'empty',
  }));

  const starRatings = [...fullStars, ...halfStars, ...emptyStars];

  return (
    <div
      className={cn(style.starRating._, style.starRating.positive, className)}
      data-rating={rating}
    >
      <span className="flex">
        {[...Array(5)].map((_, i) => (
          <RenderIcon
            key={i}
            name="star"
            type={starRatings[i].type as 'full' | 'half' | 'empty'}
            shadedColor={shadedColor}
            emptyColor={emptyColor}
          />
        ))}
      </span>
      <span>{title}</span>
    </div>
  );
};

export default StarRating;

const style = {
  starRating: {
    _: `flex items-center justify-center gap-2 px-2 py-1 text-[13px] font-semibold leading-none`,
    positive: 'bg-[#EBF7E3] text-[#2b3240]',
  },
};
