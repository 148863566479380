import type { SvgElementProps } from '../../../types';

const IconMobileFootball = ({
  width = 30,
  height = 30,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.0009 9.1051L9.45264 13.7594L10.9175 19.6671H15.0009H19.0842L20.5491 13.7594L15.0009 9.1051Z"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5498 13.7593L24.4049 12.1259L26.2847 6.78406"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9266 16.019L24.4028 12.126"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.45218 13.7593L5.59708 12.1259L3.71729 6.78406"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.07324 16.019L5.59699 12.126"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9.10507V4.90726L19.6667 1.7981"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3335 1.79724L15.0002 4.90714"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.083 19.6667L21.1247 23.1667L19.083 28.3438"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2828 23.1667H21.2705"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9167 19.6667L8.875 23.1667L10.9436 28.3803"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.74365 23.1667H8.8748"
      stroke={themeColor || stroke}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobileFootball;
