// Note its a copy from the original hooks library -- its to prevent peerDeps on header
import { useEffect, useState } from 'react';

import { aspectRatio, aspectRatioPolyfill } from './aspectRatio';
import { checkAspectRatioSupported } from './checkAspectRatio';

export const useAspectRatio = () => {
  const [isAspectRatioSupported, setIsAspectRatioSupported] = useState<boolean>(true);

  const handleAspectRatioPolyfill = (aspectRatioString: string) =>
    isAspectRatioSupported
      ? aspectRatio(aspectRatioString)
      : aspectRatioPolyfill(aspectRatioString);

  useEffect(() => {
    setIsAspectRatioSupported(checkAspectRatioSupported());
  }, []);

  return { handleAspectRatioPolyfill };
};
