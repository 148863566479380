import { normalizeNavigationItem } from '../../../../utils/normalizeNavigationItem';

import Level3Menu, { type Props } from './Level3Menu';

/**
 * Renders the active level 3 list component.
 * This is meant to be a client-side only component to avoid duplicate rendering
 * the HTML for all level 3 lists on the server
 */
const ActiveLvl3List = ({ childItems, activeLvl2, parentNameList = [] }: Props) => {
  const lvl2Item = childItems?.find((item) => item.name === activeLvl2);

  if (!lvl2Item) return null;

  const { name, route } = childItems?.find((item) => item.name === activeLvl2) ?? {};
  const fmtItem = normalizeNavigationItem(lvl2Item);

  if (!name) return null;

  return (
    <Level3Menu
      activeLvl2={activeLvl2}
      parentRoute={route}
      parentNameList={[...parentNameList, name]}
      {...fmtItem}
    />
  );
};

export default ActiveLvl3List;
