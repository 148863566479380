import { type ReactNode, createContext, useContext } from 'react';

import type { ThemeType } from '../types';

type Context = {
  theme?: ThemeType;
  immersive?: boolean;
};

const ThemeContext = createContext<Context>({
  theme: 'light',
  immersive: false,
});

type Props = {
  children: ReactNode;
} & Context;

export const ThemeProvider = ({ theme = 'light', immersive = false, children }: Props) => (
  <ThemeContext.Provider value={{ theme, immersive }}>{children}</ThemeContext.Provider>
);

export const useTheme = () => useContext(ThemeContext);
