import type { EnvVariables, HeaderProps } from '../../../types';

type Props = {
  className?: HTMLElement['className'];
  NextImage: HeaderProps['NextImage'];
  envVariables: {
    blackFridayBadge?: EnvVariables['blackFridayBadge'];
    cyberMondayBadge?: EnvVariables['cyberMondayBadge'];
    christmasBadge?: EnvVariables['christmasBadge'];
    valentinesDayBadge?: EnvVariables['valentinesDayBadge'];
    mothersDayBadge?: EnvVariables['mothersDayBadge'];
    fathersDayBadge?: EnvVariables['fathersDayBadge'];
  };
  badgeProps?: {
    src?: string;
    alt: string;
    width: number;
    height: number;
  };
};
const BlackFridayBadge = ({ className, NextImage, envVariables, badgeProps }: Props) => {
  const props: Props['badgeProps'] = badgeProps ?? {
    // this intentionally returns undefined if not set to avoid a broken image
    src: envVariables?.blackFridayBadge,
    alt: 'Black Friday Badge',
    width: 100,
    height: 64,
  };

  if (!props?.src) return null;
  return (
    <div className={className}>
      {NextImage ? <NextImage layout="responsive" {...props} /> : <img {...props} />}
    </div>
  );
};

const CyberMondayBadge = (props: Pick<Props, 'NextImage' | 'envVariables'>) => (
  <BlackFridayBadge
    {...props}
    badgeProps={{
      // this intentionally returns undefined if not set to avoid a broken image
      src: props?.envVariables?.cyberMondayBadge,
      alt: 'Cyber Monday Badge',
      width: 100,
      height: 64,
    }}
  />
);

const ChristmasBadge = (props: Pick<Props, 'NextImage' | 'envVariables'>) => (
  <BlackFridayBadge
    {...props}
    badgeProps={{
      // this intentionally returns undefined if not set to avoid a broken image
      src: props?.envVariables?.christmasBadge,
      alt: 'Christmas Badge',
      width: 80,
      height: 65,
    }}
  />
);

const ValentinesBadge = (props: Pick<Props, 'NextImage' | 'envVariables'>) => (
  <BlackFridayBadge
    {...props}
    badgeProps={{
      // this intentionally returns undefined if not set to avoid a broken image
      src: props?.envVariables?.valentinesDayBadge,
      alt: 'Valentines Badge',
      width: 80,
      height: 69,
    }}
  />
);

export { BlackFridayBadge, CyberMondayBadge, ChristmasBadge, ValentinesBadge };
