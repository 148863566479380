import { roundStarRating } from '../../../../utils/productRating';
import StarRating from '../StarRating';

export type ReviewsProps = {
  averageRating: number;
  count: number;
  maxRating?: number;
};

export const RatingsAndReviews = ({ averageRating, count }: ReviewsProps) => {
  if (typeof averageRating !== 'number' || typeof count !== 'number') return null;

  // Apply rating logic to decide whether to render Star Rating or not
  const roundedRating = roundStarRating(averageRating);

  if (!roundedRating) return null;

  return (
    <div className="flex flex-row flex-nowrap items-center gap-2">
      <StarRating rating={averageRating} />
      <span className="text-xs font-medium leading-normal text-neutral-faded">({count})</span>
    </div>
  );
};
