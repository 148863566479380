import { type ReactNode, createContext, useContext, useReducer, Dispatch, useMemo } from 'react';

import { ActionType, type GiftFinderState } from '../types';
import { giftFinderInitialState as initialState } from './initialState';
import { reducer } from './reducer';

import { useGiftFinder } from '../hooks/useGiftFinder';
import { useRouter } from 'next/compat/router';

type GiftFinderContextType = {
  state: GiftFinderState;
  dispatch: Dispatch<ActionType>;
};

const GiftFinderContext = createContext<GiftFinderContextType>({
  state: initialState,
  dispatch: () => {},
});

type Props = {
  children: ReactNode;
};

const GiftFinderContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <GiftFinderContext.Provider value={value}>{children}</GiftFinderContext.Provider>;
};

const useGiftFinderContext = () => {
  const router = useRouter();
  const { state, dispatch } = useContext(GiftFinderContext);
  const giftFinderUtils = useGiftFinder(state, dispatch, router);

  if (!state || !dispatch) {
    throw new Error('useGiftFinderContext must be used within a GiftFinderContextProvider');
  }

  return { ...giftFinderUtils, state, dispatch };
};

export { GiftFinderContextProvider, useGiftFinderContext };
