import DefaultLayout from './VariationLayout';

import type { ReactNode } from 'react';
import type { ProductMapType } from '@virginexperiencedays/products';

export type LayoutProps = {
  children: ReactNode;
  preview?: boolean;
  topProducts: ProductMapType;
  initialNavItems?: any;
  trackingPageType?: string;
};

const Layout = ({ children, ...props }: LayoutProps) => (
  <DefaultLayout {...props}>{children}</DefaultLayout>
);

export default Layout;
