type SessionStorageHook = {
  setValue: <T>(key: string, value: T) => void;
  getValue: <T>(key: string) => T | null;
  removeValue: (key: string) => void;
  clearAllValues: () => void;
};

export const handleSessionStorage = (): SessionStorageHook => {
  const setValue = <T>(key: string, value: T): void => {
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error setting session storage value:', error);
    }
  };

  const getValue = <T>(key: string): T | null => {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (error) {
      console.error('Error getting session storage value:', error);
      return null;
    }
  };

  const removeValue = (key: string): void => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing session storage value:', error);
    }
  };

  const clearAllValues = (): void => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error clearing all session storage values:', error);
    }
  };

  return {
    setValue,
    getValue,
    removeValue,
    clearAllValues,
  };
};
