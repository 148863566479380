import { useState } from 'react';

import Container from '../../../components/common/Container';
import RenderIcon from '../../../components/common/icons/RenderIcon';
import Link from '../../../components/common/Link';
import { cn } from '../../../utils';
import { footerLinkToDataLayer } from '../../../utils/addToDataLayer';

import type { ItemsProps } from '../../../types';

export type NavLinksProps = {
  items: ItemsProps[];
  level?: number;
  parentName?: string;
};

const linksToBlankTab = ['https://www.virginexperiencegifts.com/', '/giftcardbalance'];

const isLinkToBlankTab = (route: string) =>
  linksToBlankTab.some((linkToBlankTab) => {
    const regex = new RegExp(`${linkToBlankTab}(/)?$`);
    return route.match(regex);
  });

const NavLinks = ({ items = [], level = 1, parentName }: NavLinksProps) => {
  const [expand, setExpand] = useState<Record<number, boolean>>({});
  const handleListLinkClick = ({ name, index }: { name: string; index: number }) => {
    // tracking: only for level 2 links
    if (level > 1) {
      footerLinkToDataLayer({
        section: parentName,
        value: name,
      });
    }

    // set expand state
    setExpand((prevState) => ({ ...prevState, [index]: !prevState?.[index] }));
  };

  return (
    <ul
      role="footer-navigation"
      className={cn(
        'm-0 flex list-none flex-col p-0 lg:ml-auto lg:mr-auto lg:max-w-7xl lg:flex-row',
        { grow: level === 1 },
        { ['invisible h-0 opacity-0 lg:visible lg:h-auto lg:flex-col lg:opacity-100']: level === 2 }
      )}
    >
      {items.map(({ name, route, children }, index) => (
        <li
          key={`footerTag-${route}-${name}-${index}`}
          className={cn('flex flex-col overflow-hidden rounded text-sm last-of-type:mr-0', {
            ['[&>ul]:visible [&>ul]:mb-2 [&>ul]:h-auto [&>ul]:opacity-100 [&>ul]:lg:mb-0']:
              expand[index],
          })}
        >
          <Link
            href={route}
            target={isLinkToBlankTab(route) ? '_blank' : '_self'}
            asSpan={level === 1}
            onClick={() => handleListLinkClick({ name, index })}
            className={cn(
              'text-nowrap flex items-center justify-between overflow-hidden text-ellipsis px-0 py-4 text-sm leading-base text-grey-700 text-neutral no-underline transition-colors delay-150 ease-linear hover:text-primary-500',
              'lg:mb-0 lg:mr-8 lg:py-2 lg:[&>svg]:hidden',
              level === 1 && 'cursor-pointer font-semibold hover:text-neutral lg:cursor-auto'
            )}
          >
            {name}
            {Array.isArray(children) && children.length > 0 && (
              <RenderIcon
                name={expand[index] ? 'chevron-down' : 'chevron-right'}
                device="mobile"
                width="10px"
                height="10px"
              />
            )}
          </Link>
          {Array.isArray(children) && children?.length > 0 && (
            <NavLinks items={children} level={level + 1} parentName={name} />
          )}
        </li>
      ))}
    </ul>
  );
};

export const Navigation = ({ items }: NavLinksProps) => (
  <Container className="relative isolate overflow-hidden pt-[120px]">
    <div className="mb-10 flex">
      <NavLinks items={items} />
    </div>
    <RenderIcon
      name="rounded-square"
      className="absolute right-0 top-0 z-[-1] hidden h-[650px] w-[750px] text-background-primary-faded lg:block"
    />
  </Container>
);
