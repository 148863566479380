import Link from './Link';

import type { LinkType } from '../../types';
import type { HTMLAttributes } from 'react';

type LinkListComponentProps = {
  links: LinkType[];
  handleCloseAndResetNav: () => void;
} & HTMLAttributes<HTMLUListElement>;

const LinkList = ({ links, handleCloseAndResetNav, ...props }: LinkListComponentProps) => {
  if (!links?.length) {
    return null;
  }

  return (
    <ul {...props}>
      {links?.map(({ route, name }) => (
        <li key={name}>
          <Link
            href={route}
            className="flex h-full w-full py-3 text-base font-medium leading-4 text-neutral-strong hover:text-neutral-strong"
            role="link"
            onClick={handleCloseAndResetNav}
          >
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
