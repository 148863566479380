import { useRef } from 'react';

import { useInView } from '../../../../hooks/useInView';
import { BodyText } from '../../ui/BodyText';
import ButtonLink from '../../ui/buttons/ButtonLink';
import { DisplayHeading } from '../DisplayHeading';

type CarouselEndCardProps = {
  title?: string;
  overTitle?: string;
  ctaLink?: string;
  trackCtaLink?: () => void;
  trackOnReached?: () => void;
  ctaTitle?: string;
};

const CarouselEndCard = ({
  title,
  overTitle,
  ctaLink,
  trackCtaLink,
  trackOnReached,
  ctaTitle,
}: CarouselEndCardProps) => {
  const firedOnce = useRef(false);
  const endCardRef = useRef(null);

  const intersectionCallback = (isIntersecting: boolean) => {
    if (trackOnReached && isIntersecting && !firedOnce?.current) {
      trackOnReached();
      firedOnce.current = true;
    }
  };

  useInView({
    elementRef: endCardRef,
    options: { threshold: 0.9 },
    callbackFn: intersectionCallback,
  });

  if (!title) return null;

  return (
    <div
      className="flex h-full flex-col justify-center overflow-hidden rounded-lg border border-border-neutral bg-background-elevation-raised p-6"
      ref={endCardRef}
    >
      <BodyText
        className="mb-2 font-semibold uppercase tracking-[0.04em] text-brand"
        size="small"
        leading="none"
      >
        {overTitle}
      </BodyText>
      <DisplayHeading as="h2" className="mb-6 text-2xl leading-base md:mb-0">
        {title}
      </DisplayHeading>
      {ctaLink && ctaTitle && (
        <ButtonLink
          href={ctaLink}
          onClick={trackCtaLink}
          className="mr-auto mt-6 inline-block border-0 px-4 py-3 text-sm leading-[14px] text-white"
          isPrimary
        >
          {ctaTitle}
        </ButtonLink>
      )}
    </div>
  );
};

export default CarouselEndCard;
