export class GTMSingleton {
  private virtualDataLayer: Record<any, any>[] = [];

  private static instance: GTMSingleton = new GTMSingleton();

  private constructor() {}

  public static getInstance() {
    return GTMSingleton.instance;
  }
  /**
   * Adds the given items to the virtual data layer to be dispatched later
   * @param items The item to be tracked
   */
  public enqueue(items: Record<any, any>[]) {
    if (!items?.length) return;

    this.virtualDataLayer.push(...items);
  }
  /**
   * Dispatches all enqueued items
   */
  public dispatch() {
    // overwrite window.dataLayer
    // but make sure to use mutative methods on the same array all the way through
    global.dataLayer = global.dataLayer || [];
    global.dataLayer.splice(0, global.dataLayer.length);
    // NOTE: use Array#push, as GTM seems to watch this via Proxy
    global.dataLayer.push(...this.virtualDataLayer);
    this.virtualDataLayer = [];
  }
  /**
   * Dispatch items immediately
   * @param items The item(s) to be tracked
   */
  public track(items: Record<any, any>[]) {
    if (!items?.length) return;

    global.dataLayer = global.dataLayer || [];
    global.dataLayer.push(...items);
  }
}
