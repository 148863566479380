import Container from '../../../components/common/Container';
import { cn } from '../../../utils';
import { TopExperiences } from '../../common/ui/TopExperiences';

import type { FooterProps } from '../../../types';

const Carousels = ({ footerSlider }: Pick<FooterProps, 'footerSlider'>) => {
  if (!Array.isArray(footerSlider) || !footerSlider.length) return null;
  const slidersCount = footerSlider.filter(({ slider }) => slider?.cards?.length).length;
  return (
    <Container>
      <div className="grid grid-flow-dense grid-cols-1 gap-[72px] md:grid-cols-2 md:gap-14">
        {footerSlider.map(({ sliderMapKey, slider }) => (
          <TopExperiences
            key={sliderMapKey}
            className={cn(slidersCount === 1 && 'col-span-full')}
            href={slider?.ctaLink}
            ctaLabel={slider?.ctaLabel}
            products={slider?.cards ?? []}
            hasRecentlyViewed={slidersCount > 1}
            title={slider?.title}
            dataTestId={slider?.dataTestId}
            displayEndCard={slider?.displayEndCard}
            onClick={slider?.onClick}
            tracking={{
              module:
                slider?.title?.toLowerCase() === 'recently viewed'
                  ? 'footer_recently_viewed'
                  : 'footer_top_experiences',
            }}
          />
        ))}
      </div>
    </Container>
  );
};

export default Carousels;
