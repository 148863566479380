import type { SvgElementProps } from '../../../types';

const IconBriefcase = ({
  className = '',
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.4 4.88928C10.4 4.31063 10.4 4.02131 10.3387 3.78393C10.1722 3.13976 9.68704 2.6366 9.06587 2.464C8.83697 2.40039 8.55798 2.40039 8 2.40039C7.44202 2.40039 7.16303 2.40039 6.93413 2.464C6.31296 2.6366 5.82777 3.13976 5.66133 3.78393C5.6 4.02131 5.6 4.31063 5.6 4.88928M3.92 13.6004H12.08C12.7521 13.6004 13.0881 13.6004 13.3448 13.4648C13.5706 13.3454 13.7542 13.1551 13.8692 12.9209C14 12.6547 14 12.3062 14 11.6093V6.88039C14 6.18344 14 5.83496 13.8692 5.56876C13.7542 5.3346 13.5706 5.14423 13.3448 5.02492C13.0881 4.88928 12.7521 4.88928 12.08 4.88928H3.92C3.24794 4.88928 2.91191 4.88928 2.65521 5.02492C2.42942 5.14423 2.24584 5.3346 2.13079 5.56876C2 5.83496 2 6.18344 2 6.88039V11.6093C2 12.3062 2 12.6547 2.13079 12.9209C2.24584 13.1551 2.42942 13.3454 2.65521 13.4648C2.91191 13.6004 3.24794 13.6004 3.92 13.6004Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8C6.78877 9.53213 9.4117 9.47795 14 8"
      stroke={themeColor || fill}
      strokeWidth="1.5"
    />
    <line
      x1="5.75"
      y1="8.75"
      x2="5.75"
      y2="9.65"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="11.1504"
      y1="8.75"
      x2="11.1504"
      y2="9.65"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default IconBriefcase;
