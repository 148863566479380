import { useCallback } from 'react';

export const useAutoFocusCallbackRef = ({ deps = [] }: { deps?: Array<boolean> }) => {
  const inputRef = useCallback(
    (inputElement: HTMLElement | null) => {
      if (inputElement && deps.every((dep: unknown) => dep)) {
        inputElement.focus();
      }
    },
    [deps]
  );

  return inputRef;
};
