import { useCallback, useState, type UIEvent } from 'react';

type UseTouchMoveProps = {
  // callback
  onTouchEnd?: (data: { position: number; direction: 'previous' | 'next' }) => void;
};

type UseTouchMoveScrollingProps = {
  // Current scroll position
  pos: number;
  // Scroll position before (on touch start)
  posBefore: number;
  // Scroll position after (on touch end)
  posAfter: number;
  // Scroll width
  scrollWidth: number;
  // Client width of the total scrollable area
  clientWidth: number;
  // Flag to identify if the scroll is at the end
  isEnd: boolean;
  // Flag to identify if it is in a middle of a scroll (between touch start and touch end)
  active: boolean;
};

type UseTouchMoveData = {
  scrolling: UseTouchMoveScrollingProps;
  handleScroll: (e: UIEvent<HTMLDivElement>) => void;
  handleTouchStart: () => void;
  handleTouchEnd: () => void;
};

export const useTouchMove = ({ onTouchEnd }: UseTouchMoveProps): UseTouchMoveData => {
  const [scrolling, setScrolling] = useState<UseTouchMoveScrollingProps>({
    pos: 0,
    posBefore: 0,
    posAfter: 0,
    scrollWidth: 0,
    clientWidth: 0,
    isEnd: false,
    active: false,
  });

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const { scrollLeft: pos, scrollWidth, clientWidth } = e.currentTarget;
    setScrolling((prev) => ({
      ...prev,
      pos,
      scrollWidth,
      clientWidth,
      isEnd: pos >= scrollWidth - clientWidth - 2,
    }));
  }, []);

  const handleTouchStart = () => {
    if (scrolling.active) return;
    setScrolling((prev) => ({ ...prev, active: true, posBefore: prev.pos, posAfter: null }));
  };

  const handleTouchEnd = () => {
    if (scrolling.posBefore === scrolling.pos) return;
    const direction = scrolling.posBefore < scrolling.pos ? 'next' : 'previous';
    onTouchEnd?.({
      position: scrolling.pos,
      direction,
    });
    setScrolling((prev) => ({ ...prev, active: false, posAfter: prev.pos }));
  };

  return { scrolling, handleScroll, handleTouchStart, handleTouchEnd };
};
