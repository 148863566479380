import {
  type ReactNode,
  forwardRef,
  useEffect,
  useRef,
  type HTMLAttributes,
  type CSSProperties,
} from 'react';
import { cn } from '@virginexperiencedays/tailwind';
import { useKeyRelease } from '@virginexperiencedays/hooks';

import { IconCloseModal } from '../../icons/IconCloseModal';

// Any additional custom styling like header inline padding/stickRight /overflow should be defined per use case
// Unless it becomes common then should be added here to speed up development
// This can be further refactored into 3 components for easier customisation
// 1. SideDrawer
// 2. SideDrawerHeading
// 3. SideDrawerContent
// Invocation would look like this:
// <SideDrawer isOpen={isOpen} hideDrawer={hideDrawer} drawerTitle={drawerTitle}>
//  <SideDrawerHeading>...</SideDrawerHeading>
//  <SideDrawerContent>...</SideDrawerContent>
//  </SideDrawer>

export type SideDrawerProps = {
  className?: string;
  headerClass?: string;
  contentClass?: string;
  style?: CSSProperties;
  dataTestId?: string;
  /** Control wether drawer is open or not */
  isOpen: boolean;
  /**
   * Method to close drawer
   */
  hideDrawer: () => void;
  /**
   * Content to render inside drawer
   */
  children: ReactNode;
  /**
   * Title to render at the top of the drawer, next to close button
   * Can be react children or string
   */
  drawerTitle: () => ReactNode | string;
  /**
   * Control header wether sticky or static
   */
  headerAlign?: 'sticky' | 'shadow-sticky' | 'static';
  /**
   * Flag whether overall max width of the drawer is narrow or not
   */
  isNarrow?: boolean;
  /**
   * Flag whether drawer content should have flex layout or not
   */
  flexLayout?: boolean;
  /**
   * Flag whether drawer should focus on close button when open or
   */
  focusOnOpen?: boolean;
  /**
   * Flag whether drawer should allow event default or not
   */
  allowEventDefault?: boolean;
  /**
   * Tab index for drawer
   */
  tabIndex?: number;
  /**
   *  Id for drawer
   */
  id?: string;
  /**
   *  Flag whether drawer should stay positioned to the right of the screen
   */
  stickRight?: boolean;
  /**
   * Fill color of the close icon SVG
   * e.g. "neutral-faded" for `fill: var(--text-neutral-faded);`
   */
  closeIconColor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const SideDrawer = forwardRef<HTMLElement, SideDrawerProps>(
  (
    {
      children,
      drawerTitle,
      hideDrawer,
      isOpen,
      className,
      headerClass,
      contentClass,
      style,
      dataTestId = 'side-drawer',
      headerAlign = 'static',
      isNarrow = false,
      flexLayout,
      focusOnOpen,
      allowEventDefault = false,
      tabIndex,
      id,
      stickRight,
      closeIconColor,
      ...rest
    },
    ref
  ) => {
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    useKeyRelease('Escape', () => {
      isOpen && hideDrawer();
    });

    useEffect(() => {
      if (!closeButtonRef?.current || !focusOnOpen) return;
      else if (isOpen) closeButtonRef.current.focus();
      else closeButtonRef.current.blur();
    }, [focusOnOpen, isOpen]);

    return (
      <div {...rest}>
        <div
          className={cn(
            'duration-400 left-0 top-0 z-[200] h-full w-full transition ease-in',
            isOpen ? 'fixed bg-black/75' : 'relative bg-transparent'
          )}
          onClick={hideDrawer}
          data-testid="drawer-background"
        />
        <aside
          ref={ref}
          id={id}
          className={cn(
            'bg-background-page fixed left-0 top-0 z-[500] grid h-full w-full grid-rows-[auto_1fr] overflow-y-auto',
            'duration-400 transition ease-in',
            isOpen ? 'translate-x-0' : 'translate-x-full',
            stickRight ? 'left-[unset] right-0' : 'left-0 right-[unset]',
            flexLayout && 'flex flex-col flex-nowrap',
            isNarrow ? 'md:max-w-[390px]' : 'md:max-w-[480px]',
            'md:left-[initial] md:right-0',
            className
          )}
          tabIndex={tabIndex}
          data-testid={dataTestId}
          onClick={(e) => {
            if (!allowEventDefault) e.preventDefault();
          }}
          style={style}
        >
          <div
            className={cn(
              'bg-background-page flex items-center justify-between p-4 md:px-10',
              headerAlign === 'sticky' && 'sticky top-0 z-[500]',
              headerAlign === 'shadow-sticky' && 'sticky top-0 z-[500] shadow-md',
              isNarrow ? 'md:px-6' : 'md:px-10',
              headerClass
            )}
            data-testid="drawer-heading"
          >
            <div data-testid="drawer-title">{drawerTitle()}</div>
            <button
              className="flex items-center self-center p-1 text-base hover:cursor-pointer focus:outline-none"
              ref={closeButtonRef}
              onClick={hideDrawer}
              aria-label="close side-drawer"
              data-testid="drawer-close-icon"
            >
              <IconCloseModal
                title="close side-drawer"
                fill={closeIconColor ?? 'var(--text-neutral-faded)'}
                width="14px"
                height="14px"
              />
            </button>
          </div>
          <div
            className={cn('h-full px-6', isNarrow ? 'md:px-6' : 'md:px-10', contentClass)}
            data-testid="drawer-content"
          >
            {children}
          </div>
        </aside>
      </div>
    );
  }
);
SideDrawer.displayName = 'SideDrawer';
