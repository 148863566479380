import type { SvgElementProps } from '../../../types';

const IconCart = ({
  width = 20,
  height = 22,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M13.7493 9.5V5C13.7493 4.00544 13.3542 3.05161 12.6509 2.34835C11.9476 1.64509 10.9938 1.25 9.99925 1.25C9.00469 1.25 8.05086 1.64509 7.3476 2.34835C6.64434 3.05161 6.24925 4.00544 6.24925 5V9.5M17.6053 7.507L18.8683 19.507C18.9383 20.172 18.4183 20.75 17.7493 20.75H2.24925C2.09146 20.7502 1.9354 20.7171 1.7912 20.6531C1.647 20.589 1.5179 20.4953 1.41228 20.3781C1.30665 20.2608 1.22687 20.1227 1.17811 19.9726C1.12935 19.8226 1.1127 19.6639 1.12925 19.507L2.39325 7.507C2.42241 7.23056 2.55288 6.9747 2.7595 6.78876C2.96613 6.60281 3.23428 6.49995 3.51225 6.5H16.4863C17.0623 6.5 17.5453 6.935 17.6053 7.507ZM6.62425 9.5C6.62425 9.59946 6.58474 9.69484 6.51442 9.76517C6.44409 9.83549 6.34871 9.875 6.24925 9.875C6.1498 9.875 6.05441 9.83549 5.98409 9.76517C5.91376 9.69484 5.87425 9.59946 5.87425 9.5C5.87425 9.40054 5.91376 9.30516 5.98409 9.23483C6.05441 9.16451 6.1498 9.125 6.24925 9.125C6.34871 9.125 6.44409 9.16451 6.51442 9.23483C6.58474 9.30516 6.62425 9.40054 6.62425 9.5ZM14.1243 9.5C14.1243 9.59946 14.0847 9.69484 14.0144 9.76517C13.9441 9.83549 13.8487 9.875 13.7493 9.875C13.6498 9.875 13.5544 9.83549 13.4841 9.76517C13.4138 9.69484 13.3743 9.59946 13.3743 9.5C13.3743 9.40054 13.4138 9.30516 13.4841 9.23483C13.5544 9.16451 13.6498 9.125 13.7493 9.125C13.8487 9.125 13.9441 9.16451 14.0144 9.23483C14.0847 9.30516 14.1243 9.40054 14.1243 9.5Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCart;
