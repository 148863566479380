import { useEffect } from 'react';
/**
 * useKeyRelease
 * @param {string} targetKey - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export default function useKeyRelease(targetKey: string, action: () => void) {
  useEffect(() => {
    const onKeyup = ({ key }: KeyboardEvent) => {
      if (key === targetKey) action();
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [targetKey, action]);
}
