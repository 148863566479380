import { useCallback, useEffect, useRef } from 'react';

import { useInView } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { useGiftFinderContext } from '../context/GiftFinderContext';
import type { QuizStep } from '../types';

import { GiftFinderQuizComponent } from './GiftFinderQuizComponent';
import { BodyText } from '../../../typography/BodyText';

export const GiftFinderCard = ({
  componentProps,
  options,
  question,
  skip,
  step,
  subText,
  uuid,
}: QuizStep) => {
  const {
    state: { step: currentQuizStep, quiz, answers, skipScrollCallback },
    handleAnswer,
    handleNextStep,
    handleSetStep,
    handleSetSkipScrollCallback,
  } = useGiftFinderContext();

  const ref = useRef<HTMLDivElement>(null);

  // required to sync skipScrollCallback state to a ref since the intersection callback cannot actively get the actual current step
  const skipCallbackRef = useRef<boolean>(false);
  useEffect(() => {
    skipCallbackRef.current = skipScrollCallback;
  }, [skipScrollCallback]);

  // intersection callback activates the correct step indicator.
  // this should apply only on next step, on prev step, and on touch swipe.
  // events that also uses handleSetStep to jump to a particular step (e.g. step indicator click) should be skipped to avoid duplicate callback messing with the indicators.
  const intersectionCallback = useCallback(
    (isIntersecting: boolean) => {
      if (isIntersecting) {
        if (!skipCallbackRef?.current) {
          handleSetStep(step - 1);
        }
        handleSetSkipScrollCallback(false);
      }
    },
    [step]
  );

  const inView = useInView({
    elementRef: ref,
    options: { threshold: 0.9 },
    callbackFn: intersectionCallback,
  });

  const totalSteps = quiz.length;
  const active = step === currentQuizStep + 1;

  const currentStepAnswer = answers?.[uuid];
  const showSkip = !!skip && !currentStepAnswer;

  return (
    /* dynamic vertical height is required for iOS safari, deducted with computed height of the elements outside the card (heading, question, cta, etc) */
    <fieldset className="h-full max-h-[calc(100dvh_-_275px)] w-full snap-start p-0">
      <div
        ref={ref}
        className={cn(
          'bg-background-page grid h-full w-full -rotate-[5deg] grid-rows-[auto,auto,1fr,auto] overflow-hidden',
          'transition-all duration-300 ease-in-out',
          'shadow-[0px_0px_24px_-5px_rgba(0,0,0,0.1)]',
          'gap-4 rounded-lg p-6 pb-2 first:mt-0',
          (active || inView) && 'rotate-0',
          'md:px-4'
        )}
      >
        <BodyText
          className="text-brand font-semibold uppercase"
          leading="none"
          size="small"
          as="span"
        >
          Step {step} of {totalSteps}
        </BodyText>
        <BodyText className="text-neutral-strong md:mb-4" leading="snug" size="extraLarge">
          {question}
        </BodyText>
        <GiftFinderQuizComponent
          name={uuid}
          componentProps={componentProps}
          options={options}
          handleAnswer={handleAnswer}
        />
        {subText && (
          <BodyText
            className="text-neutral-faded p-0.5 text-center"
            leading="snug"
            size="small"
            as="span"
          >
            {subText}
          </BodyText>
        )}
        {showSkip && (
          <div className="text-center">
            <button
              className="text-neutral-faded px-2 text-sm leading-tight"
              onClick={handleNextStep}
              aria-label="skip this step"
            >
              Skip
            </button>
          </div>
        )}
      </div>
    </fieldset>
  );
};
