import { trackProductCard } from '../../../../utils';

import { TRACKING_IMAGE_CLICK_CLASS } from '.';

import type { CarouselCallbackProps } from '../EmblaCarousel';
import type { MouseEvent } from 'react';

export const handleProductCardImageChange = ({
  currentIndex: imgIndex,
  totalSlides,
  dir,
  gesture,
  trackingData,
}: CarouselCallbackProps & { trackingData?: Partial<ProductInteractionProps> }) => {
  const interaction = {
    swipe: {
      prev: ProductCardInteraction.SwipePrev,
      next: ProductCardInteraction.SwipeNext,
    },
    click: {
      prev: ProductCardInteraction.ClickPrev,
      next: ProductCardInteraction.ClickNext,
    },
  };
  trackProductCard({
    ...(trackingData || {}),
    imageInteracted: `${imgIndex + 1} of ${totalSlides}`,
    interaction: interaction?.[gesture]?.[dir],
  } as ProductInteractionProps);
};

export const trackProductCardV2Click = (
  e: MouseEvent<HTMLElement>,
  { trackingData }: { trackingData?: Partial<ProductInteractionProps> }
) => {
  const currentTarget = e.currentTarget as HTMLElement;
  const target = e.target as HTMLElement;

  trackProductCard({
    ...(trackingData || {}),
    // e.currentTarget gets the actual anchor element that contains data-activeimage
    imageInteracted: currentTarget?.dataset?.activeimage,

    // e.target gets the specific element where it clicked, and looks for the parent element of the image carousel if the pointer is inside it
    interaction: target.closest(`.${TRACKING_IMAGE_CLICK_CLASS}`)
      ? ProductCardInteraction.ClickImage
      : ProductCardInteraction.ClickCard,
  } as ProductInteractionProps);
};

export enum ProductCardInteraction {
  SwipeNext = 'Swipe to next',
  SwipePrev = 'Swipe to previous',
  ClickNext = 'Click next',
  ClickPrev = 'Click previous',
  ClickImage = 'Clicked on image',
  ClickCard = 'Clicked on surrounding card',
}

export type ProductInteractionProps = {
  cardTitle: string; // title
  cardFullSku: string; // sku + promocode
  pastPrice: number; // rrp
  currentPrice: number; // displayPrice
  percentOff: number;
  reviews: { averageRating: number };

  // Home / Category Listing / Product/ Custom Site / Blog Home
  // can be overwritten from GA Config
  pageType?: string;
  // slice name
  module: string;
  // position in the list
  position: number;
  // current promo pill text
  productPromo: { config: { text: string } } | null;
  // location text override
  location: string;
  // position of the currently active image when interacted (e.g. 1 of 4)
  imageInteracted: string;
  interaction: ProductCardInteraction;
};
