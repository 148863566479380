import type { SvgElementProps } from '../../../types';

const IconUser = ({
  width = 20,
  height = 20,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4 3.6C9.76348 3.6 9.15303 3.85286 8.70294 4.30294C8.25286 4.75303 8 5.36348 8 6C8 6.63652 8.25286 7.24697 8.70294 7.69706C9.15303 8.14714 9.76348 8.4 10.4 8.4C11.0365 8.4 11.647 8.14714 12.0971 7.69706C12.5471 7.24697 12.8 6.63652 12.8 6C12.8 5.36348 12.5471 4.75303 12.0971 4.30294C11.647 3.85286 11.0365 3.6 10.4 3.6ZM7.57157 3.17157C8.32172 2.42143 9.33913 2 10.4 2C11.4609 2 12.4783 2.42143 13.2284 3.17157C13.9786 3.92172 14.4 4.93913 14.4 6C14.4 7.06087 13.9786 8.07828 13.2284 8.82843C12.4783 9.57857 11.4609 10 10.4 10C9.33913 10 8.32172 9.57857 7.57157 8.82843C6.82143 8.07828 6.4 7.06087 6.4 6C6.4 4.93913 6.82143 3.92172 7.57157 3.17157ZM5.87452 12.6745C7.07475 11.4743 8.70261 10.8 10.4 10.8C12.0974 10.8 13.7252 11.4743 14.9255 12.6745C16.1257 13.8748 16.8 15.5026 16.8 17.2C16.8 17.6418 16.4418 18 16 18H4.8C4.58783 18 4.38434 17.9157 4.23431 17.7657C4.08429 17.6157 4 17.4122 4 17.2C4 15.5026 4.67428 13.8748 5.87452 12.6745ZM10.4 12.4C9.12696 12.4 7.90606 12.9057 7.00589 13.8059C6.29653 14.5152 5.83213 15.4238 5.66712 16.4H15.1329C14.9679 15.4238 14.5035 14.5152 13.7941 13.8059C12.8939 12.9057 11.673 12.4 10.4 12.4Z"
      fill={themeColor || fill}
    />
  </svg>
);

export default IconUser;
