import type { SvgElementProps } from '../../../types';

const IconSailboatSmall = ({
  width = 17,
  height = 17,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2.21744 14.089C2.1551 13.9254 2.27595 13.75 2.45106 13.75H15.5489C15.724 13.75 15.8449 13.9254 15.7826 14.089L15.0207 16.089C14.9837 16.1859 14.8908 16.25 14.787 16.25H3.21296C3.10923 16.25 3.01627 16.1859 2.97934 16.089L2.21744 14.089Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
    />
    <path
      d="M8.82861 10.3333H16.0879L8.82861 1.57141"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83057 10.3333H1.57131L8.71422 4.57141"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.57227 1C9.57227 0.585786 9.23648 0.25 8.82227 0.25C8.40805 0.25 8.07227 0.585786 8.07227 1L9.57227 1ZM8.07227 1L8.07227 13.5714L9.57227 13.5714L9.57227 1L8.07227 1Z"
      fill={themeColor || fill}
    />
  </svg>
);

export default IconSailboatSmall;
