import { HTMLAttributes, ReactNode } from 'react';

import SaleBadge from '../../../../components/common/SaleBadge';
import { useHeaderContext } from '../../../../context/HeaderContext';
import { useAspectRatioSupported } from '../../../../hooks/useAspectRatioSupported';
import { cn, enabledPromotions } from '../../../../utils';
import { formatLocations } from '../../../../utils/formatLocation';
import { getCardBadgeText } from '../../../../utils/getCardBadgeText';
import {
  type AspectRatio,
  mapAspectRatio,
  mapAspectRatioPolyfill,
} from '../../../../utils/mapAspectRatio';
import { getProductCardPrices } from '../../../../utils/productPricing';
import {
  BlackFridayBadge,
  ChristmasBadge,
  CyberMondayBadge,
  ValentinesBadge,
} from '../../icons/PromotionBadge';
import Link from '../../Link';
import Image from '../../ui/Image';

import { RatingsAndReviews } from './ProductRatingAndReviews';

import type { ProductType } from '../../../../types';

export type ProductCardProps = {
  aspectRatio?: AspectRatio;
  isMinimal?: boolean;
  hasZoomOnHover?: boolean;
} & ProductType &
  HTMLAttributes<HTMLElement>;

export const ProductCard = ({
  className,
  cardTitle,
  cardRoute,
  locations,
  cardImage,
  currentPrice: displayPrice,
  pastPrice: rrp,
  percentOff,
  isNew = false,
  isExclusive = false,
  aspectRatio = '1/1',
  hasZoomOnHover = false,
  badge,
  reviews,
  isBlackFridayProduct,
  isChristmasProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
}: ProductCardProps) => {
  const { isSupported } = useAspectRatioSupported();
  const hasRating = reviews && reviews?.averageRating > 0 && reviews?.count > 0;
  const badgeText = getCardBadgeText({ isNew, isExclusive });
  const { NextImage, blackFridayBadge, cyberMondayBadge, christmasBadge, valentinesDayBadge } =
    useHeaderContext();
  const {
    isBlackFriday,
    isCyberMonday,
    isChristmas,
    isEaster,
    isMothersDay,
    isFathersDay,
    isValentines,
  } = enabledPromotions({
    isBlackFridayProduct,
    isChristmasProduct,
    isEasterProduct,
    isMothersDayProduct,
    isFathersDayProduct,
    isValentinesProduct,
  });

  let promoBadgeText = null;

  if (isFathersDay) promoBadgeText = 'DAD APPROVED';
  else if (isMothersDay) promoBadgeText = `MOTHER'S DAY SPECIAL`;
  else if (isEaster) promoBadgeText = 'EASTER GIFTING';

  // Priority Order: Christmas, Cyber Monday, Black Friday
  let PromoBadge = null;
  switch (true) {
    case isValentines:
      PromoBadge = ValentinesBadge;
      break;
    case isChristmas:
      PromoBadge = ChristmasBadge;
      break;
    case isCyberMonday:
      PromoBadge = CyberMondayBadge;
      break;
    case isBlackFriday:
      PromoBadge = BlackFridayBadge;
      break;
  }

  const { currentPrice, pastPrice, roundedPercentOff } = getProductCardPrices({
    displayPrice,
    rrp,
    percentOff,
  });

  return (
    <Link
      className={cn('group flex h-full flex-col gap-1 overflow-hidden no-underline', className)}
      href={cardRoute}
    >
      <div className="relative">
        <div
          className={cn(
            'relative h-auto w-full overflow-hidden bg-background-neutral-faded ease-in-out image:object-cover',
            hasZoomOnHover &&
              'duration-400 transition-transform will-change-transform group-hover:scale-105',
            isSupported && mapAspectRatio(aspectRatio),
            !isSupported && mapAspectRatioPolyfill(aspectRatio)
          )}
        >
          <Image fill className="h-full w-full" src={cardImage} alt={cardTitle} objectFit="cover" />
        </div>

        {promoBadgeText ? (
          <PromotionTag isOverlay>{promoBadgeText}</PromotionTag>
        ) : (
          <>
            {badgeText && <span className={style.badge._}>{badgeText}</span>}
            {badge && (
              <SaleBadge className={cn(style.badge._, style.badge.sale)}>{badge}</SaleBadge>
            )}
            {!!PromoBadge && (
              <PromoBadge
                NextImage={NextImage}
                envVariables={{
                  blackFridayBadge,
                  cyberMondayBadge,
                  christmasBadge,
                  valentinesDayBadge,
                }}
                className={cn(style.promoBadge._, {
                  [style.promoBadge.isOnEdgeRight]: !!isChristmas,
                })}
              />
            )}
          </>
        )}
      </div>

      <div className="flex h-full flex-col justify-start gap-2 px-2 pt-2 text-base leading-base transition-colors">
        <h3 className="m-0 line-clamp-2 font-inter text-sm font-medium leading-normal text-neutral-strong group-hover:text-link">
          {cardTitle}
        </h3>

        {locations && (
          <p className="ellipsis m-0 block w-full truncate text-sm font-[500] leading-base text-neutral-faded">
            {formatLocations(locations)}
          </p>
        )}

        {hasRating && (
          <RatingsAndReviews averageRating={reviews.averageRating} count={reviews.count} />
        )}
      </div>
      <div className="flex flex-wrap items-end gap-2 px-2 pb-2 pt-4">
        <span className={'text-base font-semibold leading-none text-neutral-strong'}>
          {currentPrice}
        </span>
        {pastPrice && (
          <span className="text-sm leading-none text-grey-600 line-through">{pastPrice}</span>
        )}
        {pastPrice && !!roundedPercentOff && (
          <span className="text-xs font-semibold leading-base text-tones-positive-700">
            You save {roundedPercentOff}%
          </span>
        )}
      </div>
    </Link>
  );
};

const PromotionTag = ({ children }: { children: ReactNode; isOverlay?: boolean }) => (
  <p
    className={cn(
      'absolute left-1 top-1 m-0 my-0 ml-0 mr-1 rounded bg-background-neutral-strong px-1 py-0.5 text-xs font-semibold uppercase leading-base text-background-page',
      // ! NOTE : Change according to active promo theme color
      style.promoTag.fathersDay
    )}
  >
    {children}
  </p>
);

const style = {
  promoBadge: {
    _: 'absolute bottom-2 w-1/2 max-w-[100px] right-2',
    isOnEdgeRight: 'right-0',
  },
  promoTag: {
    generic: 'bg-primary-100 text-grey-700', // mothers day, easter
    blackFriday: 'bg-black text-white',
    fathersDay: 'bg-background-page text-neutral border border-solid border-border-neutral',
  },
  badge: {
    _: 'text-xs absolute left-2 top-2 self-start rounded border border-solid border-border-neutral bg-background-page px-1 py-0.5 font-semibold leading-none text-neutral uppercase',
    sale: 'top-auto bottom-0 left-0 rounded-none border-transparent bg-primary-500 leading-none text-white',
  },
};
