import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} targetKey - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {boolean} listen - whether to listen for keydown or keyup events
 */
export const useKeyPress = (targetKey: string, action: () => void) => {
  useEffect(() => {
    const onKeyDown = ({ key }: KeyboardEvent) => {
      if (key === targetKey) action();
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [targetKey, action]);
};
