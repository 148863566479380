import type { SvgElementProps } from '../../../types';

const IconLightning = ({
  width = 16,
  height = 16,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.66602 6.66667V2L2.66602 9.33333H7.33268V14L13.3327 6.66667H8.66602Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconLightning;
