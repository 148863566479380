import type { GiftFinderState } from '../types';
import { config } from './config';

export const giftFinderInitialState: GiftFinderState = {
  active: false,
  title: config.title,
  quiz: config.quiz,
  answers: {},
  errors: {},
  submitError: null,
  step: 0,
  skipScrollCallback: false,
  resultsCookieName: '_ved_gf_results',
  trackSwipable: true,
};
