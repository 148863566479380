import { useTheme } from '../../context/ThemeContext';
import { AriaLabel } from '../../enums';
import { HeaderProps } from '../../types';
import { basketToDataLayer, cn } from '../../utils';
import RenderIcon from '../common/icons/RenderIcon';
import IconButton from '../common/ui/buttons/IconButton';

import type { HTMLAttributes } from 'react';

type Props = {
  links: HeaderProps['navigation']['links'];
  basketCount: number;
  hideBasketCount?: boolean;
  saveForLaterCount: number;
} & HTMLAttributes<HTMLDivElement>;

const HeaderButtonGroup = ({
  links,
  basketCount,
  hideBasketCount,
  saveForLaterCount,
  className,
  ...props
}: Props) => {
  const { immersive } = useTheme();

  const iconProps = {
    width: 18,
    height: 18,
    fill: immersive ? 'var(--persistent-text-light)' : 'var(--text-neutral)',
  } as const;

  return (
    <div {...props} className={cn(style.container, className)}>
      {!!links?.saveForLater && (
        <IconButton
          aria-label={AriaLabel.LinkToSaved}
          count={saveForLaterCount}
          href={links.saveForLater}
          className={cn({ [style.button.immersive]: immersive }, { [style.icon.heart]: immersive })}
        >
          <RenderIcon name="heart" {...iconProps} className="heart-icon" />
        </IconButton>
      )}
      {!!links?.basket && (
        <IconButton
          aria-label={AriaLabel.LinkToBasket}
          onClick={() => basketToDataLayer()}
          // enforce 0 count to not display item count when hideBasketCount is true (e.g. C# basket page)
          count={hideBasketCount ? 0 : basketCount}
          href={links.basket}
          className={cn(
            { [style.button.immersive]: immersive },
            { [style.icon.basket]: immersive }
          )}
        >
          <RenderIcon name="cart" {...iconProps} className="basket-icon" />
        </IconButton>
      )}
    </div>
  );
};

export default HeaderButtonGroup;

const style = {
  container: 'flex justify-end pr-4 lg:pr-0',
  button: {
    immersive: 'lg:hover:bg-persistent-background-primary-faded',
  },
  icon: {
    basket: '[&>.basket-icon>path]:hover:stroke-persistent-text-dark',
    heart: '[&>.heart-icon>path]:hover:stroke-persistent-text-dark',
  },
};
