import { formatTopProductsToCardsArray } from './formatTopProductsToCardsArray';

import type { ProductMapType, TopProductsStructure } from './types';

type OptionsType = {
  topProductsOverwrite: Partial<TopProductsStructure>;
};

const defaultOptions: OptionsType = {
  topProductsOverwrite: {},
};

/**
 *
 * This function formats the given top products map into a structured object, that can be used in the footer carousel.
 * The resulting object includes a title, show all link, and an array of cards representing the top products.
 *
 * @param {object} topProductsMap - The top products map to format
 * @param {string} baseUrl - The base URL of the website
 * @param {object} options - Additional options for formatting the top products
 * @param {object} options.topProductsOverwrite - An object containing properties to overwrite in the resulting top products object
 * @returns {object} - A structured object representing the top products, including a title, show all link, and an array of cards
 */
export const formatFooterTopProducts = (
  topProductsMap: ProductMapType,
  baseUrl: string,
  options: Partial<OptionsType> = defaultOptions
): TopProductsStructure => {
  const { topProductsOverwrite } = { ...defaultOptions, ...options };
  const isValidMap =
    !!topProductsMap && typeof topProductsMap === 'object' && !Array.isArray(topProductsMap);

  if (!isValidMap) {
    console.warn(`'topProductsMap.cards' is invalid and being returned as an empty array`);
  }

  return {
    title: 'Top Experiences',
    showCta: true,
    ctaLink: `${baseUrl}/search`,
    ctaLabel: 'Show All',
    column: 'left',
    cards: isValidMap ? formatTopProductsToCardsArray(topProductsMap) : [],
    ...topProductsOverwrite,
  };
};
