import { type HTMLAttributes, type MouseEvent, useState } from 'react';

import { useHeaderContext } from '../../../../context/HeaderContext';
import { cn } from '../../../../utils';
import { formatLocationTextOverride } from '../../../../utils/productLocation';
import { getProductCardPrices } from '../../../../utils/productPricing';
import { STAR_RATING_MIN_DISPLAY, roundAverageRating } from '../../../../utils/productRating';
import IconStarFull from '../../icons/IconStar';
import Link from '../../Link';
import { DisplayHeading, type DisplayHeadingProps } from '../DisplayHeading';
import { EmblaCarousel, type EmblaCarouselProps } from '../EmblaCarousel';

import type { ProductPromo } from '../../../../promos';

export const TRACKING_IMAGE_CLICK_CLASS = 'track-target-image';

export type ProductCardV2Props = {
  averageRating?: number;
  displayPrice: number;
  rrp?: number;
  percentOff?: number;
  isCollectionProduct?: boolean;
  title: string;
  titleAs?: DisplayHeadingProps['as'];
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  priority?: boolean;

  className?: string;
  titleClassName?: string;
  images: { src: string; alt?: string }[];
  /**
   * Product categories that determine the location text override if product does not have location
   */
  categories?: string[];
  /**
   * Flag whether to render image carousel (if has multiple images) or single image
   */
  isImageCarousel?: boolean;
  onImageCarouselSwipe?: EmblaCarouselProps['onSwipe'];
  onImageCarouselNavClick?: EmblaCarouselProps['onNavClick'];
  productPromo: ProductPromo | null;
  /**
   * Header2024: Full URL of the card instead of calculating from slug and isCollectionProduct
   */
  productUrl?: string;
  /**
   * Header2024: promocode + sku
   */
  fullSku?: string;
  /**
   * Header2024: locations in text making compatible with V1 split
   */
  locations?: string;
};

enum TestId {
  link = 'product-card',
  image = 'product-card-image',
  title = 'product-card-title',
  location = 'product-card-location-container',
  rating = 'star-rating',
  priceContainer = 'product-card-price-container',
  priceCurrent = 'product-card-current-price',
  pricePast = 'product-card-past-price',
  priceSavings = 'product-card-savings-price',
}

export const useIsProductCardsV2 = () => {
  const { isProductCardsV2 } = useHeaderContext();

  return isProductCardsV2 === '1';
};

export const ProductCardV2 = ({
  className,
  titleClassName,
  averageRating,
  displayPrice,
  rrp,
  percentOff,
  isCollectionProduct = false,
  locations,
  productUrl,
  fullSku,
  title,
  titleAs = 'h2',
  images,
  categories,
  priority,
  productPromo,
  isImageCarousel = false,
  onClick,
  onImageCarouselSwipe,
  onImageCarouselNavClick,
}: ProductCardV2Props) => {
  const [selectedImage, setSelectedImage] = useState(0);

  return (
    <Link
      className={className}
      href={productUrl}
      onClick={onClick}
      data-testid={TestId.link}
      // for tracking
      data-activeimage={`${selectedImage + 1} of ${isImageCarousel ? images?.length : 1}`}
    >
      <div className="grid h-full w-full grid-rows-[auto,1fr]">
        <div
          className={cn(
            TRACKING_IMAGE_CLICK_CLASS,
            'relative aspect-square w-full overflow-hidden rounded-md bg-gray-300'
          )}
        >
          <EmblaCarousel
            className="h-full w-full"
            slides={images}
            options={{ loop: true, active: isImageCarousel }}
            priority={priority}
            imageTestId={TestId.image}
            onSwipe={onImageCarouselSwipe}
            onNavClick={onImageCarouselNavClick}
            onSelectImage={(index: number) => setSelectedImage(index)}
          />

          <ProductBadge
            className="pointer-events-none absolute left-2 top-2 select-none"
            productPromo={productPromo}
          />
        </div>

        <div className="flex flex-col gap-0.5 py-2" data-productid={fullSku}>
          <div className="flex items-center justify-between gap-2">
            <ProductLocation locations={locations} categories={categories} />
            {!isCollectionProduct && <ProductRating rating={averageRating} />}
          </div>

          <DisplayHeading
            as={titleAs}
            size="5"
            leading="normal"
            className={cn(styles.title, titleClassName)}
            title={title}
            data-testid={TestId.title}
          >
            {title}
          </DisplayHeading>

          <ProductCardPrice displayPrice={displayPrice} rrp={rrp} percentOff={percentOff} />
        </div>
      </div>
    </Link>
  );
};

type ProductBadgeProps = {
  productPromo: ProductPromo | null;
} & HTMLAttributes<HTMLSpanElement>;

export const ProductBadge = ({ productPromo, className }: ProductBadgeProps) => {
  if (!productPromo?.config?.text) return null;

  const { text, styles: promoStyles } = productPromo.config;
  return <span className={cn(styles.badge.default, promoStyles, className)}>{text}</span>;
};

const ProductRating = ({ rating }: { rating: ProductCardV2Props['averageRating'] }) => {
  if (typeof rating !== 'number' || rating < STAR_RATING_MIN_DISPLAY) return null;

  return (
    <div
      className="flex items-center gap-1 text-xs leading-normal text-neutral-faded"
      data-testid={TestId.rating}
      data-rating={rating}
    >
      <IconStarFull shadedColor="var(--text-neutral-strong)" className="-mt-0.5 h-3 w-3" />
      <span>{roundAverageRating(rating)}</span>
    </div>
  );
};

const ProductCardPrice = ({
  displayPrice,
  rrp,
  percentOff,
}: Pick<ProductCardV2Props, 'displayPrice' | 'rrp' | 'percentOff'>) => {
  const { currentPrice, pastPrice, roundedPercentOff } = getProductCardPrices({
    displayPrice,
    rrp,
    percentOff,
  });

  return (
    <div
      className="mt-2 flex flex-wrap items-start gap-1 lg:items-center"
      data-testid={TestId.priceContainer}
    >
      <span className={styles.price.current} data-testid={TestId.priceCurrent}>
        {currentPrice}
      </span>

      {pastPrice && (
        <span className={styles.price.past} data-testid={TestId.pricePast}>
          {pastPrice}
        </span>
      )}

      {pastPrice && !!roundedPercentOff && (
        <span
          className={cn(styles.badge.default, styles.badge.sale)}
          data-testid={TestId.priceSavings}
        >
          Save {roundedPercentOff}%
        </span>
      )}
    </div>
  );
};

/**
 * Priority Use Cases
 *
 * 1. If user uses the location radius search, then the distance to experience should populate as an exact number (No decimal places) on the product card.
 *
 * 2. If only one location, display the Location name
 *
 * 3, If more than one location, then the number of locations should display on the card
 *
 * 4. In some cases, products don’t currently have a location set, but we would want custom wording for them.
 *   if product contains multiple of these custom categories (e.g. at-home-wine-tasting-and-taster-british-cheese-box-for-two) it will follow according to these order:
 *     a. "At Home Experiences" - Any products that are part of the At home category (stay-at-home-experiences)
 *     b. "Multi Choice Collections" - All multi choice vouchers (collection-vouchers)
 *     c. "Gift Card" - All Gift card products (gift-cards)
 *     d. "Physical gifts" - All tangible products (physical-gifts)
 *
 *   We can do this by simply creating a location in admin for the following product types and applying them to the products
 *     - Is summary location check box should be ticked + lat long should be the same as balloons experiences
 *     - Need to ensure that this will not cause issues with the Algolia location search
 *
 * 5. If no location exists then we should populate a replacement wording of ‘Experience’
 */
const ProductLocation = ({
  locations,
  categories,
}: Pick<ProductCardV2Props, 'locations' | 'categories'>) => {
  const formattedLocation = getLocationText({ locations, categories });

  // no need for nearestLocation in Header/Footer

  return (
    <p className={styles.location} data-testid={TestId.location}>
      {formattedLocation}
    </p>
  );
};

export const getLocationText = ({
  locations,
  categories,
}: Pick<ProductCardV2Props, 'locations' | 'categories'>) =>
  locations ?? formatLocationTextOverride(categories || []);

export const styles = {
  badge: {
    default:
      'py-0.5 px-1 rounded bg-white text-black text-xs leading-4 uppercase tracking-wide font-semibold',
    sale: 'bg-background-neutral-strong text-background-page',
  },
  location:
    'text-neutral-faded line-clamp-1 overflow-wrap-anywhere text-xs uppercase leading-normal break-all',
  title: 'mb-auto line-clamp-2 text-sm leading-normal text-neutral-strong font-[500]',
  description: 'text-sm leading-normal text-neutral-faded-high-contrast line-clamp-3',
  price: {
    current:
      'text-neutral-strong text-sm font-semibold leading-normal lg:text-lg lg:font-bold lg:leading-normal',
    past: 'text-neutral-faded text-sm font-[500] leading-normal line-through',
  },
};
