import type { SVGProps } from 'react';

const RoundedSquareSvg = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 759 520"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      d="M78.234 530.696C-50.94 256.765-33.048 206.943 240.884 77.77 514.815-51.404 564.637-33.512 693.81 240.419c129.174 273.932 111.282 323.754-162.649 452.927-273.932 129.173-323.754 111.282-452.927-162.65Z"
    />
  </svg>
);

export default RoundedSquareSvg;
