import { RefObject } from 'react';

import { cn } from '../../../../utils';
import Link from '../../Link';
import { BodyText } from '../../ui/BodyText';
import { DisplayHeading } from '../DisplayHeading';

import { CTAButton } from './CTAButton';
import { CarouselNavs } from './Navs';

export type CarouselHeadingProps = {
  className?: string;
  dataTestId?: string;
  /**
   * Title displayed on the opposite site of the controls
   */
  title?: string;
  /**
   * Title size corresponding to sizes of `DisplayHeading`
   *
   * NOTE: This changes the heading markup, not the visual size
   */
  titleSize?: '1' | '2' | '3' | '4' | '5' | '6' | null;
  /**
   * Description shown below title
   */
  description?: string;
  /**
   * Link for the CTA
   * When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle */
  href?: string;
  /**
   * If true, forces the CTA link to be an anchor tag to an external link, not
   * subject to Next.js client-side routing.
   */
  isHrefExternal?: boolean;
  /**
   * Title for cta
   */
  ctaTitle?: string;
  /**
   * Hide CTA on Mobile
   */
  ctaHiddenOnMobile?: boolean;
  /**
   * Debounce time to prevent spamming, recommended is 350(default)
   */
  debounceTime?: number;
  /** When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle*/
  onClick?: () => void;
  showNavigationArrows?: boolean;
  carouselRef?: RefObject<HTMLElement>;
  lastChildRef?: RefObject<HTMLElement>;
  firstChildRef?: RefObject<HTMLElement>;
  /**
   * active ref index for multiple carousels (e.g. CarouselWithTabs)
   */
  activeRefIndex?: number;
};

export const CarouselHeading = ({
  className,
  title,
  titleSize,
  description,
  href,
  isHrefExternal,
  ctaTitle,
  ctaHiddenOnMobile = false,
  onClick,
  showNavigationArrows,
  carouselRef,
  lastChildRef,
  firstChildRef,
  activeRefIndex = 0,
  dataTestId = 'new-carousel',
  debounceTime,
}: CarouselHeadingProps) => (
  <div className={cn('mb-4', className)} data-testid={dataTestId}>
    <div
      className="flex w-full items-baseline justify-between md:items-center"
      data-testid="carousel-heading"
    >
      {title && (
        <DisplayHeading
          className="text-xl leading-tight text-neutral-strong"
          data-testid="carousel-title"
          size={titleSize ?? '3'}
        >
          {title}
        </DisplayHeading>
      )}
      <ul className="m-0 list-none p-0 md:flex md:flex-1 md:items-center md:justify-end md:gap-1">
        {ctaTitle && (
          <div className={cn(!!title && !ctaHiddenOnMobile ? 'flex' : 'hidden', 'md:flex')}>
            {href ? (
              <li>
                <Link
                  className={cn(
                    'whitespacing-no-wrap ml-2 text-sm font-semibold leading-none text-link no-underline',
                    !showNavigationArrows ? 'md:mr-0' : 'md:mr-2',
                    'md:ml-0'
                  )}
                  href={href}
                  isExternal={isHrefExternal}
                  target="_self"
                >
                  {ctaTitle}
                </Link>
              </li>
            ) : (
              <CTAButton onClick={onClick} ctaTitle={ctaTitle} />
            )}
          </div>
        )}
        {showNavigationArrows && (
          <div className="hidden md:flex md:gap-1">
            <CarouselNavs
              carouselRef={carouselRef}
              lastChildRef={lastChildRef}
              firstChildRef={firstChildRef}
              debounceTime={debounceTime}
              index={activeRefIndex}
            />
          </div>
        )}
      </ul>
    </div>
    {description && <BodyText className="mt-2 text-neutral">{description}</BodyText>}
  </div>
);
