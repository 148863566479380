import { Fragment, type HTMLAttributes } from 'react';

import { AriaExpanded } from '../../../enums';
import { cn } from '../../../utils';
import Divider from '../../common/ui/Divider';

import NestedMenuItem from './Menu/NestedMenuItem';

import type { NavigationItem } from '../../../types';

type Props = {
  /**
   * The lists of items to render and whether the list is an overflow list
   */
  lists: [NavigationItem[], boolean][];
  /**
   * Whether the dropdown is expanded/open
   */
  expanded: boolean;
} & HTMLAttributes<HTMLDivElement>;

// configurable breakpoints for when to move items to overflow
export const maxItemsBeforeOverflow: Record<string, number> = {
  lg: 6,
  xl: 8,
  '2xl': 8,
};

const DesktopOverflow = ({ lists, expanded, className, ...props }: Props) => (
  <div
    {...props}
    className={cn('flex flex-col', className)}
    aria-expanded={AriaExpanded[expanded ? 'True' : 'False']}
  >
    {lists.map(([list, overflow], i: number, listsArr) => {
      const firstArrayItem = i === 0;
      const lastArrayItem = i === listsArr.length - 1;

      if (!Array.isArray(list)) return null;
      return (
        <Fragment key={i}>
          <ul
            className={cn(
              'flex w-full flex-col items-start',
              'before:absolute before:right-full before:top-0 before:h-full before:w-8 before:bg-transparent',
              'after:absolute after:left-full after:top-0 after:h-full after:w-8 after:bg-transparent'
            )}
          >
            {list.map((item: NavigationItem, j: number) => {
              const { desktop } = item || {};
              const itemName = item.nameSEO ?? item.name;
              return (
                <li
                  key={item.name}
                  className={cn(
                    'block',
                    overflow && {
                      'lg:hidden': j < maxItemsBeforeOverflow.lg,
                      'xl:hidden': j < maxItemsBeforeOverflow.xl,
                      '2xl:hidden': j < maxItemsBeforeOverflow['2xl'],
                    }
                  )}
                >
                  <NestedMenuItem
                    name={itemName}
                    route={item.route}
                    parentNameList={['More', itemName]}
                    icon={item.icon}
                    inOverflowMenu
                    desktop={desktop}
                    className="font-normal"
                  />
                </li>
              );
            })}
          </ul>
          {!lastArrayItem && (
            <Divider
              className={cn('w-full border-border-neutral', {
                'lg:hidden': firstArrayItem && list.length <= maxItemsBeforeOverflow.lg,
                'xl:hidden': firstArrayItem && list.length <= maxItemsBeforeOverflow.xl,
                '2xl:hidden': firstArrayItem && list.length <= maxItemsBeforeOverflow['2xl'],
              })}
            />
          )}
        </Fragment>
      );
    })}
  </div>
);

export default DesktopOverflow;
