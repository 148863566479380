type TagTypes = 'script' | 'iframe';
type EnvType = 'production' | 'development';

interface IGtm {
  initialize: () => void;
}

enum ElementId {
  noScript = 'GTM_iframe',
  script = 'GTM_script',
}

const Snippets = (gtmId: string): Record<EnvType, Record<TagTypes, string>> => ({
  production: {
    script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=7cQ4Acc6U-EtVyExIh2Bqg&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`,
    iframe: `https://www.googletagmanager.com/ns.html?id=${gtmId}&gtm_auth=7cQ4Acc6U-EtVyExIh2Bqg&gtm_preview=env-2&gtm_cookies_win=x`,
  },
  development: {
    script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=G0ctZAjS1V8oIWULp_ziag&gtm_preview=env-7&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`,
    iframe: `https://www.googletagmanager.com/ns.html?id=${gtmId}&gtm_auth=G0ctZAjS1V8oIWULp_ziag&gtm_preview=env-7&gtm_cookies_win=x`,
  },
});

/**
 * GTM class implements the IGtm interface and provides functionality to interact with Google Tag Manager.
 */
export class GTM implements IGtm {
  private isProduction = false;
  private gtmId: string = '';
  private environment: EnvType = 'development';

  /**
   * Creates an instance of GTM.
   * @param isProduction - Indicates whether the environment is production or development.
   * @param gtmId - The Google Tag Manager ID.
   */
  constructor(gtmId: string, isProduction: boolean) {
    this.gtmId = gtmId;
    this.isProduction = isProduction;
    this.environment = this.isProduction ? 'production' : 'development';
  }

  /**
   * Generates the script element for Google Tag Manager.
   * @returns The generated script element.
   */
  private script(): HTMLScriptElement {
    const script = document.createElement('script');
    script.setAttribute('data-nscript', 'afterInteractive');
    script.id = ElementId.script;
    script.innerHTML = Snippets(this.gtmId)?.[this.environment]?.script ?? '';

    return script;
  }

  /**
   * Generates the noscript element for Google Tag Manager.
   * @returns The generated noscript element.
   */
  private iframe(): HTMLElement {
    const noscript = document.createElement('noscript');

    noscript.id = ElementId.noScript;
    noscript.innerHTML = Snippets(this.gtmId)?.[this.environment]?.iframe ?? '';

    return noscript;
  }

  /**
   * Checks if there are duplicate elements in the DOM matching the given query selector.
   * @param querySelector - The query selector for the elements to check for duplicates.
   * @returns True if duplicate elements are found, false otherwise.
   */
  private getDuplicateElements(querySelector: string): boolean {
    const duplicates = document.querySelectorAll(querySelector);
    return !!duplicates.length;
  }

  /**
   * Injects the Google Tag Manager script into the DOM.
   */
  private injectScript() {
    const script = this.script();
    document.head.prepend(script);
  }

  /**
   * Injects the Google Tag Manager iframe into the DOM.
   */
  private injectIframe() {
    const iframe = this.iframe();
    document.body.prepend(iframe);
  }

  /**
   * Initializes Google Tag Manager by injecting the script and iframe into the DOM.
   */
  initialize() {
    if (typeof document === 'undefined' || !this.gtmId) return;

    // inject script
    const duplicateScript = this.getDuplicateElements(`#${ElementId.script}`);
    if (!duplicateScript) this.injectScript();

    // inject iframe
    const duplicateNoScript = this.getDuplicateElements(`#${ElementId.noScript}`);
    if (!duplicateNoScript) this.injectIframe();
  }
}

export default GTM;
