type BaseTrackingData = {
  event: string;
};

type GenericEventData = BaseTrackingData & {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
};

type GAEmailSignupData = BaseTrackingData & {
  emailHash: string;
};

type TrackingData = GenericEventData | GAEmailSignupData;

const addToDataLayer = (obj: TrackingData) => {
  if (!obj || !global?.dataLayer) return;

  global.dataLayer = global.dataLayer || [];
  global.dataLayer.push(obj);
};

export { type TrackingData, addToDataLayer };
