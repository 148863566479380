import type { IconProps as Props } from './types';

export const IconSquircle = ({ className, fill = 'currentColor', ...rest }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 195 195"
    fill={fill}
    {...rest}
  >
    <path d="M10.8661 76.8892C30.7656 -6.52444 35.0703 -9.17086 118.484 10.7286C201.898 30.628 204.544 34.9327 184.645 118.346C164.745 201.76 160.44 204.406 77.0268 184.507C-6.38689 164.608 -9.03331 160.303 10.8661 76.8892Z" />
  </svg>
);
