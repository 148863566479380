import { CSSProperties } from 'react';

import { ProductType } from '../../../types';
import { cn } from '../../../utils';
import {
  trackEndCardOnReachedToDataLayer,
  trackEndCardToDataLayer,
} from '../../../utils/addToDataLayer';

import { Carousel, ResponsivePerPage } from './Carousel';
import CarouselEndCard from './Carousel/CarouselEndCard';
import { useIsProductCardsV2 } from './ProductV2';
import {
  type ProductCardTrackingProps,
  ProductCardRenderer as ProductCard,
} from './ProductV2/ProductCardRenderer';

type TopExperiencesProps = {
  href: string;
  products: ProductType[];
  className?: string;
  title?: string;
  dataTestId?: string;
  ctaLabel?: string;
  hasRecentlyViewed?: boolean;
  displayEndCard?: boolean;
  onClick?: () => void;
  tracking?: ProductCardTrackingProps;
};

export const TopExperiences = ({
  href,
  products,
  className,
  title = 'Top Experiences',
  dataTestId = 'top-experiences',
  ctaLabel = 'Show All',
  hasRecentlyViewed = false,
  displayEndCard = false,
  onClick,
  tracking,
}: TopExperiencesProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  if (!products || !products.length) return null;

  const endCardtrackCtaLink = () => {
    trackEndCardToDataLayer(endCardData.endCardCtaLink);
  };

  const endCardTrackOnReached = () => {
    trackEndCardOnReachedToDataLayer(endCardData.endCardTitle);
  };

  // this is currently hard coded for top experience carousel as business requirements
  const endCardData = {
    endCardTitle: 'Top Experiences',
    endCardOverTitle: 'SEE MORE',
    endCardCtaTitle: 'Shop Now',
    endCardCtaLink: 'https://www.virginexperiencedays.co.uk/search',
    endCardtrackCtaLink,
    endCardTrackOnReached,
  };
  let renderCardsProductItems = products.map((item: ProductType, index: number) => (
    <ProductCard
      key={item.id}
      className="rounded-md border border-border-neutral"
      item={item}
      index={index}
      tracking={{ ...tracking, module: tracking?.module ?? 'top_experiences' }}
    />
  ));

  if (displayEndCard) {
    renderCardsProductItems = [
      ...renderCardsProductItems,
      <CarouselEndCard
        title={endCardData.endCardTitle}
        overTitle={endCardData.endCardOverTitle}
        ctaLink={endCardData.endCardCtaLink}
        ctaTitle={endCardData.endCardCtaTitle}
        trackCtaLink={endCardData.endCardtrackCtaLink}
        trackOnReached={endCardData.endCardTrackOnReached}
      />,
    ];
  }

  const perPage = hasRecentlyViewed
    ? {
        tablet: 2,
        desktop: 2,
        wide: 3,
      }
    : {
        tablet: 3,
        desktop: 4,
        wide: 5,
      };

  return (
    <Carousel
      dataTestId={dataTestId}
      className={cn(
        hasRecentlyViewed &&
          'md:after:absolute md:after:bottom-0 md:after:right-[-28px] md:after:top-[10%] md:after:border-l md:after:border-[#d3d5d7] md:last:after:hidden',
        hasRecentlyViewed &&
          'md:after:h-[calc(100%_-_28px)] lg:after:h-[calc(100%_-_16px)] xl:after:h-[calc(100%_-_28px)] 2xl:after:h-[calc(100%_-_38px)]',
        isProductCardsV2 && hasRecentlyViewed && 'flex flex-col',
        className
      )}
      trackWrapperClassName={cn(isProductCardsV2 && hasRecentlyViewed && 'flex h-full')}
      trackClassName={cn(
        'pt-0 pl-0 pb-0 pr-px scroll-p-4',
        // Old Product Card: 404px, sm:476px, md:390px, lg:387px, xl:367px, 2xl:399px
        !isProductCardsV2 &&
          'h-[404px] sm:h-[476px] md:h-[calc(100%_-_24px)] lg:h-[calc(100%_-_24px)] xl:h-[calc(100%_-_46px)] 2xl:h-[calc(100%_-_40px)]',
        // Old Product Card: 404px, sm:476px, md:327px, lg:377px, xl:330px, 2xl:365px
        !isProductCardsV2 &&
          hasRecentlyViewed &&
          'md:h-[calc(100%_-_40px)] lg:h-[calc(100%_-_24px)] xl:h-[calc(100%_-_40px)] 2xl:h-[calc(100%_-_44px)]'
      )}
      itemClassName={cn(
        'w-[calc(100%_-_80px)] max-w-[232px] sm:max-w-[320px] md:max-w-[unset] lg:max-w-[unset] xl:max-w-[unset]',
        'md:w-[calc((100%_-_((var(--footer-carousel-tablet)_*_16px)_-_16px))_/_var(--footer-carousel-tablet))]',
        'lg:w-[calc((100%_-_((var(--footer-carousel-desktop)_*_16px)_-_16px))_/_var(--footer-carousel-desktop))]',
        'xl:w-[calc((100%_-_((var(--footer-carousel-wide)_*_16px)_-_16px))_/_var(--footer-carousel-wide))]'
      )}
      title={title}
      titleSize="2"
      isHrefExternal
      href={href}
      ctaTitle={ctaLabel}
      onClick={onClick}
      perPage={perPage as ResponsivePerPage}
      style={
        {
          '--footer-carousel-tablet': perPage.tablet,
          '--footer-carousel-desktop': perPage.desktop,
          '--footer-carousel-wide': perPage.wide,
        } as CSSProperties
      }
    >
      {renderCardsProductItems}
    </Carousel>
  );
};
