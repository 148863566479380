import { useRouter } from 'next/router';
import useFeatureFlags from '@virginexperiencedays/feature-flags';
import GTM from '../components/tracking/gtm';
import { PAGE_TYPES } from '../components/tracking/gtm/consts';

export const initializeGtm = () => {
  const [isEnabled] = useFeatureFlags();
  const router = useRouter();

  // set default pageType values before GTM initializes
  const pageType = getDefaultPageTypes(router.pathname);
  if (pageType) global.pageType = pageType;

  // @TODO Remove this console log
  // eslint-disable-next-line no-console
  console.log('!! ~ ' + router.pathname, {
    dynamic: global?.pageType,
    default: pageType,
  });

  if (!isEnabled('FF_gtm')) return;

  const gtm = new GTM(
    process.env.NEXT_PUBLIC_GTM_ID,
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
  );

  gtm.initialize();
};

const getDefaultPageTypes = (pathname) => {
  const types = {
    '/404': PAGE_TYPES.Error,
    '/collection/[slug]': PAGE_TYPES.Product,
    '/collection/[slug]/product/[productSlug]': PAGE_TYPES.Product,
    '/experience-blog': PAGE_TYPES.BlogHome,
    '/experience-blog/page/[page]': PAGE_TYPES.BlogListing,
    '/experience-blog/[slug]': PAGE_TYPES.BlogPost,
    '/experience-blog/[slug]/page/[page]': PAGE_TYPES.BlogCategory,
    '/free-greetings-cards': PAGE_TYPES.GreetingsCards,
    '/gift-finder': PAGE_TYPES.GiftFinder,
    '/gift-finder/results': PAGE_TYPES.GiftFinderResults,
    '/': PAGE_TYPES.Home,
    '/search': PAGE_TYPES.Search,
    '/things-to-do/counties': PAGE_TYPES.ThingsToDoCounties,
    '/things-to-do': PAGE_TYPES.ThingsToDoHome,
    '/things-to-do/search': PAGE_TYPES.ThingsToDoSearch,
    '/things-to-do/[slug]': PAGE_TYPES.ThingsToDoPage,
    '/product/[slug]': PAGE_TYPES.Product,
    '/[...slug]': PAGE_TYPES.CustomSite,
    '/variation/[variation]': PAGE_TYPES.Home,
    '/variation/[variation]/gift-finder/results': PAGE_TYPES.GiftFinderResults,
    '/variation/[variation]/collection/[slug]': PAGE_TYPES.Product,
    '/variation/[variation]/collection/[slug]/product/[productSlug]': PAGE_TYPES.Product,
  };

  Object.assign(
    types,
    [
      '/c',
      '/cor',
      '/f',
      '/location',
      '/o',
      '/price',
      '/rating',
      '/variation/[variation]/c',
      '/variation/[variation]/cor',
      '/variation/[variation]/f',
      '/variation/[variation]/location',
      '/variation/[variation]/o',
      '/variation/[variation]/price',
      '/variation/[variation]/rating',
    ].reduce((acc, path) => {
      acc[`${path}/page/[page]/[...slug]`] = PAGE_TYPES.ListingCategory;
      acc[`${path}/plp/[...slug]`] = PAGE_TYPES.ListingCategory;
      acc[`${path}/[...slug]`] = PAGE_TYPES.ListingCategory;
      return acc;
    }, {})
  );

  if (pathname) return types?.[pathname];

  return types;
};
