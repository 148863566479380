import useEmblaCarousel from 'embla-carousel-react';

import { cn } from '../../../../utils';
import Image from '../Image';

import { useBindings } from './EmblaBindings';
import { ControlButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';

import type { EmblaOptionsType } from 'embla-carousel';
import type { HTMLAttributes } from 'react';

export type CarouselImage = {
  src: string;
  alt?: string;
  priority?: boolean;
};

// on swipe or nav click
export type CarouselCallbackProps = {
  currentIndex: number;
  selectedIndex: number;
  totalSlides: number;
  dir: 'next' | 'prev';
  gesture: 'swipe' | 'click';
};

export type EmblaCarouselProps = {
  slides: CarouselImage[];
  options?: Pick<EmblaOptionsType, 'loop' | 'active'>;
  priority?: boolean;
  imageTestId?: string;
  onSwipe?: (data: CarouselCallbackProps) => void;
  onNavClick?: (data: CarouselCallbackProps) => void;
  onSelectImage?: (index: number) => void;
} & HTMLAttributes<HTMLElement>;

export const EmblaCarousel = ({ className, imageTestId, ...props }: EmblaCarouselProps) => {
  const { slides, options } = props || {};

  if (slides.length === 0) return null;
  if (slides.length === 1 || !options?.active) {
    return (
      <CarouselImageComponent {...slides[0]} className={className} imageTestId={imageTestId} />
    );
  }
  return <Carousel {...props} className={className} />;
};

const CarouselImageComponent = ({
  src,
  alt,
  className,
  priority,
  imageTestId,
}: CarouselImage & { className?: string; imageTestId?: string }) => (
  <div
    className={cn(
      'relative h-full w-full',
      'after:absolute after:bottom-0 after:left-0 after:h-12 after:w-full after:bg-gradient-to-b after:from-transparent after:to-[rgba(0,0,0,0.25)]',
      className
    )}
  >
    <Image
      src={src}
      alt={alt || ''}
      fill
      className="h-full w-full"
      priority={priority}
      dataTestId={imageTestId}
    />
  </div>
);

const Carousel = ({
  slides,
  options,
  priority,
  className,
  onSwipe,
  onNavClick,
  onSelectImage,
}: Omit<EmblaCarouselProps, 'disableScroll'>) => {
  const totalSlides = slides.length;

  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, {
    onSelectImage,
  });
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi, { onNavClick, totalSlides });

  useBindings(emblaApi, {
    onSwipe,
    totalSlides,
  });

  return (
    <div className={cn('embla relative [&:hover>div:nth-child(2)]:opacity-100', className)}>
      <div className="embla__viewport h-full w-full overflow-hidden" ref={emblaRef}>
        <div
          className="embla__container flex h-full w-full"
          style={{ backfaceVisibility: 'hidden', touchAction: 'pan-y pinch-zoom' }}
        >
          {slides.map(({ src, alt }) => (
            <div
              className="embla__slide min-w-0 flex-shrink-0 flex-grow-0 basis-full"
              key={`${src}-${alt}`}
            >
              <CarouselImageComponent src={src} alt={alt} priority={priority} />
            </div>
          ))}
        </div>
      </div>

      <div
        className={cn(
          'embla__buttons',
          'absolute inset-0 hidden h-full w-full items-center justify-between lg:flex',
          'opacity-0 transition-opacity duration-500 ease-in-out coarse-hover:hidden'
        )}
      >
        <ControlButton
          direction="prev"
          onClick={(e) => onPrevButtonClick(e, selectedIndex)}
          disabled={prevBtnDisabled}
        />
        <ControlButton
          direction="next"
          onClick={(e) => onNextButtonClick(e, selectedIndex)}
          disabled={nextBtnDisabled}
        />
      </div>

      <div className="embla__dots pointer-events-none absolute bottom-0 left-1/2 flex h-10 w-full -translate-x-1/2 select-none flex-nowrap items-center justify-center gap-2">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={cn(
              'embla__dot',
              'pointer-events-none z-10 flex h-2 w-2 touch-manipulation appearance-none items-center justify-center rounded-full bg-white opacity-50 transition-all duration-300',
              index === selectedIndex && 'opacity-100'
            )}
            style={{ transform: `scale(calc(1 - 0.${Math.abs(selectedIndex - index)}))` }}
            disabled
          />
        ))}
      </div>
    </div>
  );
};
