import type { SvgElementProps } from '../../../types';

const IconCamera = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  strokeWidth = '1.5',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.5721 7.48235C8.31363 7.89145 7.96877 8.23904 7.56171 8.50073C7.15466 8.76243 6.69528 8.93188 6.21579 8.99722C5.67015 9.07476 5.12882 9.15804 4.58749 9.2485C3.07549 9.49978 2 10.8309 2 12.363V24.4618C2 25.3187 2.34038 26.1404 2.94627 26.7463C3.55216 27.3522 4.37392 27.6926 5.23077 27.6926H26.7692C27.6261 27.6926 28.4478 27.3522 29.0537 26.7463C29.6596 26.1404 30 25.3187 30 24.4618V12.363C30 10.8309 28.9231 9.49978 27.4125 9.2485C26.8708 9.15824 26.328 9.07447 25.7842 8.99722C25.305 8.93169 24.8459 8.76213 24.4391 8.50045C24.0323 8.23876 23.6877 7.89128 23.4293 7.48235L22.249 5.59271C21.9839 5.16208 21.619 4.80166 21.1851 4.54197C20.7512 4.28227 20.2611 4.13095 19.7563 4.10081C17.2539 3.9664 14.7461 3.9664 12.2437 4.10081C11.7389 4.13095 11.2488 4.28227 10.8149 4.54197C10.381 4.80166 10.0161 5.16208 9.75097 5.59271L8.5721 7.48235Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4617 16.9235C22.4617 18.6372 21.7809 20.2807 20.5691 21.4924C19.3573 22.7042 17.7138 23.385 16.0001 23.385C14.2864 23.385 12.6429 22.7042 11.4311 21.4924C10.2193 20.2807 9.53857 18.6372 9.53857 16.9235C9.53857 15.2097 10.2193 13.5662 11.4311 12.3545C12.6429 11.1427 14.2864 10.4619 16.0001 10.4619C17.7138 10.4619 19.3573 11.1427 20.5691 12.3545C21.7809 13.5662 22.4617 15.2097 22.4617 16.9235ZM25.6924 13.6927H25.7039V13.7042H25.6924V13.6927Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCamera;
