export const IconAngleLeft = ({
  width = '1em',
  height = '1em',
  stroke = 'currentColor',
  title = 'Arrow Left Icon',
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>{title}</title>
    <path
      d="M5 9L1 5l4-4"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
