import type { SvgElementProps } from '../../../types';

const IconMobileTent = ({
  width = 32,
  height = 32,
  fill = 'var(--text-neutral)',
  themeColor,
  strokeWidth,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6894 5.10928C18.8878 4.74564 18.7538 4.29007 18.3902 4.09172C18.0265 3.89337 17.5709 4.02737 17.3726 4.391L15.9935 6.91939L14.6144 4.3911C14.4161 4.02746 13.9605 3.89347 13.5968 4.09182C13.2332 4.29016 13.0992 4.74574 13.2976 5.10938L15.1392 8.48564L5.36093 26.4124H2.75C2.33579 26.4124 2 26.7482 2 27.1624C2 27.5766 2.33579 27.9124 2.75 27.9124H5.79408C5.80171 27.9125 5.80934 27.9125 5.81696 27.9124H26.1667C26.1736 27.9125 26.1805 27.9126 26.1874 27.9125C26.1904 27.9125 26.1933 27.9125 26.1963 27.9124H29.2371C29.6514 27.9124 29.9871 27.5766 29.9871 27.1624C29.9871 26.7482 29.6514 26.4124 29.2371 26.4124H26.626L16.8478 8.48564L18.6894 5.10928ZM11.9185 26.4124H7.06956L15.9935 10.0519L24.9174 26.4124H20.0683V26.3999V23.75C20.0683 21.5409 18.2775 19.75 16.0683 19.75H15.9185C13.7093 19.75 11.9185 21.5409 11.9185 23.75V26.3999V26.4124ZM15.9185 21.25H16.0683C17.4491 21.25 18.5683 22.3693 18.5683 23.75V26.3999H13.4185V23.75C13.4185 22.3693 14.5377 21.25 15.9185 21.25Z"
      fill={themeColor || fill}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default IconMobileTent;
