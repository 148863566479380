import { Actions, QuizAnswers, type ActionType, type GiftFinderState } from '../types';
import { giftFinderInitialState } from './initialState';

export const reducer = (state: GiftFinderState, action: ActionType): GiftFinderState => {
  const { type, payload } = action;

  switch (type) {
    case Actions.SET_ACTIVE:
      return { ...state, active: payload };
    case Actions.NEXT_STEP: {
      if (state.step === state.quiz.length - 1) return state;
      return { ...state, step: state.step + 1 };
    }
    case Actions.PREV_STEP: {
      if (state.step === 0) return state;
      return { ...state, step: state.step - 1 };
    }
    case Actions.SET_STEP:
      return { ...state, step: payload };
    case Actions.SET_SKIP_SCROLL_CALLBACK:
      return { ...state, skipScrollCallback: payload };
    case Actions.SET_SINGLE_ANSWER:
      return handleSetAnswer(payload, state);
    case Actions.SET_MULTIPLE_ANSWER:
      return handleSetMultiChoiceAnswer(payload, state);
    case Actions.SET_RANGE_ANSWER:
      return handleSetAnswer(payload, state);
    case Actions.HANDLE_SUBMIT:
      return state;
    case Actions.SET_ERROR:
      return { ...state, errors: { ...state.errors, ...payload } };
    case Actions.SET_SUBMIT_ERROR:
      return { ...state, submitError: payload };
    case Actions.SET_TRACK_SWIPABLE:
      return { ...state, trackSwipable: payload };
    case Actions.CLEAR_ANSWERS:
      return giftFinderInitialState;
    case Actions.CLEAR_SINGLE_ANSWER:
      return handleClearAnswer(payload, state);
    case Actions.CLEAR_ERRORS:
      return { ...state, errors: giftFinderInitialState.errors };
    default:
      return state;
  }
};

/**
 * Updates the state with the provided answer payload specifying the name to delete.
 * @param payload - The answer payload to update the state with deleted value based from "name"
 * @param state - The current state of the GiftFinder.
 * @returns The updated state of the GiftFinder.
 */
function handleClearAnswer(payload: any, state: GiftFinderState): GiftFinderState {
  if (!payload?.name || !(payload.name in state.answers)) return state;

  const answersCopy: QuizAnswers = { ...state.answers };
  delete answersCopy[payload.name];

  return { ...state, answers: answersCopy };
}

/**
 * Updates the state with the provided answer payload.
 * @param payload - The answer payload to update the state with.
 * @param state - The current state of the GiftFinder.
 * @returns The updated state of the GiftFinder.
 */
function handleSetAnswer(payload: any, state: GiftFinderState): GiftFinderState {
  if (!payload?.name || !payload?.value?.length) return state;

  const answersCopy: QuizAnswers = { ...state.answers };
  answersCopy[payload.name] = [payload.value];

  return { ...state, answers: answersCopy };
}

/**
 * Handles setting a multi-choice answer in the GiftFinder quiz.
 * ? this is very similar to handleSetAnswer, can/should we combine them
 * @param payload - The payload containing the answer name and value.
 * @param state - The current GiftFinder state.
 * @returns The updated GiftFinder state.
 */
function handleSetMultiChoiceAnswer(payload: any, state: GiftFinderState): GiftFinderState {
  if (!payload?.name || !payload?.value?.length) return state;

  const answersCopy: QuizAnswers = { ...state.answers };
  const answerValues = answersCopy[payload.name];

  if (!answerValues?.length) {
    answersCopy[payload.name] = [payload.value];
    return { ...state, answers: answersCopy };
  } else if (answerValues.includes(payload.value)) {
    answersCopy[payload.name] = answerValues.filter((value: string) => value !== payload.value);
    return { ...state, answers: answersCopy };
  } else {
    answersCopy[payload.name] = [...answerValues, payload.value];
    return { ...state, answers: answersCopy };
  }
}
