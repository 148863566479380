export type GiftFinderState = {
  active: boolean;
  title?: string;
  quiz: QuizStep[];
  step: number;
  skipScrollCallback?: boolean;
  answers: QuizAnswers;
  errors: QuizErrors;
  submitError: string | null;
  resultsCookieName: string;
  trackSwipable: boolean;
};

export enum Actions {
  SET_ACTIVE = 'SET_ACTIVE',
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  SET_STEP = 'SET_STEP',
  SET_SKIP_SCROLL_CALLBACK = 'SET_SKIP_SCROLL_CALLBACK',
  SET_SINGLE_ANSWER = 'SET_SINGLE_ANSWER',
  SET_MULTIPLE_ANSWER = 'SET_MULTIPLE_ANSWER',
  SET_RANGE_ANSWER = 'SET_RANGE_ANSWER',
  HANDLE_SUBMIT = 'HANDLE_SUBMIT',
  SET_ERROR = 'SET_ERROR',
  SET_SUBMIT_ERROR = 'SET_SUBMIT_ERROR',
  SET_TRACK_SWIPABLE = 'SET_TRACK_SWIPABLE',
  CLEAR_ANSWERS = 'CLEAR_ANSWERS',
  CLEAR_SINGLE_ANSWER = 'CLEAR_SINGLE_ANSWER',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
}

export type ActionType = { type: Actions; payload?: any };

export type GiftFinderFormComponent = { type: 'single' | 'multiple' | 'range' };

export type GiftFinderMultiChoiceComponent = {
  minSelections?: number;
  maxSelections?: number;
} & GiftFinderFormComponent;

export type GiftFinderRangeComponent = {
  min: number;
  max: number;
  step: number;
  fromValue: number;
  toValue: number;
  stepValues?: number[];
} & GiftFinderFormComponent;

export type QuizOption = { uuid: string; text: string };

export type QuizStep = {
  step: number;
  uuid: string;
  componentProps:
    | GiftFinderFormComponent
    | GiftFinderMultiChoiceComponent
    | GiftFinderRangeComponent;
  question: string;
  subText?: string;
  options?: QuizOption[];
  skip?: boolean;
};

export type QuizConfig = {
  title?: string;
  quiz: QuizStep[];
};

export type QuizAnswers = Record<QuizStep['uuid'], QuizOption['uuid'][]>;
export type QuizErrors = Record<QuizStep['uuid'], string>;
