import { useState, type RefObject } from 'react';

import { cn, openMobileNavToDataLayer } from '../../utils';
import IconButton from '../common/ui/buttons/IconButton';
import { MegaNavMobile, MegaNavDesktop } from '../MegaNav';
import MenuContextProvider from '../MegaNav/Desktop/Menu/MenuContext';

import type { HeaderProps } from '../../types';
import type { NavStackState } from '../MegaNav/Mobile/NavigationBar/NavList';

type Props = {
  navigation: HeaderProps['navigation'];
  className?: HTMLElement['className'];
  navExpanded: boolean;
  mobileNavOpen: boolean;
  mobileNavRef: RefObject<HTMLElement>;
  handleNavExpanded: (expanded: boolean) => void;
  handleToggleMobileNav: () => void;
};

export const DEFAULT_NAV_STATE: NavStackState = { currentLvl: 1, selectedStack: [] };

const HeaderMenu = ({
  navigation,
  className,
  navExpanded,
  mobileNavOpen,
  handleToggleMobileNav,
  handleNavExpanded,
  mobileNavRef,
}: Props) => {
  const { navItems, featuredColumn, secondaryNavItems, links } = navigation;
  const [activeNavStack, setActiveNavStack] = useState(DEFAULT_NAV_STATE);

  const handleMobileNav = () => {
    handleToggleMobileNav();

    if (!mobileNavOpen) {
      // opened - log to data layer
      openMobileNavToDataLayer();
    } else {
      // closed - reset menu levels
      setActiveNavStack(DEFAULT_NAV_STATE);
    }
  };

  return (
    <>
      <MobileNavButton isActive={mobileNavOpen} onClick={handleMobileNav} />
      {/* Desktop Nav - this will be primarly used by crawlers, so we need it to be rendered at all times and just hide in mobile via CSS */}
      <MenuContextProvider>
        <MegaNavDesktop
          className={cn('mx-auto hidden lg:block', className, 'lg:p-0')}
          navigation={navItems}
          featuredColumn={featuredColumn}
          secondaryNavigation={secondaryNavItems ?? []}
          navExpanded={navExpanded}
          handleNavExpanded={handleNavExpanded}
        />
      </MenuContextProvider>
      {/* Mobile Nav - to avoid confusion to crawlers detecting the desktop navs on load, we only render mobile nav on burger button click */}
      {mobileNavOpen && (
        <MegaNavMobile
          ref={mobileNavRef}
          navItems={navItems}
          secondaryNavItems={secondaryNavItems}
          links={links}
          mobileNavOpen={mobileNavOpen}
          activeNavStack={activeNavStack}
          setActiveNavStack={setActiveNavStack}
          handleToggleMobileNav={handleToggleMobileNav}
        />
      )}
    </>
  );
};

const MobileNavButton = ({
  className,
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
  className?: string;
}) => (
  <IconButton
    className={cn(styles.burger.button._, isActive && styles.burger.button.active, className)}
    aria-label="Open navigation menu"
    onClick={onClick}
  >
    {(['top', 'mid', 'bottom'] as BurgerLinesStyle[]).map((line: BurgerLinesStyle) => {
      const lineStyles = styles.burger.lines[line] as { _: string; active: string };
      return (
        <span
          key={line}
          className={cn(
            styles.burger.lines._,
            lineStyles._,
            isActive && styles.burger.lines.active,
            isActive && lineStyles.active
          )}
        />
      );
    })}
  </IconButton>
);

type BurgerLinesStyle = keyof typeof styles['burger']['lines'];

const styles = {
  burger: {
    button: {
      _: 'w-full flex-col gap-[3px] rounded-none border-solid border-y border-border-neutral bg-background-page transition-all duration-300 lg:hidden lg:rounded-sm lg:border-y-0 focus:outline-none',
      active: 'bg-primary-50 dark:bg-nav-item-background-hover',
    },
    lines: {
      _: 'h-0.5 bg-neutral w-4 rounded transition-all duration-300',
      active: 'bg-grey-700 dark:bg-persistent-text-light',
      top: {
        _: 'rotate-0 translate origin-top-left',
        active: 'rotate-45 translate-x-[3px] -translate-y-px',
      },
      mid: {
        _: 'opacity-100',
        active: 'opacity-0',
      },
      bottom: {
        _: 'rotate-0 translate origin-bottom-left',
        active: '-rotate-45 translate-x-[3px] translate-y-0',
      },
    },
  },
};

export default HeaderMenu;
