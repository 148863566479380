import { RefObject, useEffect, useState } from 'react';

type Props = {
  elementRef: RefObject<Element | undefined>;
  options?: IntersectionObserverInit;
  callbackFn?: (intersecting: boolean) => void;
  disabled?: boolean;
};

const useInView = ({
  elementRef,
  options = { threshold: 0, root: null, rootMargin: '0%' },
  callbackFn,
  disabled,
}: Props) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    if (disabled) return;
    if (!window?.IntersectionObserver) return;

    const callback = (entries: { isIntersecting: boolean }[]) => {
      const isIntersecting = !!entries?.[0]?.isIntersecting;
      setIsIntersecting(isIntersecting);
      callbackFn?.(isIntersecting);
    };

    observer?.disconnect();

    if (elementRef?.current) {
      const _observer = new IntersectionObserver(callback, options);
      _observer.observe(elementRef.current);
      setObserver(_observer);
    }

    return () => {
      observer?.disconnect();
    };
  }, [elementRef?.current, options.root, options.rootMargin, options.threshold]);

  return isIntersecting;
};

export { useInView };
