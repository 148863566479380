import { useHeaderContext } from '../../../../context/HeaderContext';
import { getProductCardPrices } from '../../../../utils/productPricing';
import SearchResultCard from '../../../Header/SearchOverlay/SearchResultCard';
import {
  ProductCard as ProductCardV1,
  type ProductCardProps as ProductCardV1Props,
} from '../Product';

import {
  ProductInteractionProps,
  handleProductCardImageChange,
  trackProductCardV2Click,
} from './tracking';

import { ProductCardV2, getLocationText, useIsProductCardsV2 } from '.';

import type { ProductType, SearchResultCardProps } from '../../../../types';
import type { SearchResultCardsProps } from '../../../Header/SearchOverlay/SearchResult';
import type { CarouselCallbackProps } from '../EmblaCarousel';
import type { MouseEvent } from 'react';

export type ProductCardTrackingProps = {
  pageType?: string;
  module?: string;
};

export type ProductCardRendererProps = {
  index: number;
  item: ProductType & {
    onClick?: SearchResultCardsProps['onCardClick'];
  };
  className?: string;
  titleClassName?: string;
  isSearchCard?: boolean;
  isImageCarousel?: boolean;
  tracking?: ProductCardTrackingProps;
};

export const ProductCardRenderer = ({
  item,
  className,
  titleClassName,
  isSearchCard,
  isImageCarousel,
  tracking,
  index,
}: ProductCardRendererProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const { trackingPageType } = useHeaderContext();

  if (!isProductCardsV2) {
    /**
     * V1 - Header Search Result / Recently Viewed
     */
    if (isSearchCard) {
      const searchCardItem = {
        ...item,
        categories: Array.isArray(item.categories) ? item.categories : [],
        currentPrice: Number(item?.currentPrice || 0),
        pastPrice: Number(item?.pastPrice || 0),
      } as SearchResultCardProps;

      return <SearchResultCard {...searchCardItem} />;
    }

    /**
     * V1 - Footer Top Experiences / Recently Viewed
     */
    return <ProductCardV1 className={className} {...(item as unknown as ProductCardV1Props)} />;
  }

  /**
   * V2 - Covers both Header and Footer cards
   */
  const {
    currentPrice,
    pastPrice,
    roundedPercentOff: percentOff,
  } = getProductCardPrices({
    displayPrice: item?.currentPrice,
    rrp: item?.pastPrice,
    percentOff: item?.percentOff,
    formatNumber: true,
  });

  const card = {
    ...(item || {}),
    currentPrice: currentPrice ? Number(currentPrice) : 0,
    pastPrice: pastPrice ? Number(pastPrice) : 0,
    percentOff: percentOff ? Number(percentOff) : 0,
    locations:
      typeof item?.locations === 'number' ? `${item?.locations} locations` : item?.locations,
  };

  const trackingData = {
    ...card,
    pageType: tracking?.pageType || trackingPageType,
    module: tracking?.module,
    position: index + 1,
    location: getLocationText({
      locations: 'locations' in card ? card.locations : '',
      categories: 'categories' in card ? card.categories : [],
    }),
  } as Partial<ProductInteractionProps>;

  // for users with existing Recently Viewed data in their local storage with no "cardImages", fallback to rendering single image from "cardImage"
  const images = card?.cardImages
    ? card?.cardImages
    : [{ src: card.cardImage, alt: card.cardTitle }];

  return (
    <ProductCardV2
      titleClassName={titleClassName}
      fullSku={card.cardFullSku}
      productUrl={card.cardRoute}
      averageRating={card?.reviews?.averageRating}
      title={card.cardTitle}
      locations={card.locations}
      displayPrice={card.currentPrice}
      rrp={card.pastPrice}
      percentOff={card.percentOff}
      images={images}
      isImageCarousel={isImageCarousel}
      categories={card?.categories || []}
      productPromo={card?.productPromo}
      isCollectionProduct={!!card?.isCollectionProduct}
      onImageCarouselSwipe={(props: CarouselCallbackProps) => {
        handleProductCardImageChange({ ...props, trackingData });
      }}
      onImageCarouselNavClick={(props: CarouselCallbackProps) => {
        handleProductCardImageChange({ ...props, trackingData });
      }}
      onClick={(e: MouseEvent<HTMLElement>) => {
        trackProductCardV2Click(e, { trackingData });

        // includes tracking from components/Header/SearchOverlay/SearchResultsWrapper.tsx
        card?.onClick?.(card?.cardTitle);
      }}
    />
  );
};
