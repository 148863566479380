import type { EnvVariables } from '@virginexperiencedays/header-2024/src';

export const envVariables: EnvVariables = {
  prismicRepoEndpoint: process.env.NEXT_PUBLIC_PRISMIC_REPO_ENDPOINT,
  prismicToken: process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN,
  homeLink: process.env.NEXT_PUBLIC_HOME_LINK,
  siteLogoDefault: process.env.NEXT_PUBLIC_SITE_LOGO_DEFAULT,
  siteLogoWhite: process.env.NEXT_PUBLIC_SITE_LOGO_WHITE,
  isBlackFriday: process.env.NEXT_PUBLIC_FF_BLACK_FRIDAY,
  isChristmas: process.env.NEXT_PUBLIC_FF_CHRISTMAS,
  isCyberMonday: process.env.NEXT_PUBLIC_FF_CYBER_MONDAY,
  isEaster: process.env.NEXT_PUBLIC_FF_EASTER,
  isMothersDay: process.env.NEXT_PUBLIC_FF_MOTHERS_DAY,
  isFathersDay: process.env.NEXT_PUBLIC_FF_FATHERS_DAY,
  isValentinesDay: process.env.NEXT_PUBLIC_FF_VALENTINES_DAY,
  blackFridayBadge: process.env.NEXT_PUBLIC_BLACK_FRIDAY_BADGE,
  cyberMondayBadge: process.env.NEXT_PUBLIC_CYBER_MONDAY_BADGE,
  christmasBadge: process.env.NEXT_PUBLIC_CHRISTMAS_BADGE,
  valentinesDayBadge: process.env.NEXT_PUBLIC_VALENTINES_BADGE,
  algoliaAppId: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_APP_ID,
  algoliaSearchApiKey: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_SEARCH_API_KEY,
  algoliaSearchIndex: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME,
  algoliaSuggestionIndex: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_query_suggestions`,
  productApiUrl: process.env.NEXT_PUBLIC_PRODUCTS_API_URL,
  cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  isProductCardsV2: process.env.NEXT_PUBLIC_FF_PRODUCT_CARDS_V2,
  isFeaturedColumnFallback: process.env.NEXT_PUBLIC_FF_NAV_FEATURED_COLUMN_FALLBACK,
};

export const navigationServiceUrl = process?.env?.NEXT_PUBLIC_NAVIGATION_SERVICE_BASE_URL;
