import { useEffect } from 'react';
import { getCookieMap } from '../../utils/cookie';

import type { ValidDYCookies } from '@virginexperiencedays/dy';

type UseReportSplitTest = {
  cookieKey: string;
  dyCampaignSelector: string;
  callback: (data: Record<string, string>) => void;
};

export const useReportCfSplitTest = ({
  cookieKey,
  dyCampaignSelector,
  callback,
}: UseReportSplitTest) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!dyCampaignSelector || !cookieKey || !callback) return;

    // Step 1: Check for all DY cookies
    const dyCookieMap: Omit<ValidDYCookies, '_dyid'> = {
      _dy_cf_variation: null,
      _dyid_server: null,
      _dyjsession: null,
    };

    const keys = Object.keys(dyCookieMap);
    const allCookiesMap = getCookieMap();

    keys.forEach((key) => {
      const value = allCookiesMap?.get(key);
      if (value) {
        dyCookieMap[key] = value;
      }
    });

    // Step 2: Check if all DY cookies are found
    const allValuesFound = Object.values(dyCookieMap).every((v) => !!v);
    if (!allValuesFound) {
      localStorage.removeItem(cookieKey); // Remove the localStorage value if the DY cookies are not found
      return;
    }

    // Step 3: Check whether the visit has already been recorded based on the _dyjsession cookie value
    //         For as long as the session is valid, the visit will not be recorded again
    const recordCookie = localStorage.getItem(cookieKey);
    if (recordCookie === dyCookieMap._dyjsession) return; // Already recorded

    // Step 4: Record the visit
    const testingType = 'CloudFront';
    const eventLabel = `Campaign: ${dyCampaignSelector} | Variation: ${dyCookieMap._dy_cf_variation} | Session: ${dyCookieMap._dyjsession} | ID: ${dyCookieMap._dyid_server}`;

    callback({
      event: 'genericEvent',
      eventCategory: 'Split Testing',
      eventAction: testingType,
      eventLabel,
    });

    // Step 5: Set the visit as recorded
    localStorage.setItem(cookieKey, dyCookieMap._dyjsession);
  }, []);
};
