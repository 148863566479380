import useSWR, { useSWRConfig } from 'swr';

import { fetchVedNavigationItems } from '../utils/fetchVedNavigationItems';
import { getValidNavigationItems } from '../utils/getValidNavigationItems';

type Params = {
  header: Record<string, unknown>;
  footer: Record<string, unknown>;
};

/**
 * A custom hook for handling SWR cache and fetching valid navigation items.
 *
 * @param initialNavItems The initial navigation items to use if revalidatedNavItems is invalid.
 * @returns An object containing the header and footer objects.
 */
export const useSwrNavigationCache = (
  navigationServiceUrl: string,
  initialNavItems?: unknown
): Params => {
  const { cache } = useSWRConfig();

  const { data: revalidatedNavItems } = useSWR(
    navigationServiceUrl,
    async () => await fetchVedNavigationItems(navigationServiceUrl),
    {
      revalidateOnMount: !cache.get(navigationServiceUrl)?.data,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return getValidNavigationItems(initialNavItems, revalidatedNavItems);
};
