import type { PortableTextBlock } from '../types';

export type PortableTextTrackingFn = 'promoBanner';

/**
 * Adds a tracking entry to links in an array of PortableTextBlocks.
 *
 * @param arr - The array of PortableTextBlocks to modify.
 * @param fnName - The name of the tracking function to add.
 * @returns The modified array of PortableTextBlocks.
 */
export const addTrackingEntryToLinks = (
  arr: PortableTextBlock[],
  fnName: PortableTextTrackingFn
): PortableTextBlock[] => {
  if (!Array.isArray(arr) || !fnName) return arr;
  arr.forEach((obj) => {
    if (obj._type === 'link') obj.trackingFn = fnName;
    else if (obj.children) obj.children = addTrackingEntryToLinks(obj.children, fnName);
  });
  return arr;
};
