import { useState, useRef, useMemo, type FormEvent } from 'react';

import type { GiftFinderMultiChoiceComponent, QuizStep } from '../../../types';
import { choiceLabelInputStyles, choiceLabelSpanStyles } from '../../shared-styles';

import { ChoiceContainer } from './ChoiceContainer';

type Props = {
  handleAnswer: (e: FormEvent) => void;
  name: QuizStep['uuid'];
  options: QuizStep['options'];
  maxSelections?: GiftFinderMultiChoiceComponent['maxSelections'];
};

export const MultipleChoice = ({ handleAnswer, name, options, maxSelections = 3 }: Props) => {
  const [selected, setSelected] = useState(0);

  const handleChange = (e: FormEvent) => {
    const { checked } = e.target as HTMLInputElement;

    setSelected((prev) => (checked ? prev + 1 : prev - 1));
    handleAnswer(e);
  };

  return (
    <ChoiceContainer className="flex-wrap content-start gap-2">
      {options?.map(({ uuid, text }) => (
        <label key={uuid} className="relative">
          <Input
            className={choiceLabelInputStyles}
            name={name}
            value={uuid}
            disabled={selected === maxSelections}
            handleChange={handleChange}
          />
          <span className={choiceLabelSpanStyles}>{text}</span>
        </label>
      ))}
    </ChoiceContainer>
  );
};

type InputProps = {
  value: string;
  disabled?: boolean;
  className?: string;
  handleChange: (e: FormEvent) => void;
} & Pick<Props, 'name'>;

const Input = ({ name, value, disabled, handleChange, className }: InputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const isDisabled = useMemo(() => {
    if (!ref?.current) return;
    const checked = ref.current.checked;
    return disabled && !checked;
  }, [disabled]);

  return (
    <input
      className={className}
      ref={ref}
      type="checkbox"
      name={name}
      value={value}
      onChange={handleChange}
      disabled={isDisabled}
    />
  );
};
