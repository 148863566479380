import { CSSProperties, HTMLAttributes, ReactNode, useRef } from 'react';

import { useRefScrollTo } from '../../../../hooks';
import { cn } from '../../../../utils';

import { CarouselHeading } from './Heading';
import { sizeConfig } from './sizeConfig';
import { Track } from './Track';

import type { DisplayHeadingProps } from '../DisplayHeading';

export type ResponsivePerPage = {
  mobile?: keyof typeof sizeConfig['mobile'];
  tablet?: keyof typeof sizeConfig['tablet'];
  desktop?: keyof typeof sizeConfig['desktop'];
  wide?: keyof typeof sizeConfig['wide'];
};

type CarouselProps = {
  dataTestId?: string;
  /**
   * Styles specifically for heading
   */
  headingClassName?: string;
  /**
   * Styles specifically for track wrapper
   */
  trackWrapperClassName?: string;
  /**
   * Styles specifically for track
   */
  trackClassName?: string;
  /**
   * Styles specifically for track items
   */
  itemClassName?: string;
  /**
   * Carousel elements
   */
  children: Array<ReactNode>;
  /**
   * Title displayed on the opposite site of the controls
   */
  title?: string;
  /**
   * Title size corresponding to sizes of `DisplayHeading`
   */
  titleSize?: DisplayHeadingProps['size'];
  /**
   * Description shown below title
   */
  description?: string;
  /**
   * Link for the CTA
   * When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle */
  href?: string;
  /**
   * If true, forces the CTA link to be an anchor tag to an external link, not
   * subject to Next.js client-side routing.
   */
  isHrefExternal?: boolean;
  /**
   * Title for cta
   */
  ctaTitle?: string;
  /**
   * Debounce time to prevent spamming, recommended is 350(default)
   */
  debounceTime?: number;
  /**
   * When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle
   */
  onClick?: () => void;
  perPage?: ResponsivePerPage;
  withHeading?: boolean;
  /**
   * Toggles displaying the page indicator dots.
   */
  showPageIndicators?: boolean;
  /**
   * Toggles displaying the navigation arrows.
   * True by default, for backward compatibility, but prefer this over the
   * negative hideNavigationArrows, which might cause confusion.
   */
  showNavigationArrows?: boolean;
  /**
   * if true, the carousel will be styled for product cards
   */
  isProductCarousel?: boolean;
  /**
   * Dependencies for resetting carousel scroll position (e.g. route change)
   */
  scrollToDeps?: unknown[];
  trackStyle?: CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

export const Carousel = ({
  className,
  headingClassName,
  trackWrapperClassName,
  trackClassName,
  itemClassName,
  children,
  title,
  titleSize,
  description,
  ctaTitle,
  href,
  isHrefExternal,
  debounceTime = 350,
  onClick,
  perPage = {
    tablet: 3,
    desktop: 4,
    wide: 5,
  },
  withHeading = true,
  showPageIndicators,
  showNavigationArrows = true,
  isProductCarousel = false,
  scrollToDeps = [],
  dataTestId,
  style,
  trackStyle,
}: CarouselProps) => {
  const carouselRef = useRef(null);
  const lastChildRef = useRef(null);
  const firstChildRef = useRef(null);

  // Reset carousel scroll position based on deps (e.g. router.asPath)
  useRefScrollTo({ ref: carouselRef, deps: scrollToDeps });

  return (
    <div className={cn('relative', className)} style={style}>
      {withHeading && (
        <CarouselHeading
          className={headingClassName}
          titleSize={titleSize}
          debounceTime={debounceTime}
          title={title}
          description={description}
          href={href}
          isHrefExternal={isHrefExternal}
          ctaTitle={ctaTitle}
          showNavigationArrows={showNavigationArrows}
          carouselRef={carouselRef}
          lastChildRef={lastChildRef}
          firstChildRef={firstChildRef}
          onClick={onClick}
          dataTestId={dataTestId}
        />
      )}
      <div className={trackWrapperClassName}>
        <Track
          isProductCarousel={isProductCarousel}
          className={trackClassName}
          style={trackStyle}
          itemClassName={itemClassName}
          perPage={perPage}
          carouselRef={carouselRef}
          firstChildRef={firstChildRef}
          lastChildRef={lastChildRef}
          showPageIndicators={showPageIndicators}
        >
          {children}
        </Track>
      </div>
    </div>
  );
};
