import type { SvgElementProps } from '../../../types';

const IconChristmasGift = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.0999 1V19"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.32593 5.5L17.9144 14.5"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.32349 14.4898L17.8765 5.51025"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.7 3.69998L6.05 7.74998L2 8.64998"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 11.35L6.05 12.25L4.7 16.3"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5001 3.69998L14.1501 7.74998L18.2001 8.64998"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2001 11.35L14.1501 12.25L15.5001 16.3"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.40015 2.35001L10.1001 5.05001L12.8001 2.35001"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.40015 17.65L10.1001 14.95L12.8001 17.65"
      stroke={themeColor || fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconChristmasGift;
