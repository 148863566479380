import type { HTMLAttributes, ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';

export type LinkWrapProps = {
  href: string;
  className?: string;
  isExternal?: boolean;
  children?: ReactNode;
  itemProp?: string;
  title?: string;
  target?: string;
  onMouseLeave?: () => void;
  rel?: string;

  /** data properties */
  dataProductId?: string;
  dataSrc?: string;
  dataMinWidth?: string;
  dataTestId?: string;
} & LinkProps &
  HTMLAttributes<HTMLAnchorElement>;

export const LinkWrap = ({
  className,
  href,
  children,
  itemProp,
  title,
  target,
  dataProductId,
  dataSrc,
  dataMinWidth,
  dataTestId,
  onMouseLeave,
  isExternal = false,
  ...rest
}: LinkWrapProps) => {
  const anchorProps = {
    href,
    rel: 'noopener noreferrer',
    'data-productid': dataProductId,
    'data-src': dataSrc,
    'data-min-width': dataMinWidth,
    'data-testid': dataTestId,
    itemProp,
    title,
    onMouseLeave,
    ...rest,
  };

  // Prevents CMS RichTextWrap link error: `href` expects a `string` or `object` in `<Link>`, but got `undefined` instead.
  if (!href) return <>{children}</>;

  if (isExternal) {
    return (
      <a className={className} target={target || '_blank'} {...anchorProps}>
        {children}
      </a>
    );
  }

  let prefetchAttr: 'development' | 'hover' | 'all' = 'development';
  if (process.env.NODE_ENV === 'production') {
    prefetchAttr = rest?.prefetch === false ? 'hover' : 'all';
  }

  return (
    <Link
      className={className}
      target={target || '_self'}
      data-prefetch={prefetchAttr}
      {...anchorProps}
    >
      <>{children}</>
    </Link>
  );
};
