import type { NextRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useGiftFinderContext } from '../context';

/**
 * Custom hook for handling the state and actions of the gift finder form based on the URL.
 * If the current route is in the `forceOpenRoutes` array, the gift finder will be opened.
 * If `forceOpenRoutes` is not provided then all routes will open the gift finder.
 *
 * @param router - The Next.js router object.
 * @param forceOpenRoutes - An optional array of route paths that should always trigger the gift finder to open.
 */
export const useGiftFinderAutoOpen = (router: NextRouter, forceOpenRoutes?: string[]): void => {
  const [firedOnce, setFiredOnce] = useState(false);
  const { handleOpen } = useGiftFinderContext();

  const { pathname } = router;

  const handleRouteChange = useCallback(() => {
    setFiredOnce(true);
    handleOpen('auto', { eventAction: 'Auto Opened Gift Finder' });
  }, [handleOpen]);

  useEffect(() => {
    const shouldOpen = forceOpenRoutes?.includes(pathname) || !forceOpenRoutes;

    if (shouldOpen && !firedOnce) handleRouteChange();
  }, [forceOpenRoutes, pathname, firedOnce, handleRouteChange]);
};
