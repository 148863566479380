/**
 * Parses cookies into a Map for convenience.
 */
export function getCookieMap(): Map<string, string> | null {
  if (!global.document?.cookie) return null;

  const cookieEntries = global.document.cookie
    // split into an Array of key=value strings
    .split(';')
    // split each, so we have an Array of entries ([key, value])
    .map((str: string) => str.split('=').map((s: string) => s.trim()) as [string, string]);

  return new Map(cookieEntries);
}

type SetDomainCookieOpts = {
  maxAge?: number;
  domain?: string;
};

/**
 * Sets a cookie for a specific domain.
 *
 * Useful for e.g. setting a cookie explicitly for a main domain instead of a
 * subdomain (e.g. virginexperiencedays.co.uk instead of
 * myaccount.virginexperiencedays.co.uk) to share data.
 */
export function setDomainCookie(key: string, value: string, opts?: SetDomainCookieOpts) {
  const {
    maxAge = 2592000, // default 30 days
    domain,
  } = opts ?? {};
  let cookieString = `${key}=${value}; max-age=${maxAge}; path=/`;
  if (domain) cookieString = `${cookieString}; domain=${domain}`;
  document.cookie = cookieString;
}
