// Purpose of this function is to satisfy requirements for different domains
// Currently we attach `/route` to current domain, to make links work on previews, but MA for example runs under different domain
// that then results with links pointing to a wrong destination.
// This function checks whether or not we are on sapling page (NextLink will exist) and if so, it appends the route to the current domain, otherwise it concatenates the route with home link provided via env variable
// Note that C# nor MA links are actually fine to be full urls as vast majority of header links are living on sapling already

import type { EnvVariables } from '../types';

// The only requirement here is to always run this function within the scope of globalState context it is to prevent prop drilling
export function generateRoute(
  href: string,
  options: { homeLink: EnvVariables['homeLink']; isNextLink?: boolean }
): string {
  // Don't populate leading slash for full urls (e.g. prismic links to web/media)
  if (href?.startsWith('http')) {
    return href;
  }

  const { homeLink, isNextLink = false } = options;

  const normalizedHomeLink = homeLink?.endsWith('/') ? homeLink : `${homeLink}/`;
  const normalizedHref = href?.startsWith('/') ? href.slice(1) : href;

  if (isNextLink) {
    return `/${normalizedHref}`;
  }

  return `${normalizedHomeLink}${normalizedHref}`;
}
