import type { SvgElementProps } from '../../../types';

const IconCoachSmall = ({
  width = 20,
  height = 20,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_779_23081)">
      <path
        d="M4.5 11.1852C9.10508 11.7012 10.6793 11.7078 15.3333 11.1852"
        stroke={themeColor || fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="4.23486"
        y="2.75"
        width="11.537"
        height="12.7222"
        rx="0.916667"
        stroke={themeColor || fill}
        strokeWidth="1.5"
      />
      <mask id="path-3-inside-1_779_23081" fill="white">
        <rect x="12.374" y="15.6296" width="2.96295" height="2.37036" rx="0.555556" />
      </mask>
      <rect
        x="12.374"
        y="15.6296"
        width="2.96295"
        height="2.37036"
        rx="0.555556"
        stroke={themeColor || fill}
        strokeWidth="2"
        mask="url(#path-3-inside-1_779_23081)"
      />
      <path
        d="M5.16992 16.1852C5.16992 16.1545 5.19479 16.1296 5.22548 16.1296H7.07732C7.108 16.1296 7.13287 16.1545 7.13287 16.1852V17.4444C7.13287 17.4751 7.108 17.5 7.07732 17.5H5.22548C5.19479 17.5 5.16992 17.4751 5.16992 17.4444V16.1852Z"
        stroke={themeColor || fill}
      />
      <path
        d="M16.522 4.37036H16.7461C17.0522 4.37036 17.34 4.51622 17.5211 4.7631V4.7631C17.642 4.92795 17.7072 5.12704 17.7072 5.33146V7.9259"
        stroke={themeColor || fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.48486 4.37036H3.26078C2.95463 4.37036 2.66679 4.51622 2.48575 4.7631V4.7631C2.36486 4.92795 2.29968 5.12704 2.29968 5.33146V7.9259"
        stroke={themeColor || fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.52197 13.8518H11.4849"
        stroke={themeColor || fill}
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <path
        d="M15.0996 13.7334L13.5185 14.1726C13.4389 14.1947 13.3734 14.251 13.3395 14.3263L12.9907 15.1012C12.9746 15.1371 12.9663 15.1759 12.9663 15.2152V15.8667"
        stroke={themeColor || fill}
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <path
        d="M4.75293 13.73L6.33408 14.1692C6.4136 14.1913 6.47918 14.2476 6.51305 14.3228L6.86179 15.0978C6.87792 15.1337 6.88626 15.1725 6.88626 15.2118V15.8633"
        stroke={themeColor || fill}
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_779_23081">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCoachSmall;
