import { useCallback, useEffect, useState } from 'react';

import type { EmblaCarouselProps } from '.';
import type { EmblaCarouselType, EmblaEventType } from 'embla-carousel';

type CarouselEventTrackingProps = {
  lastGesture: 'swipe' | null;
  lastIndex: number;
  isScrolling: boolean;
};

type UseBindingProps = {
  onSwipe?: EmblaCarouselProps['onSwipe'];
  totalSlides: number;
};

type HandleSwipeEventProps = UseBindingProps & {
  emblaApi: EmblaCarouselType;
  eventName: EmblaEventType;
  initialEvent: CarouselEventTrackingProps;
  event: CarouselEventTrackingProps;
  setEvent: (event: CarouselEventTrackingProps) => void;
};

export const useBindings = (
  emblaApi: EmblaCarouselType,
  { onSwipe, totalSlides }: UseBindingProps
) => {
  const initialEvent: CarouselEventTrackingProps = {
    lastGesture: null,
    lastIndex: totalSlides,
    isScrolling: false,
  };

  const [event, setEvent] = useState<CarouselEventTrackingProps>(initialEvent);

  const bindSwipeEvent = useCallback(
    (emblaApi: EmblaCarouselType, eventName: EmblaEventType) => {
      handleSwipeEvent({
        emblaApi,
        eventName,
        initialEvent,
        event,
        setEvent,
        onSwipe,
        totalSlides,
      });
    },
    [event, totalSlides]
  );

  useEffect(() => {
    if (!emblaApi) return;

    // slide in place, gets called on swipe, on nav click
    emblaApi.on('settle', bindSwipeEvent);
    // swipe start
    emblaApi.on('pointerDown', bindSwipeEvent);

    return () => {
      emblaApi.off('settle', bindSwipeEvent);
      emblaApi.off('pointerDown', bindSwipeEvent);
    };
  }, [emblaApi, bindSwipeEvent]);
};

const handleSwipeEvent = ({
  emblaApi,
  eventName,
  initialEvent,
  event,
  setEvent,
  onSwipe,
  totalSlides,
}: HandleSwipeEventProps) => {
  // since this event also gets called on nav click for desktop,
  // check last gesture to ensure it was a swipe to avoid duplicate tracking
  if (eventName === 'settle' && event.lastGesture === 'swipe') {
    const next = emblaApi.selectedScrollSnap();
    const lastSlide = totalSlides - 1;
    const dir =
      (event.lastIndex < next && !(event.lastIndex === 0 && next === lastSlide)) ||
      (event.lastIndex === lastSlide && next === 0)
        ? 'next'
        : 'prev';

    // trigger callback (e.g. tracking) only if user is able to do a full image swipe to the next/prev one
    if (event.lastIndex !== next) {
      onSwipe?.({
        currentIndex: event.lastIndex,
        selectedIndex: next,
        totalSlides,
        dir,
        gesture: 'swipe',
      });
    }

    // flag end of swipe
    setEvent(initialEvent);
  }

  if (event.isScrolling) return;

  if (eventName === 'pointerDown') {
    // flag start of swipe
    setEvent({
      lastIndex: emblaApi.selectedScrollSnap(),
      lastGesture: 'swipe',
      isScrolling: true,
    });
  }
};
