import { getLocationInfo, getPriceInfo } from '../productInfo';
import { roundPrice } from '../roundPrice';

import { ProductCardType, ProductMapType } from './types';

/**
 * Takes in a productMap object and formats its entries to an array of ProductCardType objects.
 *
 * @param {Record<string, any>} productMap - The productMap object containing product data.
 * @returns {ProductCardType[]} An array of ProductCardType objects, where each object contains information about a single product card.
 */
export const formatTopProductsToCardsArray = (
  productMap: Record<string, ProductMapType>
): ProductCardType[] => {
  const productsEntries = Object.entries(productMap);

  const formattedCards = productsEntries.map(([key, data]) => {
    const {
      id,
      media,
      title: cardTitle,
      productPromo,
      isCollectionProduct,
      isBlackFridayProduct,
      isCyberMondayProduct,
      isChristmasProduct,
      isEasterProduct,
      isMothersDayProduct,
      isFathersDayProduct,
      isValentinesProduct,
      reviews,
      isNew,
      isExclusive,
      categories,
      promocode,
      sku,
    } = data;
    const { currentPrice, pastPrice, badge } = getPriceInfo(data) ?? {};

    return {
      id,
      badge,
      // used by variation A (old product card)
      cardImage: media?.mainImage?.url ?? null,
      // used by variation B (product card 2024)
      cardImages: [media?.mainImage, ...(media?.images || [])].map((img) => ({
        src: img?.url || '',
        alt: img?.alt || cardTitle,
      })),
      cardFullSku: promocode ? `${sku} ${promocode}` : sku,
      cardRoute: isCollectionProduct ? `/collection/${key}` : `/product/${key}`,
      cardTitle,
      categories, // categories for location text override for product card 2024
      reviews,
      locations: getLocationInfo(data),
      currentPrice: typeof currentPrice === 'number' ? roundPrice(currentPrice) : null,
      pastPrice: typeof pastPrice === 'number' ? roundPrice(pastPrice) : null,
      percentOff: data?.price?.percentOff ?? null,
      productPromo,
      isCollectionProduct,
      isBlackFridayProduct,
      isCyberMondayProduct,
      isChristmasProduct,
      isEasterProduct,
      isMothersDayProduct,
      isFathersDayProduct,
      isValentinesProduct,
      isNew,
      isExclusive,
    };
  });

  return formattedCards;
};
