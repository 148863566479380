import {
  createContext,
  useState,
  useMemo,
  useContext,
  useRef,
  type MutableRefObject,
  type ReactNode,
} from 'react';

/**
 * This context is used to manage the state of the mega nav desktop menu and
 * its focused items at each of the 3 levels. This primarily supports accessibility
 * and allows for UI updates based on the focused item(s).
 *
 * The `handleClose` method is used to reset the state of the menu and focused items -
 * for example when the `Escape` key is pressed.
 */

type Name = string | null;
type HoverListRef = MutableRefObject<HTMLDivElement | null>;

type ContextType = {
  lvl1: Name;
  lvl2: Name;
  handleLvl1: (name: Name, ref?: HoverListRef) => void;
  handleLvl2: (name: Name) => void;
  handleClose: () => void;
};

const MenuContext = createContext<ContextType>({
  lvl1: null,
  lvl2: null,
  handleLvl1: () => null,
  handleLvl2: () => null,
  handleClose: () => null,
});

const MenuContextProvider = ({ children }: { children: ReactNode }) => {
  const lvl1Ref = useRef<HTMLDivElement | null>(null);

  const [lvl1, setLvl1] = useState<Name>(null);
  const [lvl2, setLvl2] = useState<Name>(null);

  const handleClose = () => {
    setLvl1(null);
    setLvl2(null);
    const activeElement = document.activeElement as HTMLElement;
    activeElement?.blur();
  };

  const handleLvl1Name = (name: Name) => {
    setLvl1(name);
  };

  const handleLvl1Ref = (name: boolean, ref?: HoverListRef) => {
    const srClass = 'sr-only';

    if (!name) {
      if (ref?.current) {
        ref?.current?.classList.add(srClass);
        lvl1Ref.current = null;
      } else lvl1Ref.current?.classList.add(srClass);

      return;
    }

    if (ref?.current) {
      ref?.current?.classList.remove(srClass);
      lvl1Ref.current?.classList.remove(srClass);
    } else lvl1Ref.current?.classList.remove(srClass);
  };

  const handleLvl1 = (name: Name, ref?: HoverListRef) => {
    handleLvl1Name(name);
    handleLvl1Ref(!!name, ref);
  };

  const value = useMemo(
    () => ({
      lvl1,
      lvl2,
      handleLvl1,
      handleLvl2: (name: Name) => setLvl2(name ?? null),
      handleClose,
    }),
    [lvl1, lvl2]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenuContext = () => useContext(MenuContext);
export default MenuContextProvider;
