const IconEllipse = ({ width = '12px', height = '12px', fill = 'none', ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill={fill}
    {...rest}
  >
    <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2" />
  </svg>
);

export default IconEllipse;
