import type { SvgElementProps } from '../../../types';

const IconCocktailSmall = ({
  width = 16,
  height = 18,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 11L14.7002 2.6247C15.2241 1.96993 14.7579 1 13.9194 1H2.08062C1.24212 1 0.775946 1.96993 1.29976 2.6247L8 11ZM8 11V17M3.2 5H12.8M3 17H13"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCocktailSmall;
