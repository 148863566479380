import { VariantProps, cva } from 'class-variance-authority';

import { LinkWrap, LinkWrapProps } from '../LinkWrap';
import { cn } from '@virginexperiencedays/tailwind';
import { FormEvent, MouseEvent } from 'react';

const linkButtonVariants = cva(
  'text-center no-underline align-middle select-none transition-colors font-inter font-semibold border-2 border-transparent rounded cursor-pointer focus:border-tones-info-500',
  {
    variants: {
      size: {
        small: 'text-xs leading-none py-2 px-4',
        regular: 'text-sm leading-none py-3 px-6',
        large: 'text-base leading-none py-3.5 px-10',
        xl: 'text-base leading-normal py-3.5 px-10',
      },
      variant: {
        default:
          'text-neutral-strong bg-background-primary-faded hover:bg-background-primary-faded-hover focus:bg-background-primary-faded',
        primary:
          'text-white bg-background-primary hover:bg-primary-600 focus:bg-background-primary',
        reverse: 'text-neutral-strong bg-white shadow-sm hover:bg-neutral-faded focus:bg-white',
        invisible:
          'text-neutral-strong bg-white hover:bg-background-primary focus:bg-background-primary',
        outline:
          'bg-transparent border border-neutral-strong text-neutral-strong hover:bg-background-primary hover:border-background-primary hover:text-white focus:bg-transparent focus:border-neutral-strong focus:text-neutral-strong',
      },
      defaultVariants: {
        size: 'regular',
        variant: 'default',
      },
    },
  }
);

export type LinkButtonProps = {
  href: string;
  dataTestId?: string;
  variant?: VariantProps<typeof linkButtonVariants>['variant'];
  size?: VariantProps<typeof linkButtonVariants>['size'];
  fullWidth?: boolean;
  onClick?: (e?: MouseEvent | FormEvent) => void;
  className?: string;
} & LinkWrapProps;

export const LinkButton = ({
  className,
  href,
  children,
  onClick,
  fullWidth,
  variant = 'default',
  size = 'regular',
  ...rest
}: LinkButtonProps) => (
  <LinkWrap
    className={cn(linkButtonVariants({ variant, size }), fullWidth && 'w-full', className)}
    href={href}
    onClick={onClick}
    {...rest}
  >
    {children}
  </LinkWrap>
);
