import type { FormEvent } from 'react';

import { SingleChoice } from './form/choice/SingleChoice';
import { MultipleChoice } from './form/choice/MultipleChoice';
import { RangeSlider } from './form/Range';

import { useGiftFinderContext } from '../context';

import type {
  GiftFinderFormComponent,
  GiftFinderMultiChoiceComponent,
  GiftFinderRangeComponent,
  QuizStep,
} from '../types';

type Props = {
  name: QuizStep['uuid'];
  componentProps: GiftFinderFormComponent | GiftFinderRangeComponent;
  options?: QuizStep['options'];
  handleAnswer: (e: FormEvent) => void;
};

export const GiftFinderQuizComponent = ({ name, componentProps, options, handleAnswer }: Props) => {
  const {
    state: { answers },
    handleTrackSwipable,
  } = useGiftFinderContext();
  const sharedProps = { name, answers, handleAnswer };

  switch (componentProps.type) {
    case 'single':
      if (!options?.length) return null;
      return <SingleChoice {...sharedProps} options={options} />;
    case 'multiple': {
      const { maxSelections } = componentProps as GiftFinderMultiChoiceComponent;
      return <MultipleChoice {...sharedProps} options={options} maxSelections={maxSelections} />;
    }
    case 'range': {
      const {
        stepValues,
        fromValue: from,
        toValue: to,
        ...props
      } = componentProps as GiftFinderRangeComponent;
      const fromValue = handleRangeValue(answers?.[`${name}.from`], from, stepValues);
      const toValue = handleRangeValue(answers?.[`${name}.to`], to, stepValues);
      return (
        <RangeSlider
          {...sharedProps}
          {...props}
          stepValues={stepValues}
          fromValue={fromValue}
          toValue={toValue}
          // while dragging
          onTouchStart={() => handleTrackSwipable(false)}
          // when dragging finished
          onTouchEnd={() => handleTrackSwipable(true)}
        />
      );
    }
    default:
      return null;
  }
};

/**
 * Returns the index of the first element in the `stepValues` array that matches the first element in the `answers` array, or `defaultValue` if `stepValues` is empty or no match is found.
 * @param answers - An array of strings representing the user's answers.
 * @param defaultValue - The default value to return if no match is found.
 * @param stepValues - An optional array of numbers representing the possible placeholder values for the range input.
 * @returns The index of the first matching element in `stepValues`, or `defaultValue` if no match is found.
 */
function handleRangeValue(
  answers: string[],
  defaultValue: number,
  stepValues: number[] = []
): number {
  if (!stepValues?.length) return defaultValue;

  const index = stepValues.findIndex((stepValue) => stepValue === Number(answers?.[0]));
  return index > -1 ? index : defaultValue;
}
