import { roundPrice } from './roundPrice';

export const calculateSavingsPercentage = (
  currentPrice?: number | string | null,
  pastPrice?: number | string | null
): number => {
  if (!currentPrice || !pastPrice) return 0;

  // if currentPrice is a string remove any non-numeric characters
  const currentPriceNumber =
    typeof currentPrice === 'string'
      ? Number(currentPrice.replace(/[^0-9.-]+/g, ''))
      : currentPrice;

  // if pastPrice is a string remove any non-numeric characters
  const pastPriceNumber =
    typeof pastPrice === 'string' ? Number(pastPrice.replace(/[^0-9.-]+/g, '')) : pastPrice;

  return Math.floor(((pastPriceNumber - currentPriceNumber) / pastPriceNumber) * 100);
};

export const handlePriceFormat = (
  price?: string | number | null,
  formatNumberFunc?: (n: number) => string
): string | null => {
  if (!price || !formatNumberFunc) return null;

  if (typeof price === 'string') return price;

  if (typeof price === 'number' && price > 0) {
    return formatNumberFunc?.(price) ?? null;
  }

  return null;
};

export const getProductCardPrices = ({
  displayPrice,
  rrp: _rrp,
  percentOff,
  formatNumber,
}: {
  displayPrice?: string | number;
  rrp?: string | number;
  percentOff?: string | number;
  formatNumber?: boolean; // return as number (e.g. 23.00 instead of £23)
}) => {
  const rrp = displayPrice !== _rrp ? _rrp : null;

  const currentPrice = handlePriceFormat(displayPrice, roundPrice);
  const pastPrice = handlePriceFormat(rrp, roundPrice);
  const roundedPercentOff =
    typeof percentOff === 'number'
      ? Math.round(percentOff)
      : calculateSavingsPercentage(displayPrice, rrp);

  if (formatNumber) {
    return {
      currentPrice: getPriceNumber(currentPrice) || 0,
      pastPrice: getPriceNumber(pastPrice) || getPriceNumber(currentPrice),
      roundedPercentOff: getPriceNumber(roundedPercentOff) || null,
    };
  }

  return { currentPrice, pastPrice, roundedPercentOff };
};

export const getPriceNumber = (price?: string | number | null): number => {
  const priceNumber = typeof price === 'string' ? price.replace(',', '').replace('£', '') : price;
  return Number(priceNumber);
};
