import type { SvgElementProps } from '../../../types';

const IconMobilePaw = ({
  width = 32,
  height = 27,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.8226 16.9614L8.0996 16.7619L8.8226 16.9614C9.22577 15.4998 10.0973 14.2109 11.3035 13.2924C12.5098 12.3739 13.984 11.8765 15.5002 11.8765C17.0163 11.8765 18.4905 12.3739 19.6968 13.2924C20.903 14.2109 21.7745 15.4998 22.1777 16.9614L23.2425 20.8214C23.403 21.4031 23.4269 22.014 23.3125 22.6065C23.198 23.199 22.9483 23.757 22.5827 24.2371C22.2171 24.7172 21.7456 25.1064 21.2049 25.3743C20.7 25.6244 20.1475 25.7625 19.5853 25.7797C18.1277 25.2838 16.9055 25.0339 15.6208 25.0412C14.337 25.0485 13.0373 25.3127 11.4319 25.7802C10.8639 25.7653 10.3054 25.6269 9.79544 25.3743C9.25473 25.1064 8.7832 24.7172 8.41761 24.2371C8.05203 23.757 7.80228 23.199 7.68782 22.6065C7.57337 22.014 7.59731 21.4031 7.75778 20.8214L8.8226 16.9614ZM19.1911 25.3623L19.322 25.4137L19.4627 25.4143C20.0108 25.4163 20.5519 25.2907 21.043 25.0473C21.5333 24.8043 21.9604 24.4507 22.2905 24.0144C22.6233 23.5802 22.8506 23.0747 22.9544 22.5373C23.0582 21.9996 23.0356 21.4451 22.8882 20.9177C22.888 20.9172 22.8879 20.9168 22.8878 20.9163L21.8237 17.0591C21.4419 15.675 20.6165 14.4545 19.4743 13.5846C18.332 12.7148 16.9359 12.2438 15.5002 12.2438C14.0644 12.2438 12.6683 12.7148 11.526 13.5846C10.3838 14.4545 9.55841 15.675 9.1766 17.0591L8.11179 20.919C7.96635 21.4462 7.94465 21.9999 8.04838 22.5368C8.1521 23.0738 8.37844 23.5795 8.70976 24.0146C9.04108 24.4497 9.46842 24.8024 9.95846 25.0452C10.4485 25.288 10.988 25.4143 11.5349 25.4143L11.6659 25.4143L11.7892 25.3698C13.3083 24.8222 14.4238 24.5505 15.5078 24.5392C16.5852 24.5279 17.6934 24.7737 19.1911 25.3623Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
    <path
      d="M22.5463 5.809C22.2718 7.7621 20.7651 8.93959 19.3934 8.74681C18.0216 8.55402 16.8979 7.00685 17.1724 5.05375C17.4469 3.10064 18.9535 1.92316 20.3253 2.11594C21.697 2.30873 22.8208 3.8559 22.5463 5.809Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
    <path
      d="M28.587 13.1294C27.3265 14.6464 25.4258 14.8557 24.3604 13.9705C23.2949 13.0852 23.1526 11.1783 24.413 9.66131C25.6735 8.14432 27.5742 7.93497 28.6396 8.82022C29.705 9.70547 29.8474 11.6124 28.587 13.1294Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
    <path
      d="M14.0951 4.63819C14.6716 6.52434 13.8037 8.22821 12.479 8.63314C11.1542 9.03807 9.48197 8.1107 8.90542 6.22455C8.32887 4.3384 9.19684 2.63453 10.5216 2.2296C11.8463 1.82467 13.5185 2.75205 14.0951 4.63819Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
    <path
      d="M7.60486 9.99342C8.18141 11.8796 7.31343 13.5834 5.98872 13.9884C4.66401 14.3933 2.99173 13.4659 2.41518 11.5798C1.83864 9.69363 2.70661 7.98976 4.03132 7.58482C5.35603 7.17989 7.02831 8.10727 7.60486 9.99342Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
    />
  </svg>
);

export default IconMobilePaw;
