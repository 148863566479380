import { CSSProperties, useMemo } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { useGiftFinderContext } from '../context';

export const StepIndicator = () => {
  const {
    state: { quiz, step },
    handleSetStep,
    handleSetSkipScrollCallback,
  } = useGiftFinderContext();
  const total = quiz.length;

  const stepsArr = useMemo(() => {
    return Array.from({ length: total }).map((_, i) => i);
  }, [total]);

  const handleClick = (index: number) => {
    // required to skip the scroll callback to avoid double calls to handleSetStep that also set in the intersection callback of the card
    handleSetSkipScrollCallback(true);
    handleSetStep(index);
  };

  return (
    <div className="flex w-[230px] max-w-full">
      {stepsArr.map((n, i) => (
        <div
          key={n}
          // $active={step === i}
          onClick={() => handleClick(i)}
          className={cn(
            'bg-background-page relative ml-2 h-[var(--step-height)] w-full cursor-pointer overflow-hidden rounded-[var(--step-height)] first:ml-0',
            'after:bg-primary-700 after:z-1 after:absolute after:left-0 after:top-0 after:h-[var(--step-height)] after:w-full after:rounded-[var(--step-height)] after:opacity-0 after:transition-all after:duration-300',
            step === i && 'after:opacity-100'
          )}
          style={
            {
              '--step-height': '7px',
            } as CSSProperties
          }
        />
      ))}
    </div>
  );
};
