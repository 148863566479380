import type { SvgElementProps } from '../../../types';

const IconSparkle = ({
  width = 16,
  height = 16,
  fill = 'var(--text-neutral)',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.33333 2V4.66667V2ZM2 3.33333H4.66667H2ZM4 11.3333V14V11.3333ZM2.66667 12.6667H5.33333H2.66667ZM8.66667 2L10.1907 6.57133L14 8L10.1907 9.42867L8.66667 14L7.14267 9.42867L3.33333 8L7.14267 6.57133L8.66667 2Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSparkle;
