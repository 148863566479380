import flags from './flags';

export const parseCookie = (str) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

export const isEnable = (flag: string) => {
  // check for flag start with "FF_"
  if (!flag.startsWith('FF_')) {
    console.warn(`Flag ${flag} is not valid. Flag must start with "FF_"`);
    return false;
  }

  // check for URL and cookies only in browser environment
  if (typeof window !== 'undefined') {
    // check query string first
    const query = new URLSearchParams(window.location.search);
    if (query.has(flag)) {
      switch (query.get(flag)) {
        case '1': // save flag in cookie for 24h
          console.warn(`Flag ${flag} is enabled by query string`);
          document.cookie = `${flag}=1; max-age=86400; path=/`;
          return true;
        case '0': // set flag to disabled and save in cookie for 24h
          console.warn(`Flag ${flag} is disabled by query string`);
          document.cookie = `${flag}=0; max-age=86400; path=/`;
          return false;
        default:
          // @todo: future case for other values
          // - for cannary releasing flags to subsets of users
          // - 0.3 would roll out to 30% of users
          return false;
      }
    }
    if (typeof document.cookie !== 'undefined' && document.cookie.length > 0) {
      // check for cookie
      const cookie = parseCookie(document.cookie);
      if (cookie.hasOwnProperty(flag)) {
        switch (parseInt(cookie[flag])) {
          case 1: // save flag in cookie for 24h
            console.warn(`Flag ${flag} is enabled by cookie`);
            return true;
          default:
            console.warn(`Flag ${flag} is disabled by cookie`);
            // 0 or anything else will turn it off
            return false;
        }
      }
    }
  }

  // check for ENV variable
  if (flags[flag] && flags[flag] === 1) {
    return true;
  }

  // if flag is not set, return false
  return false;
};

export const isEnableServer = (flag: string) => {
  // check for flag start witj "FF_"
  if (!flag.startsWith('FF_')) {
    console.warn(`Flag ${flag} is not valid. Flag must start with "FF_"`);
    return false;
  }

  // check for ENV variable
  if (flags[flag] && flags[flag] === 1) {
    return true;
  }

  // if flag is not set, return false
  return false;
};

// feature toggle hook
export const useFeatureFlags = () => {
  return [isEnable];
};

export const useFeatureFlagsServer = () => {
  return [isEnableServer];
};

export default useFeatureFlags;
