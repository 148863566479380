import type { SvgElementProps } from '../../../types';

const IconMobileTicket = ({
  width = 32,
  height = 32,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M21.8782 8.12496C21.3582 7.60484 21.0538 6.90762 21.0257 6.17272C20.9977 5.43782 21.2481 4.71942 21.7269 4.16119C21.7665 4.11476 21.7871 4.05508 21.7846 3.99411C21.782 3.93315 21.7565 3.87539 21.7132 3.83244L18.955 1.07117C18.9094 1.0256 18.8476 1 18.7831 1C18.7187 1 18.6569 1.0256 18.6113 1.07117L14.215 5.46745C14.0528 5.62961 13.9306 5.82734 13.8581 6.04495V6.04495C13.7859 6.26306 13.6639 6.46135 13.5017 6.62408C13.3395 6.78681 13.1416 6.90952 12.9237 6.98245V6.98245C12.706 7.05501 12.5081 7.17719 12.3456 7.33933L1.07117 18.6113C1.0256 18.6569 1 18.7187 1 18.7831C1 18.8476 1.0256 18.9094 1.07117 18.955L3.82931 21.7132C3.87227 21.7565 3.93002 21.782 3.99099 21.7846C4.05196 21.7871 4.11164 21.7665 4.15806 21.7269C4.71617 21.2476 5.43473 20.9968 6.16988 21.0247C6.90504 21.0525 7.60253 21.3571 8.12274 21.8773C8.64295 22.3975 8.94747 23.095 8.97535 23.8301C9.00323 24.5653 8.7524 25.2838 8.27309 25.8419C8.23349 25.8884 8.2129 25.948 8.21545 26.009C8.218 26.07 8.2435 26.1277 8.28684 26.1707L11.045 28.9288C11.0906 28.9744 11.1524 29 11.2169 29C11.2813 29 11.3431 28.9744 11.3887 28.9288L22.6638 17.6544C22.8259 17.4919 22.9481 17.294 23.0207 17.0763V17.0763C23.0929 16.8582 23.2149 16.6599 23.3771 16.4971C23.5393 16.3344 23.7372 16.2117 23.9551 16.1388V16.1388C24.1727 16.0663 24.3704 15.9441 24.5326 15.7819L28.9288 11.3856C28.9744 11.34 29 11.2782 29 11.2137C29 11.1493 28.9744 11.0874 28.9288 11.0419L26.1707 8.28371C26.1277 8.24037 26.07 8.21487 26.009 8.21232C25.948 8.20977 25.8884 8.23036 25.8419 8.26996C25.2845 8.74957 24.5665 9.00108 23.8316 8.9742C23.0967 8.94731 22.3991 8.64402 21.8782 8.12496V8.12496Z"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.6559 7.77773L13.624 6.74585"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.4083 10.5292L16.7202 9.84167"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.1597 13.2808L19.4722 12.5927"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.2555 16.3756L22.2236 15.3438"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconMobileTicket;
