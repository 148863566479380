import { linkResolver } from './linkResolver';

/**
 * Rely on {@link linkResolver}, so we don't duplicate these cases several times
 * in our codebase.
 *
 * NOTE: we are in lib/cms, so ostensibly we should be agnostic to whatever
 * routing structure a consuming project will have. See the doc comment in
 * linkResolver for some justification for this, though.
 */
//@TODO:  link resolver should've refactored to implement string normalization before returning another string to prevent router errors, leaving as todo due to the fact that link resolver itself will be moved soon to header. It is to prevent conflicts
export function createLinkedPath(type: string, slug: string): string {
  return linkResolver({ type, uid: slug });
}
