import { CSSProperties, ReactNode, RefObject, useState } from 'react';

import { cn } from '../../../../utils';

import { Indicators } from './Indicators';
import { Item } from './Item';
import { sizeConfig } from './sizeConfig';

export type TrackProps = {
  className?: string;
  itemClassName?: string;
  children: ReactNode[];
  carouselRef: RefObject<Element>;
  lastChildRef: RefObject<Element>;
  firstChildRef: RefObject<Element>;
  perPage?: {
    mobile?: keyof typeof sizeConfig['mobile'];
    tablet?: keyof typeof sizeConfig['tablet'];
    desktop?: keyof typeof sizeConfig['desktop'];
    wide?: keyof typeof sizeConfig['wide'];
  };
  showPageIndicators?: boolean;
  isProductCarousel?: boolean;
  style?: CSSProperties;
};

const setSizeClasses = (size: TrackProps['perPage']) => {
  if (!size) return null;
  const { mobile = 'small', tablet = 2, desktop = 3, wide = 3 } = size;

  const mobileSize = sizeConfig.mobile[mobile];
  const tabletSize = sizeConfig.tablet[tablet];
  const desktopSize = sizeConfig.desktop[desktop];
  const widescreenSize = sizeConfig.wide[wide];

  return cn(mobileSize, tabletSize, desktopSize, widescreenSize);
};

export const Track = ({
  className = 'track',
  itemClassName,
  carouselRef,
  children,
  firstChildRef,
  lastChildRef,
  perPage = {
    mobile: 'small',
    tablet: 3,
    desktop: 4,
    wide: 5,
  },
  showPageIndicators,
  isProductCarousel,
  style,
}: TrackProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCurrentIndex = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <ul
        className={cn(
          'flex-1 snap-x snap-mandatory gap-4 overflow-x-auto overflow-y-hidden scroll-smooth px-4 pb-0.5 hide-scrollbar',
          '-mx-4 flex',
          'children:shrink-0 children:snap-start',
          'md:mx-0 md:scroll-p-0 lg:mx-auto',
          !itemClassName && 'md:first:ml-auto md:last:mr-auto',
          !itemClassName && setSizeClasses(perPage),
          className
        )}
        data-testid="carousel-track"
        ref={carouselRef as RefObject<HTMLUListElement>}
        style={style}
      >
        <ChildrenRenderer
          className={itemClassName}
          firstChildRef={firstChildRef}
          lastChildRef={lastChildRef}
          isProductCarousel={isProductCarousel}
          handleCurrentIndex={handleCurrentIndex}
          disabledObserver={!showPageIndicators}
        >
          {children}
        </ChildrenRenderer>
      </ul>
      {showPageIndicators && children?.length ? (
        <Indicators activeIndex={activeIndex} amountOfPages={children.length} />
      ) : null}
    </>
  );
};
type ChildrenRendererProps = {
  className?: string;
  children: ReactNode[];
  lastChildRef: RefObject<Element>;
  firstChildRef: RefObject<Element>;
  isProductCarousel?: boolean;
  handleCurrentIndex: (index: number) => void;
  disabledObserver: boolean;
};
export const ChildrenRenderer = ({
  className,
  children,
  firstChildRef,
  lastChildRef,
  isProductCarousel,
  handleCurrentIndex,
  disabledObserver,
}: ChildrenRendererProps) => (
  <>
    {Array.isArray(children)
      ? children.map((child, index) => {
          const childItemProps = {
            className,
            index,
            handleCurrentIndex,
            disabledObserver,
            isProductCarousel,
          };
          if (index === 0) {
            return (
              <Item
                key={`first-child-${index}`}
                data-testid="first-child"
                ref={firstChildRef}
                {...childItemProps}
              >
                {child}
              </Item>
            );
          }
          if (index === children.length - 1) {
            return (
              <Item
                key={`last-child-${index}`}
                data-testid="last-child"
                ref={lastChildRef}
                {...childItemProps}
              >
                {child}
              </Item>
            );
          }
          return (
            <Item key={`children-${index}`} data-testid="children" {...childItemProps}>
              {child}
            </Item>
          );
        })
      : children}
  </>
);
