import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import { linkResolver } from '@virginexperiencedays/header/src/utils/cms/linkResolver';

// re-export linkResolver
// as quite a few pieces look to this file for Prismic config
export { linkResolver };

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export const createClient = (config = {}) => {
  const PRISMIC_REPOSITORY_NAME = process.env.PRISMIC_REPOSITORY_NAME;

  if (!PRISMIC_REPOSITORY_NAME)
    throw new Error("Config - Missing required 'PRISMIC_REPOSITORY_NAME' environment variable");

  const endpoint = prismic.getEndpoint(PRISMIC_REPOSITORY_NAME);
  const client = prismic.createClient(endpoint, config);

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
