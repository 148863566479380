import { HTMLAttributes } from 'react';

import { cn } from '../../utils';

const Container = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'w-full px-4 md:px-10 xl:px-20 [@media(min-width:1440px)]:mx-auto [@media(min-width:1440px)]:max-w-[1440px]',
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export default Container;
