import type { SvgElementProps } from '../../../types';

const IconFootballSmall = ({
  width = 18,
  height = 18,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.00049 5.63147L5.83008 8.29105L6.66716 11.6669H9.00049H11.3338L12.1709 8.29105L9.00049 5.63147Z"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1714 8.29101L14.3743 7.35768L15.4485 4.30518"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.958 9.58225L14.373 7.35767"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82982 8.29101L3.6269 7.35768L2.55273 4.30518"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.04199 9.58225L3.62699 7.35767"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5.63147V3.23272L11.6667 1.45605"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3335 1.45557L9.00016 3.23265"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.333 11.6667L12.4997 13.6667L11.333 16.6251"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4472 13.6667H12.583"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 11.6667L5.5 13.6667L6.68208 16.6459"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.56787 13.6667H5.49995"
      stroke={themeColor || fill}
      strokeWidth="1.41667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconFootballSmall;
