import type { SvgElementProps } from '../../../types';

const IconCompassSmall = ({
  width = 20,
  height = 20,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76788 13.1316L8.41893 8.41874L11.4808 11.4806L6.76788 13.1316Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M13.1315 6.76777L11.4805 11.4807L8.41863 8.41882L13.1315 6.76777Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCompassSmall;
