import React from 'react';

import { Client } from 'urql';
import { createUrqlClient } from '../utils/graphql';
import { linkResolver } from '../prismicio';

// Providers
import { GiftFinderContextProvider } from '@virginexperiencedays/components-v2/src/drawers/GiftFinder/context/GiftFinderContext';
import { PrismicProvider } from '@prismicio/react';
import { Provider as GraphQLProvider } from '../utils/graphql';
import { TrackingContextProvider } from './tracking/Context';

/**
 * This component is to share context from _app with SSR algolia hooks, so we can have access to theme and other needs, making sure that's a singleton
 */
let urql: Client;

export const PageContext = ({ children }) => {
  urql = urql ?? createUrqlClient();

  return (
    <PrismicProvider linkResolver={linkResolver}>
      <TrackingContextProvider>
        <GraphQLProvider value={urql}>
          <GiftFinderContextProvider>{children}</GiftFinderContextProvider>
        </GraphQLProvider>
      </TrackingContextProvider>
    </PrismicProvider>
  );
};
