import { useEffect, useState, type RefObject } from 'react';

type UseStickyElementProps = {
  stickyRef: RefObject<HTMLElement>;
};

type UseStickyElementData = {
  isSticky: boolean;
};

/**
 * This is meant to be used with position: sticky
 * Returns "isSticky" JS var that tracks whether a sticky div is already "sticking" at the top
 */
export const useStickyElement = ({ stickyRef }: UseStickyElementProps): UseStickyElementData => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const node = stickyRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    observer.observe(node);

    return () => {
      if (!hasIOSupport || !node) return;
      observer.unobserve(node);
    };
  }, []);

  return { isSticky };
};
