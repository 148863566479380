import { ComponentPropsWithoutRef } from 'react';

interface IconStarProps extends ComponentPropsWithoutRef<'svg'> {
  type?: 'full' | 'half' | 'empty';
  shadedColor?: string;
  emptyColor?: string;
}

const IconStar = ({
  type = 'full',
  shadedColor = '#FED141',
  emptyColor = '#D1D5DB',
  className,
}: IconStarProps) =>
  type === 'half' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      aria-label="Half Star"
      data-testid="half-star"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.78899C7.6796 1.7891 7.35916 1.9733 7.2392 2.34159L7.24 2.34239L6.384 4.97599C6.33166 5.13651 6.22988 5.27636 6.09322 5.37551C5.95656 5.47466 5.79204 5.52803 5.6232 5.52799H2.8544C2.08 5.52799 1.7576 6.51999 2.384 6.97599L4.6232 8.60239C4.75998 8.70161 4.86182 8.8416 4.91413 9.00227C4.96645 9.16295 4.96654 9.33606 4.9144 9.49679L4.0584 12.1304C3.8192 12.8672 4.6624 13.4808 5.2896 13.0248L7.5296 11.3976C7.66624 11.2984 7.83075 11.245 7.9996 11.245C7.99973 11.245 7.99987 11.245 8 11.245V1.78899Z"
        fill={shadedColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.792C8.3204 1.79212 8.64084 1.97632 8.7608 2.3446L8.76 2.3454L9.616 4.979C9.66834 5.13952 9.77012 5.27937 9.90678 5.37852C10.0434 5.47767 10.208 5.53104 10.3768 5.531H13.1456C13.92 5.531 14.2424 6.523 13.616 6.979L11.3768 8.6054C11.24 8.70462 11.1382 8.84461 11.0859 9.00528C11.0336 9.16596 11.0335 9.33907 11.0856 9.4998L11.9416 12.1334C12.1808 12.8702 11.3376 13.4838 10.7104 13.0278L8.4704 11.4006C8.33376 11.3014 8.16925 11.248 8.0004 11.248C8.00027 11.248 8.00013 11.248 8 11.248V1.792Z"
        fill={emptyColor}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      aria-label={type === 'empty' ? 'Empty Star' : 'Filled Star'}
      data-testid={type === 'empty' ? 'empty-star' : 'star'}
    >
      <path
        d="M7.2392 2.8416C7.4792 2.1048 8.5216 2.1048 8.7608 2.8416L9.6168 5.4752C9.6691 5.63559 9.77076 5.77535 9.90726 5.87449C10.0438 5.97362 10.2081 6.02708 10.3768 6.0272H13.1464C13.9216 6.0272 14.2432 7.0192 13.6168 7.4752L11.3768 9.1024C11.24 9.20163 11.1382 9.34161 11.0859 9.50228C11.0336 9.66296 11.0335 9.83607 11.0856 9.9968L11.9416 12.6304C12.1816 13.3672 11.3376 13.9808 10.7096 13.5248L8.4696 11.8976C8.33297 11.7984 8.16845 11.745 7.9996 11.745C7.83075 11.745 7.66624 11.7984 7.5296 11.8976L5.2896 13.5248C4.6624 13.9808 3.8192 13.3672 4.0584 12.6304L4.9144 9.9968C4.96654 9.83607 4.96645 9.66296 4.91413 9.50228C4.86182 9.34161 4.75998 9.20163 4.6232 9.1024L2.384 7.476C1.7576 7.02 2.08 6.028 2.8544 6.028H5.6232C5.79204 6.02805 5.95656 5.97467 6.09322 5.87552C6.22988 5.77637 6.33166 5.63652 6.384 5.476L7.24 2.8424L7.2392 2.8416Z"
        fill={type === 'empty' ? emptyColor : shadedColor}
      />
    </svg>
  );

export default IconStar;
