import { useHeaderContext } from '../context/HeaderContext';

type Options = {
  isBlackFridayProduct?: boolean;
  isChristmasProduct?: boolean;
  isEasterProduct?: boolean;
  isMothersDayProduct?: boolean;
  isFathersDayProduct?: boolean;
  isValentinesProduct?: boolean;
};

type Promos = {
  isBlackFriday: boolean;
  isCyberMonday: boolean;
  isChristmas: boolean;
  isEaster: boolean;
  isMothersDay: boolean;
  isFathersDay: boolean;
  isValentines: boolean;
};

const getPromoFlag = (promo: unknown): number =>
  typeof promo === 'string' ? parseInt(promo ?? '0') : 0;

export const enabledPromotions = ({
  isBlackFridayProduct,
  isChristmasProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
}: Options): Promos => {
  const {
    isBlackFriday,
    isChristmas,
    isCyberMonday,
    isEaster,
    isMothersDay,
    isFathersDay,
    isValentinesDay,
  } = useHeaderContext();

  const blackFriday = getPromoFlag(isBlackFriday);
  const cyberMonday = getPromoFlag(isCyberMonday);
  const christmas = getPromoFlag(isChristmas);
  const easter = getPromoFlag(isEaster);
  const mothersDay = getPromoFlag(isMothersDay);
  const fathersDay = getPromoFlag(isFathersDay);
  const valentines = getPromoFlag(isValentinesDay);

  return {
    isBlackFriday: (isBlackFridayProduct && !!blackFriday) ?? false,
    isCyberMonday: (isBlackFridayProduct && !!cyberMonday) ?? false,
    isChristmas: (isChristmasProduct && !!christmas) ?? false,
    isEaster: (isEasterProduct && !!easter) ?? false,
    isMothersDay: (isMothersDayProduct && !!mothersDay) ?? false,
    isFathersDay: (isFathersDayProduct && !!fathersDay) ?? false,
    isValentines: (isValentinesProduct && !!valentines) ?? false,
  };
};
