import type { SvgElementProps } from '../../../types';

const IconTentSmall = ({
  width = 16,
  height = 14,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.53012 0.391002C7.33178 0.027366 6.8762 -0.106627 6.51256 0.0917205C6.14893 0.290068 6.01493 0.745646 6.21328 1.10928L7.13045 2.79075L1.97433 12.2436H0.75C0.335786 12.2436 0 12.5794 0 12.9936C0 13.4079 0.335786 13.7436 0.75 13.7436H2.40432C2.41404 13.7438 2.42376 13.7438 2.43346 13.7436H13.5384C13.5465 13.7438 13.5547 13.7438 13.5629 13.7436H15.2196C15.6338 13.7436 15.9696 13.4079 15.9696 12.9936C15.9696 12.5794 15.6338 12.2436 15.2196 12.2436H13.9952L8.83908 2.79075L9.75624 1.1093C9.95458 0.745664 9.82059 0.290087 9.45695 0.0917398C9.09332 -0.106607 8.63774 0.0273862 8.43939 0.391022L7.98476 1.22451L7.53012 0.391002ZM5.75879 12.2436H3.68296L7.98477 4.357L12.2866 12.2436H10.211V12.2422V11.5161C10.211 10.2867 9.21431 9.29 7.98488 9.29C6.75544 9.29 5.75879 10.2867 5.75879 11.5161V12.2422V12.2436ZM8.71096 11.5161V12.2422H7.25879V11.5161C7.25879 11.1151 7.58387 10.79 7.98488 10.79C8.38588 10.79 8.71096 11.1151 8.71096 11.5161Z"
      fill={themeColor || fill}
    />
  </svg>
);

export default IconTentSmall;
