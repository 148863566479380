import { cn } from '../../../utils';

import type { HTMLAttributes } from 'react';

type Props = {
  noPadding?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ListContainer = ({ noPadding, className, children }: Props) => (
  <div className={cn(style.container, className)}>
    <div className={cn(style.inner._, { [style.inner.noPadding]: noPadding })}>{children}</div>
  </div>
);

export default ListContainer;

export const MIN_LIST_WIDTH = 'min-w-[240px] xl:min-w-[230px]';

const style = {
  container: 'pt-2',
  inner: {
    _: `rounded-md border border-solid border-border-neutral bg-background-page p-4 shadow-lg ${MIN_LIST_WIDTH}`,
    noPadding: 'p-0',
  },
};
