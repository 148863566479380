import { useEffect, useRef, useState } from 'react';

export type UseCopyToClipBoard = {
  success: boolean;
  supported: boolean;
  error?: string | null;
  handleCopy: (text: string) => Promise<void>;
};

const COPY_TIMEOUT_DURATION = 2000;

/**
 * Custom hook for copying text to the clipboard.
 *
 * @returns An object containing the success status, clipboard support status, error message, and the handleCopy function.
 */
export const useCopyToClipboard = (duration = COPY_TIMEOUT_DURATION): UseCopyToClipBoard => {
  const copyTimeout = useRef<number | null>(null);

  const [supported, setSupported] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  /**
   * Handles copying the provided text to the clipboard.
   *
   * @param text - The text to be copied to the clipboard.
   * @returns A promise that resolves when the text is successfully copied.
   */
  const handleCopy = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccess(true);

      if (copyTimeout.current) clearTimeout(copyTimeout.current);

      copyTimeout.current = window.setTimeout(() => {
        clearTimeout(copyTimeout.current);
        setSuccess(false);
      }, duration);
    } catch (error) {
      const msg = error instanceof Error ? error.message : 'Error copying to clipboard';
      console.error(msg);
      setError(msg);
      setSuccess(false);
    }
  };

  // Check for clipboard support
  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!navigator?.clipboard) setSupported(false);
  }, []);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (copyTimeout.current) {
        clearTimeout(copyTimeout.current);
      }
    };
  }, []);

  return { success, supported, error, handleCopy };
};
