export enum TestId {
  // header
  Title = 'header-title',
  // desktop nav
  OverflowMenu = 'mega-nav-overflow-menu',
  // mobile nav
  MobileNavigation = 'mega-nav-mobile-navigation',
  TopHeader = 'mega-nav-mobile-top-header',
  PrimaryNavList = 'mega-nav-mobile-primary-list',
  SecondaryNavList = 'mega-nav-mobile-secondary-list',
  ImageNavList = 'mega-nav-mobile-image-list',
  IconNavList = 'mega-nav-mobile-icon-list',
  VoucherLink = 'mega-nav-mobile-voucher-cta',
  MainCtaLink = 'mega-nav-mobile-main-cta',
  MainUsp = 'mega-nav-mobile-main-usp',
}
