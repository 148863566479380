import type { SvgElementProps } from '../../../types';

const IconSearch = ({
  width = 15,
  height = 15,
  fill = 'var(--text-neutral-strong)',
  themeColor,
  strokeWidth = '1.8',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M16.5007 16.5007L12.1698 12.1698M12.1698 12.1698C13.342 10.9977 14.0005 9.4079 14.0005 7.75023C14.0005 6.09257 13.342 4.5028 12.1698 3.33065C10.9977 2.1585 9.4079 1.5 7.75023 1.5C6.09257 1.5 4.5028 2.1585 3.33065 3.33065C2.15851 4.5028 1.5 6.09257 1.5 7.75023C1.5 9.4079 2.1585 10.9977 3.33065 12.1698C4.5028 13.342 6.09257 14.0005 7.75023 14.0005C9.4079 14.0005 10.9977 13.342 12.1698 12.1698Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSearch;
