import { CookieKeys, LocalStorageKeys } from '../hooks/useValidateProducts';

import { setDomainCookie } from './cookie';
import { getViewedProducts } from './getViewedProducts';

export const formatFooterRecentlyViewed = ({
  products,
  loading,
  setStorageItem,
  setProducts,
  cookieDomain,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const maxItems = 20;
  const recentlyViewedProductCards = !loading
    ? getViewedProducts(Date.now(), products, maxItems)
    : [];

  const clearHistory = () => {
    setProducts([]);
    setStorageItem(LocalStorageKeys.RECENTLY_VIEWED_PRODUCTS, []);
    setDomainCookie(CookieKeys.RECENTLY_VIEWED_PRODUCTS, '[]', {
      domain: cookieDomain,
    });
  };

  const recentlyViewedProductsMapped = {
    dataTestId: 'recently-viewed-carousel',
    title: 'Recently Viewed',
    showCta: true,
    ctaLabel: 'Clear All',
    ctaLink: null,
    onClick: clearHistory,
    column: 'left',
    cards: recentlyViewedProductCards ?? [],
  };

  return recentlyViewedProductsMapped;
};
