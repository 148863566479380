import { useEffect, useState } from 'react';

export const useServerToClientDate = (
  date: string | Date,
  opts: Intl.DateTimeFormatOptions = {}
): string => {
  /**
   * Initialize formattedDateCreated always in a single timezone (Europe/London
   * IANA timezone, since that's where most of our users are, to be safe), so
   * server and client don't get mismatches in HTML content even if both are in
   * different timezones.
   */
  const londonDateCreated = new Date(date).toLocaleDateString('en-GB', {
    timeZone: 'Europe/London',
    ...opts,
  });

  const [formattedDateCreated, setFormattedDateCreated] = useState(londonDateCreated);

  /**
   * Run a client-side update to formattedDateCreated to match local time. This
   * is done in a useEffect, so it occurs *after* hydration -- no server-client
   * mismatches should occur.
   */
  useEffect(() => {
    const localDateCreated = new Date(date).toLocaleDateString('en-GB', opts);
    if (localDateCreated !== formattedDateCreated) {
      setFormattedDateCreated(localDateCreated);
    }
  }, [date]);

  return formattedDateCreated;
};
