import { useHeaderContext } from '../../../context/HeaderContext';
import { useTheme } from '../../../context/ThemeContext';
import Link from '../../common/Link';
import Logo from '../../common/ui/Logo';
import { SearchBar } from '../SearchBar';

type Props = {
  logoSrc: { red: string; white: string };
  handleLinkClicks: () => void;
};

const SearchControlsWrapper = ({ logoSrc, handleLinkClicks }: Props) => {
  const { immersive } = useTheme();
  const { handleToggleSearchOpen, homeLink } = useHeaderContext();

  return (
    <div className="flex w-full items-end justify-between pt-2 lg:pt-0">
      <Link
        href={homeLink}
        onClick={handleLinkClicks}
        className="hidden h-10 w-[100px] items-center justify-center overflow-hidden lg:m-0 lg:flex"
      >
        <Logo src={logoSrc} enforceRed={immersive} />
      </Link>
      <div className="mx-auto inline-flex grow items-center lg:min-w-[400px] lg:max-w-[650px] xl:translate-x-8">
        <SearchBar id="searchField" />
        <button
          className="ml-2 cursor-pointer border-0 bg-transparent py-4 text-sm font-normal leading-none text-neutral-faded underline"
          onClick={handleToggleSearchOpen}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SearchControlsWrapper;
