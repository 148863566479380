import type { SvgElementProps } from '../../../types';

const IconTicketSmall = ({
  width = 18,
  height = 18,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.9304 5.07141C12.6333 4.7742 12.4593 4.37578 12.4433 3.95584C12.4273 3.53589 12.5704 3.12538 12.844 2.80639C12.8666 2.77986 12.8783 2.74576 12.8769 2.71092C12.8754 2.67608 12.8609 2.64308 12.8361 2.61853L11.26 1.04067C11.234 1.01463 11.1986 1 11.1618 1C11.125 1 11.0896 1.01463 11.0636 1.04067L8.55143 3.55283C8.45874 3.64549 8.38891 3.75848 8.3475 3.88283V3.88283C8.30623 4.00746 8.2365 4.12077 8.14382 4.21376C8.05114 4.30675 7.93806 4.37687 7.81356 4.41855V4.41855C7.68913 4.46001 7.57604 4.52982 7.48321 4.62248L1.04067 11.0636C1.01463 11.0896 1 11.125 1 11.1618C1 11.1986 1.01463 11.234 1.04067 11.26L2.61675 12.8361C2.6413 12.8609 2.6743 12.8754 2.70914 12.8769C2.74398 12.8783 2.77808 12.8666 2.80461 12.844C3.12353 12.5701 3.53413 12.4267 3.95422 12.4427C4.37431 12.4586 4.77288 12.6326 5.07014 12.9299C5.3674 13.2271 5.54141 13.6257 5.55734 14.0458C5.57327 14.4659 5.42994 14.8765 5.15605 15.1954C5.13342 15.2219 5.12166 15.256 5.12311 15.2909C5.12457 15.3257 5.13914 15.3587 5.16391 15.3833L6.73999 16.9593C6.76604 16.9854 6.80137 17 6.8382 17C6.87504 17 6.91036 16.9854 6.93642 16.9593L13.3793 10.5168C13.472 10.424 13.5418 10.3109 13.5832 10.1864V10.1864C13.6245 10.0618 13.6942 9.94849 13.7869 9.8555C13.8796 9.76251 13.9927 9.6924 14.1172 9.65072V9.65072C14.2415 9.6093 14.3545 9.53948 14.4472 9.44679L16.9593 6.93463C16.9854 6.90858 17 6.87325 17 6.83642C17 6.79958 16.9854 6.76425 16.9593 6.7382L15.3833 5.16212C15.3587 5.13736 15.3257 5.12278 15.2909 5.12133C15.256 5.11987 15.2219 5.13164 15.1954 5.15426C14.8768 5.42833 14.4666 5.57205 14.0466 5.55668C13.6267 5.54132 13.228 5.36801 12.9304 5.07141V5.07141Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M8.80351 4.87297L8.21387 4.28333"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.3929 7.39322L11 7"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.7176 9.78606L13.1279 9.19641"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconTicketSmall;
