import RenderIcon from '../../icons/RenderIcon';

type Props = {
  amountOfPages: number;
  activeIndex: number;
};

export const Indicators = ({ amountOfPages, activeIndex }: Props) => (
  <ul
    data-testid="carousel-page-indicators"
    className="absolute bottom-0 left-1/2 z-0 m-auto mb-3 flex -translate-x-1/2 animate-fade-in list-none gap-4 p-0 leading-none"
  >
    {Array.from({ length: amountOfPages }, (_, index) => (
      <li key={`indicator-${index}`} className="m-0">
        <RenderIcon name="ellipse" fill={index === activeIndex ? 'white' : 'none'} />
      </li>
    ))}
  </ul>
);
