import { useState, type CSSProperties } from 'react';

import { useHeaderContext } from '../../../context/HeaderContext';
import { checkIfSrcExistsInProviders, cn, generateOptimizedUrl } from '../../../utils';

type LoaderProps = {
  src: string;
  width: number;
  quality: number;
};

type ImageProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
  loading?: 'eager' | 'lazy';
  sizes?: string;
  srcSet?: string;
  className?: string;
};

type NextImageProps = {
  srcSet?: string;
  blurDataURL?: string;
  fill?: boolean;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  placeholder?: string;
  priority?: boolean;
  quality?: number;
  onLoad?: () => void;
};

type Props = NextImageProps & ImageProps;

const FALLBACK_IMAGE =
  'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format';

const Image = ({
  src = FALLBACK_IMAGE,
  srcSet,
  alt,
  width,
  height,
  style,
  loading,
  sizes,
  className,
  objectFit,
  ...props
}: Props & { dataTestId?: string }) => {
  const [error, setError] = useState(null);

  const { NextImage } = useHeaderContext();

  const sharedProps = {
    src: error ? FALLBACK_IMAGE : src,
    alt,
    // if fill is already passed, drop dimensions as required in next image
    width: !props?.fill ? width : undefined,
    height: !props?.fill ? height : undefined,
    style,
    loading,
    sizes,
    'data-testid': props?.dataTestId,
  } as const;

  if (NextImage) {
    const {
      srcSet,
      blurDataURL,
      fill,
      placeholder,
      priority,
      quality = 80,
      onLoad,
    } = props as NextImageProps;

    const loader = ({ src, width, quality }: LoaderProps) =>
      checkIfSrcExistsInProviders(src) ? generateOptimizedUrl({ src, width, quality }) : src;

    return (
      <NextImage
        {...sharedProps}
        // next12 and below
        layout={fill ? 'fill' : 'responsive'}
        objectFit={objectFit}
        // next13
        fill={fill}
        className={cn(objectFit ? styles.objectFit?.[objectFit] : undefined, className)}
        srcSet={srcSet}
        blurDataURL={blurDataURL}
        placeholder={placeholder}
        priority={priority}
        quality={quality}
        loader={loader}
        onError={setError}
        onLoad={onLoad}
      />
    );
  }

  return (
    <img
      {...sharedProps}
      srcSet={srcSet}
      className={cn(
        'w-full', // fix for safari when layout="fill" is set
        objectFit ? styles.objectFit?.[objectFit] : undefined,
        className
      )}
    />
  );
};

export default Image;

const styles = {
  objectFit: {
    // double classnames to support both without and with NextImage (e.g. without = object-cover, with = image:object-cover)
    contain: 'object-contain image:object-contain',
    cover: 'object-cover image:object-cover',
    fill: 'object-fill image:object-fill',
    none: 'object-none image:object-none',
    'scale-down': 'object-scale-down image:object-scale-down',
  },
};
