import {
  type NormalizedNavItem,
  normalizeNavigationItem,
} from '../../../../utils/normalizeNavigationItem';

import NestedMenuItem from './NestedMenuItem';

import type { HTMLAttributes } from 'react';

export type Props = {
  activeLvl2?: string | null;
  parentRoute?: string;
  parentNameList: string[];
} & NormalizedNavItem &
  HTMLAttributes<HTMLUListElement>;

/**
 * Renders a list of level 3 items in the mega navigation desktop menu.
 */
const Level3Menu = ({
  childItems,
  shopAll,
  activeLvl2,
  parentRoute,
  parentNameList = [],
  className,
}: Props) => {
  const hasChildren = Array.isArray(childItems) ? !!childItems.length : false;

  if (!hasChildren) return null;

  const parentName = parentNameList?.at(-1) ?? '';
  const hidden = activeLvl2 !== parentName;

  return (
    <ul className={className} role="list" aria-expanded={!hidden}>
      {shopAll && !!parentRoute && (
        <li aria-hidden={hidden}>
          <NestedMenuItem
            shopAllLink
            name={`Shop All ${parentName?.replace(/^[aA]ll /, '')}`}
            route={parentRoute}
            parentNameList={[...parentNameList, 'Shop All']}
          />
        </li>
      )}
      {childItems?.map((item, index: number) => {
        const fmtLvl3 = normalizeNavigationItem(item);
        const itemName = fmtLvl3?.nameSEO ?? fmtLvl3?.name;

        return (
          <li key={`${fmtLvl3.name} - ${index}`} aria-hidden={hidden}>
            <NestedMenuItem {...fmtLvl3} parentNameList={[...parentNameList, itemName]} />
          </li>
        );
      })}
    </ul>
  );
};

export default Level3Menu;
