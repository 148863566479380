import React, { type SVGProps } from 'react';

type Props = {
  type?: 'facebook' | 'instagram' | 'twitter' | 'youtube';
} & SVGProps<SVGSVGElement>;

const IconSocial = ({
  className = '',
  width = '1em',
  height = '1em',
  fill = '#ffffff',
  type,
  ...rest
}: Props) => {
  if (type === 'facebook') {
    return (
      <svg
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox="0 0 19 19"
        {...rest}
      >
        <path d="M19 9.7C19 4.563 14.838.4 9.7.4A9.298 9.298 0 0 0 .4 9.7c0 4.65 3.375 8.512 7.838 9.188V12.4H5.875V9.7h2.363V7.675c0-2.325 1.387-3.637 3.487-3.637 1.05 0 2.1.187 2.1.187v2.288h-1.162c-1.163 0-1.538.712-1.538 1.462V9.7h2.588l-.413 2.7h-2.175v6.487C15.588 18.212 19 14.35 19 9.7Z" />
      </svg>
    );
  } else if (type === 'instagram') {
    return (
      <svg
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox="0 0 18 18"
        {...rest}
      >
        <path d="M9.019 4.669c-2.4 0-4.313 1.95-4.313 4.312a4.29 4.29 0 0 0 4.313 4.313A4.313 4.313 0 0 0 13.33 8.98c0-2.362-1.95-4.312-4.312-4.312Zm0 7.125A2.809 2.809 0 0 1 6.206 8.98c0-1.537 1.238-2.775 2.813-2.775a2.769 2.769 0 0 1 2.775 2.775c0 1.575-1.238 2.813-2.775 2.813Zm5.475-7.275c0-.563-.45-1.013-1.013-1.013-.562 0-1.012.45-1.012 1.013 0 .562.45 1.012 1.012 1.012.563 0 1.013-.45 1.013-1.012Zm2.85 1.012c-.075-1.35-.375-2.55-1.35-3.525-.975-.975-2.175-1.275-3.525-1.35-1.388-.075-5.55-.075-6.938 0-1.35.075-2.512.375-3.525 1.35-.975.975-1.275 2.175-1.35 3.525-.075 1.388-.075 5.55 0 6.938.075 1.35.375 2.512 1.35 3.525 1.013.975 2.175 1.275 3.525 1.35 1.388.075 5.55.075 6.938 0 1.35-.075 2.55-.375 3.525-1.35.975-1.013 1.275-2.175 1.35-3.525.075-1.388.075-5.55 0-6.938Zm-1.8 8.4c-.263.75-.863 1.313-1.575 1.613-1.125.45-3.75.337-4.95.337-1.238 0-3.863.113-4.95-.337a2.843 2.843 0 0 1-1.613-1.613c-.45-1.087-.337-3.712-.337-4.95 0-1.2-.113-3.825.337-4.95.3-.712.863-1.275 1.613-1.575 1.087-.45 3.712-.337 4.95-.337 1.2 0 3.825-.113 4.95.337.712.263 1.275.863 1.575 1.575.45 1.125.337 3.75.337 4.95 0 1.238.113 3.863-.337 4.95Z" />
      </svg>
    );
  } else if (type === 'twitter') {
    return (
      <svg
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox="0 0 20 17"
        {...rest}
      >
        <path d="M17.613 4.663a8.34 8.34 0 0 0 1.95-2.025 7.38 7.38 0 0 1-2.25.6 3.847 3.847 0 0 0 1.725-2.175 8.33 8.33 0 0 1-2.476.975A3.938 3.938 0 0 0 13.675.8a3.937 3.937 0 0 0-3.937 3.938c0 .3.037.6.112.9a11.424 11.424 0 0 1-8.137-4.125c-.338.562-.525 1.237-.525 1.987 0 1.35.675 2.55 1.762 3.263-.637-.038-1.275-.188-1.8-.488v.038a3.942 3.942 0 0 0 3.15 3.862c-.3.075-.675.15-1.012.15-.263 0-.488-.037-.75-.075.487 1.575 1.95 2.7 3.675 2.738a7.916 7.916 0 0 1-4.875 1.687C1 14.675.7 14.637.4 14.6c1.725 1.125 3.788 1.763 6.038 1.763 7.237 0 11.175-5.963 11.175-11.175v-.525Z" />
      </svg>
    );
  } else if (type === 'youtube') {
    return (
      <svg
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox="0 0 20 14"
        {...rest}
      >
        <path d="M19.179 2.097A2.362 2.362 0 0 0 17.494.413C15.984 0 10.018 0 10.018 0S4.015 0 2.506.412A2.362 2.362 0 0 0 .821 2.098C.4 3.54.4 6.634.4 6.634s0 3.06.421 4.538c.21.825.878 1.444 1.685 1.65 1.51.378 7.512.378 7.512.378s5.967 0 7.476-.378c.807-.206 1.474-.825 1.685-1.65.421-1.478.421-4.538.421-4.538s0-3.093-.421-4.537ZM8.052 9.419V3.85l4.984 2.784L8.052 9.42Z" />
      </svg>
    );
  }
  return null;
};

export default IconSocial;
