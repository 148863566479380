import type { SvgElementProps } from '../../../types';

const IconGiftSmall = ({
  width = 17,
  height = 16,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14 7.5V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3.5C3.23478 14 2.98043 13.8946 2.79289 13.7071C2.60536 13.5196 2.5 13.2652 2.5 13V7.5M8 3.25C8 2.90388 7.89736 2.56554 7.70507 2.27775C7.51278 1.98997 7.23947 1.76566 6.9197 1.63321C6.59993 1.50076 6.24806 1.4661 5.90859 1.53363C5.56913 1.60115 5.25731 1.76782 5.01256 2.01256C4.76782 2.25731 4.60115 2.56913 4.53363 2.90859C4.4661 3.24806 4.50076 3.59993 4.63321 3.9197C4.76566 4.23947 4.98997 4.51278 5.27775 4.70507C5.56554 4.89736 5.90388 5 6.25 5H8M8 3.25V5M8 3.25C8 2.90388 8.10264 2.56554 8.29493 2.27775C8.48722 1.98997 8.76053 1.76566 9.0803 1.63321C9.40007 1.50076 9.75194 1.4661 10.0914 1.53363C10.4309 1.60115 10.7427 1.76782 10.9874 2.01256C11.2322 2.25731 11.3989 2.56913 11.4664 2.90859C11.5339 3.24806 11.4992 3.59993 11.3668 3.9197C11.2343 4.23947 11.01 4.51278 10.7222 4.70507C10.4345 4.89736 10.0961 5 9.75 5H8M8 5V14M2.25 7.5H14.25C14.664 7.5 15 7.164 15 6.75V5.75C15 5.336 14.664 5 14.25 5H2.25C1.836 5 1.5 5.336 1.5 5.75V6.75C1.5 7.164 1.836 7.5 2.25 7.5Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconGiftSmall;
