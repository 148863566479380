import { useEffect, useState } from 'react';

import { Cookie } from '../../../enums';
import { dismissPromoBannerToDataLayer, handleSessionStorage } from '../../../utils/';
import PromoBanner from '../../common/ui/PromoBanner';

import type { HeaderProps } from '../../../types';

type Props = {
  /**
   * Promo banners data from the nav service
   */
  promoBanners: HeaderProps['navigation']['promoBanner'];
};

const PromoBanners = ({ promoBanners }: Props) => {
  const [promoDismissed, setPromoDismissed] = useState(false);
  const { setValue, getValue } = handleSessionStorage();

  const { default: defaultPromoBanner, dismissible: dismissiblePromoBanner } = promoBanners || {};

  const handlePromoDismiss = () => {
    dismissPromoBannerToDataLayer();
    setPromoDismissed(true);
    setValue(Cookie.PromoDismissed.toString(), true);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const promoDismissedCookie = getValue(Cookie.PromoDismissed.toString());
    if (promoDismissedCookie) {
      setPromoDismissed(true);
    }
  }, []);

  const hasDefaultBanner = Array.isArray(defaultPromoBanner?.portableText)
    ? !!defaultPromoBanner.portableText.length
    : !!defaultPromoBanner?.portableText;
  const hasDismissableBanner = Array.isArray(dismissiblePromoBanner?.portableText)
    ? !!dismissiblePromoBanner.portableText.length
    : !!dismissiblePromoBanner?.portableText;

  if (!hasDefaultBanner && !hasDismissableBanner) return null;

  return (
    <div className="relative hidden lg:block">
      {!!hasDefaultBanner && (
        <PromoBanner
          portableText={defaultPromoBanner?.portableText}
          variation={defaultPromoBanner?.variation}
          alignContent={defaultPromoBanner?.alignContent}
          dismissed={!promoDismissed}
        />
      )}
      {!!hasDismissableBanner && (
        <PromoBanner
          portableText={dismissiblePromoBanner?.portableText}
          variation={dismissiblePromoBanner?.variation}
          alignContent={dismissiblePromoBanner?.alignContent}
          dismissed={promoDismissed}
          dismissible
          handleDismiss={handlePromoDismiss}
        />
      )}
    </div>
  );
};

export default PromoBanners;
