import { useEffect, useState } from 'react';

export const useViewportSize = (defaultValue = 0): number => {
  const [width, setWidth] = useState<number>(defaultValue);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => setWidth(window.innerWidth);
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};
