import { useEffect, useRef, useState } from 'react';

type UseStickyElementBottom = (opts?: {
  initial?: boolean;
  debounceDelay?: number;
  bottomOffset?: number;
}) => {
  isSticky: boolean;
  ref: React.RefObject<HTMLDivElement>;
};

/**
 * Custom hook that determines if an element is sticky at the bottom of the viewport.
 *
 * @param {boolean} [initial=false] - Initial sticky state.
 * @param {number} [debounceDelay=100] - Delay in milliseconds for the scroll event handler.
 * @returns {{ isSticky: boolean, ref: React.RefObject<HTMLDivElement> }} - An object containing the sticky state and a ref to be attached to the element.
 *
 * @example
 * const { isSticky, ref } = useStickyElementBottom();
 *
 * return (
 *   <div ref={ref}>
 *     {isSticky ? 'I am sticky!' : 'I am not sticky!'}
 *   </div>
 * );
 */
export const useStickyElementBottom: UseStickyElementBottom = ({
  initial = false,
  debounceDelay = 100,
  bottomOffset = 0,
}) => {
  const [isSticky, setIsSticky] = useState(initial);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    function throttle(fn: () => void, wait: number) {
      let timeout: number | null = null;
      return () => {
        if (timeout) return;
        timeout = window.setTimeout(() => {
          fn();
          timeout = null;
        }, wait);
      };
    }

    function handleScroll() {
      if (!ref?.current) return;
      const height = window.innerHeight;
      const { bottom } = ref.current.getBoundingClientRect();

      setIsSticky(bottom + bottomOffset >= height);
    }
    handleScroll();

    const throttledHandleScroll = throttle(handleScroll, debounceDelay);

    window.addEventListener('scroll', throttledHandleScroll);
    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, []);

  return { isSticky, ref };
};
