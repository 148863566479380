import type { SvgElementProps } from '../../../types';

const IconMobileCompass = ({
  width = 32,
  height = 32,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    {...rest}
  >
    <g clip-path="url(#clip0_933_388)">
      <path
        d="M14 27.2882C21.3389 27.2882 27.2882 21.3389 27.2882 14C27.2882 6.66121 21.3389 0.711914 14 0.711914C6.66121 0.711914 0.711914 6.66121 0.711914 14C0.711914 21.3389 6.66121 27.2882 14 27.2882Z"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.9028 14H24.9625"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.05615 14H5.1253"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14 5.1539V3.09424"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14 24.9627V22.9031"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.09482 20.7542L11.7891 11.9913L15.8577 16.0599L7.09482 20.7542Z"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.7543 7.09476L15.9738 15.9439L11.9051 11.8753L20.7543 7.09476Z"
        stroke={themeColor || stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_933_388">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMobileCompass;
