/**
 * Parses cookies into a Map for convenience.
 */
export function getCookieMap(cookieString?: string): Map<string, string> {
  const cookie = cookieString || global.document?.cookie;
  const cookieMap = new Map<string, string>();

  if (!cookie) return cookieMap;

  const cookies = cookie.split(';');

  return cookies.reduce((acc, cookie) => {
    const key = cookie.substring(0, cookie.indexOf('='));
    const value = cookie.substring(cookie.indexOf('=') + 1);
    acc.set(key.trim(), value.trim());
    return acc;
  }, cookieMap);
}

type SetDomainCookieOpts = {
  maxAge?: number;
  isSecure?: boolean;
};

const DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;

/**
 * Sets a cookie for a specific domain (controlled via env).
 *
 * Useful for e.g. setting a cookie explicitly for a main domain instead of a
 * subdomain (e.g. virginexperiencedays.co.uk instead of
 * myaccount.virginexperiencedays.co.uk) to share data.
 */
export function setDomainCookie(key: string, value: string, opts?: SetDomainCookieOpts) {
  const {
    maxAge = 2592000, // default 30 days
    isSecure = true,
  } = opts ?? {};
  let cookieString = `${key}=${value}; max-age=${maxAge}; path=/${isSecure ? '; Secure;' : ''}`;
  if (DOMAIN) cookieString = `${cookieString}; domain=${DOMAIN}`;
  document.cookie = cookieString;
}

type CookieOpts = {
  secure?: boolean;
  domain?: string;
  expires?: string;
  maxAge?: number;
  httpOnly?: boolean;
};

/**
 * Computes the cookie string with the given {@link CookieOpts}.
 *
 * Useful for e.g. setting a cookie explicitly for a main domain instead of a
 * subdomain (e.g. virginexperiencedays.co.uk instead of
 * myaccount.virginexperiencedays.co.uk) to share data.
 *
 * NOTE: Make sure not to use any user-provided values and string opts, as they
 * get injected directly into the RFC 6265 cookie string.
 */
export function serializeCookie(key: string, value: string, opts?: CookieOpts): string {
  const { secure, domain, expires, maxAge, httpOnly } = opts ?? {};
  let cookieString = `${key}=${value}; Path=/`;
  if (expires) cookieString = `${cookieString}; Expires=${expires}`;
  if (!expires && maxAge) cookieString = `${cookieString}; Max-Age=${maxAge}`;
  if (domain) cookieString = `${cookieString}; Domain=${domain}`;
  if (secure) cookieString = `${cookieString}; SameSite=Lax; Secure`;
  if (httpOnly) cookieString = `${cookieString}; HttpOnly`;
  return cookieString;
}
