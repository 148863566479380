import type { SvgElementProps } from '../../../types';

const IconMobileLotusFlower = ({
  width = 31,
  height = 23,
  stroke = '#2B3240',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.5 8.15894C1.5 11.872 2.975 15.4329 5.6005 18.0584C8.22601 20.6839 11.787 22.1589 15.5 22.1589C19.213 22.1589 22.774 20.6839 25.3995 18.0584C28.025 15.4329 29.5 11.872 29.5 8.15894"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 22.1589C15.5 18.4459 16.975 14.8849 19.6005 12.2594C22.226 9.63393 25.787 8.15894 29.5 8.15894"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 8.15894C3.33851 8.15894 5.15901 8.52106 6.85757 9.22462C8.55613 9.92819 10.0995 10.9594 11.3995 12.2594C12.6995 13.5595 13.7307 15.1028 14.4343 16.8014C15.1379 18.4999 15.5 20.3204 15.5 22.1589"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 1.15881C16.9109 2.50569 18.0151 4.1407 18.7374 5.95258C19.4597 7.76446 19.7833 9.71068 19.686 11.6588"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3113 11.6588C11.2141 9.71068 11.5376 7.76446 12.2599 5.95258C12.9823 4.1407 14.0864 2.50569 15.4973 1.15881"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.78711 2.69556C6.73714 2.65029 8.67404 3.02565 10.466 3.79607C11.0453 4.04514 11.6051 4.33361 12.1416 4.65884"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8858 8.37628C5.11537 6.58431 4.74002 4.64742 4.78528 2.69738"
      stroke="#2B3240"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9092 2.29272C26.2928 4.2052 26.2598 6.17786 25.8127 8.07646"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9068 2.29138C23.9786 2.58581 22.1364 3.29216 20.5057 4.36237C19.7936 4.82965 19.7567 4.88966 19.1477 5.47811"
      stroke={themeColor || stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobileLotusFlower;
