import type { SvgElementProps } from '../../../types';

const IconTeacupSmall = ({
  width = 17,
  height = 16,
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4C1 8.0501 3 11.3333 7 11.3333C11 11.3333 13 8.0501 13 4H1Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.397 7.5408C12.7269 6.6795 12.9215 5.7123 12.9807 4.68354C13.0958 4.6723 13.2136 4.6665 13.3335 4.6665C14.6222 4.6665 15.6668 5.33807 15.6668 6.1665C15.6668 6.99494 14.6222 7.6665 13.3335 7.6665C13.0004 7.6665 12.6836 7.62164 12.397 7.5408Z"
      stroke={themeColor || fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 13.5H11" stroke={themeColor || fill} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default IconTeacupSmall;
