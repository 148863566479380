import Container from '../../../components/common/Container';
import { cn } from '../../../utils';

import HelpLinks from './HelpLinks';
import Logo from './Logo';
import SocialIcons from './SocialIcons';

import type { PersistentFooterProps } from '../../../types';

export const PersistentFooter = ({ helpLinks, copyright }: PersistentFooterProps) => (
  <div className="relative grow bg-primary-700">
    <Container className="flex grow items-center justify-between">
      <div className="flex grow flex-col items-center bg-primary-700 px-0 pb-20 pt-12 text-primary-100 lg:h-[120px] lg:flex-row lg:p-0">
        <Logo />
        <div className="order-2 flex grow flex-col">
          <p className={cn(style.copyRightBlock, 'hidden lg:flex')}>{copyright}</p>
          <HelpLinks items={helpLinks} />
        </div>
        <SocialIcons />
        <p className={cn(style.copyRightBlock, 'flex lg:hidden')}>{copyright}</p>
      </div>
    </Container>
  </div>
);

const style = {
  copyRightBlock:
    'order-4 m-0 mt-8 justify-center text-xs leading-none lg:order-1 lg:mt-0 lg:h-1/2 lg:items-end lg:justify-start lg:pb-2',
};
