import type { SvgElementProps } from '../../../types';

const IconCross = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.576063 0.575751C0.688579 0.463269 0.841164 0.400079 1.00026 0.400079C1.15936 0.400079 1.31195 0.463269 1.42446 0.575751L4.00026 3.15155L6.57606 0.575751C6.63141 0.518445 6.69762 0.472736 6.77082 0.44129C6.84402 0.409845 6.92275 0.393293 7.00242 0.392601C7.08209 0.391908 7.1611 0.407089 7.23483 0.437258C7.30857 0.467426 7.37556 0.511978 7.4319 0.568314C7.48823 0.62465 7.53279 0.691641 7.56295 0.765379C7.59312 0.839116 7.6083 0.918124 7.60761 0.997792C7.60692 1.07746 7.59037 1.15619 7.55892 1.22939C7.52748 1.3026 7.48177 1.3688 7.42446 1.42415L4.84866 3.99995L7.42446 6.57575C7.53376 6.68891 7.59423 6.84047 7.59287 6.99779C7.5915 7.15511 7.5284 7.3056 7.41715 7.41684C7.30591 7.52809 7.15542 7.59119 6.9981 7.59256C6.84078 7.59392 6.68922 7.53345 6.57606 7.42415L4.00026 4.84835L1.42446 7.42415C1.3113 7.53345 1.15974 7.59392 1.00242 7.59256C0.845104 7.59119 0.694616 7.52809 0.583371 7.41684C0.472126 7.3056 0.409025 7.15511 0.407658 6.99779C0.406291 6.84047 0.466768 6.68891 0.576063 6.57575L3.15186 3.99995L0.576063 1.42415C0.46358 1.31163 0.400391 1.15905 0.400391 0.999951C0.400391 0.840853 0.46358 0.688268 0.576063 0.575751Z"
      fill={themeColor || fill}
    />
  </svg>
);

export default IconCross;
