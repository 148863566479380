import { type Dispatch, type FormEvent, useMemo, useCallback } from 'react';
import { type TrackingData, addToDataLayer } from '../../../utils/addToDataLayer';
import type { NextRouter } from 'next/router';
import { Actions, type GiftFinderState, type ActionType, QuizOption } from '../types';

type ReopenOptions = { push?: boolean; path?: string };

export type ReturnType = {
  finalStep: boolean;
  resultsParams: string;
  handleClose: () => void;
  handleOpen: (location: string, trackingOptions?: Partial<TrackingData>) => void;
  handleTrackSwipable: (payload: boolean) => void;
  handleNextStep: (e: FormEvent) => void;
  handlePrevStep: (e: FormEvent) => void;
  handleSetStep: (step: number) => void;
  handleSetSkipScrollCallback: (shouldSkip: boolean) => void;
  handleAnswer: (e: FormEvent, answerOverride?: QuizOption['uuid']) => void;
  handleSubmit: (e: FormEvent) => void;
  handleReopenQuiz: (location: string, options?: ReopenOptions) => void;
  handleDeleteResultsCookie: (resultsCookieName: string) => void;
};

/**
 * Custom hook for handling the state and actions of the gift finder form.
 * @param router - The NextJS router object.
 * @param state - The current state of the gift finder form from context.
 * @param dispatch - The dispatch function for updating the state of the gift finder form from context.
 * @returns An object containing functions for handling the gift finder form actions.
 */
export const useGiftFinder = (
  state: GiftFinderState,
  dispatch: Dispatch<ActionType>,
  router: NextRouter
): ReturnType => {
  const { asPath } = router ?? {};

  const { quiz, answers, step, resultsCookieName } = state;
  const finalStep = step === quiz?.length - 1;

  /**
   * Resets the error state to null.
   */
  const resetError = () => dispatch({ type: Actions.CLEAR_ERRORS, payload: {} });

  /**
   * Closes the gift finder modal by dispatching an action to set the active state to false.
   */
  const handleClose = () => {
    dispatch({ type: Actions.CLEAR_ANSWERS });
    dispatch({ type: Actions.SET_ACTIVE, payload: false });
  };

  /**
   * Handles tracking of user progress in the Gift Finder quiz.
   */
  const handleTrackStep = (currentStep: number, totalSteps: number, uuid: string) => {
    addToDataLayer({
      event: 'genericEvent',
      eventCategory: 'Gift Finder',
      eventAction: 'Quiz Stage',
      eventLabel: `${currentStep} of ${totalSteps} - ${uuid}`,
    });
  };

  /**
   * Handles tracking of user answers in the Gift Finder quiz.
   */
  const handleTrackAnswer = (name: string, answer: string) => {
    addToDataLayer({
      event: 'genericEvent',
      eventCategory: 'Gift Finder',
      eventAction: `Logging - ${name}`,
      eventLabel: answer,
    });
  };

  /**
   * Handles tracking of user opening the Gift Finder quiz.
   */
  const handleTrackOpen = (location: string, trackingOptions: Partial<TrackingData> = {}) => {
    const trackingData = {
      event: 'userInteraction',
      eventCategory: 'Gift Finder',
      eventAction: 'Clicked Start Gift Finder',
      eventLabel: `${asPath} - ${location}`,
    };

    addToDataLayer({ ...trackingData, ...trackingOptions });
  };

  /**
   * Handles tracking of user submission in the Gift Finder quiz.
   */
  const handleTrackSubmit = () => {
    addToDataLayer({
      event: 'userInteraction',
      eventCategory: 'Gift Finder',
      eventAction: 'Clicked See Gift Finder Results',
      eventLabel: '',
    });
  };

  /**
   * Opens the gift finder modal by dispatching an action to set the active state to true.
   */
  const handleOpen = (location: string, trackingOptions: Partial<TrackingData> = {}) => {
    handleTrackOpen(location, trackingOptions);
    dispatch({ type: Actions.SET_ACTIVE, payload: true });
  };

  /**
   * Handles reopening the gift finder quiz by clearing the domain cookie, clearing the answers,
   * tracking the event, and redirecting to the quiz page.
   */
  const handleReopenQuiz = (location: string, options?: ReopenOptions) => {
    const defaultOptions: ReopenOptions = { push: true, path: '/gift-finder' };
    const opts = { ...defaultOptions, ...(options ?? {}) };

    handleDeleteResultsCookie(resultsCookieName);
    handleTrackReopen(location);
    dispatch({ type: Actions.CLEAR_ANSWERS });

    const { push, path } = opts;

    if (!!path && push) router.push(path);
  };

  /**
   * Handles tracking of user reopening the Gift Finder quiz.
   */
  const handleTrackReopen = (position: string) => {
    addToDataLayer({
      event: 'userInteraction',
      eventCategory: 'Gift Finder',
      eventAction: 'Clicked Restart Quiz',
      eventLabel: `area of page ${position}`,
    });
  };

  /**
   * Sets a cookie with the given search parameters for Gift Finder results.
   */
  const handleSetResultsCookie = (params: string, resultsCookieName: string): void => {
    if (!resultsCookieName) {
      console.error('[useGiftFinder]: Missing Gift Finder results cookie name');
      return;
    }

    if (typeof document !== 'undefined' && document?.cookie !== undefined) {
      // cookie options
      const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
      const isSecure =
        process.env.NODE_ENV == null || process.env.NODE_ENV === 'development' ? '' : 'Secure;';
      const cookiePath = '/';

      // cookie value
      const cookieOptions = `max-age=${thirtyDaysInSeconds}; ${isSecure} path=${cookiePath}`;
      const cookieValue = `${resultsCookieName}=${params}; ${cookieOptions}`;

      // set cookie
      document.cookie = cookieValue;
    } else {
      console.error('[useGiftFinder]: Browser does not support document.cookie');
    }
  };

  /**
   * Deletes the Gift Finder results cookie.
   */
  const handleDeleteResultsCookie = (resultsCookieName: string): void => {
    // check if the cookie exists
    const cookies = document?.cookie.split(';');
    const cookieExists = cookies?.some((item) => item.trim().startsWith(resultsCookieName + '='));

    if (cookieExists) {
      // cookie options
      const expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
      const isSecure =
        process.env.NODE_ENV == null || process.env.NODE_ENV === 'development' ? '' : 'Secure;';
      const cookiePath = '/';

      // cookie value
      const cookieOptions = `${expires}; ${isSecure} path=${cookiePath}`;
      const cookieValue = `${resultsCookieName}=; ${cookieOptions}`;

      // delete cookie
      document.cookie = cookieValue;
    } else {
      console.error('[useGiftFinder]: Gift Finder results cookie does not exist');
    }
  };

  /**
   * Handles the next step in the gift finder quiz including validation.
   */
  const handleNextStep = (e: FormEvent) => {
    e.preventDefault();
    resetError();

    const stepData = quiz?.[step];
    const { uuid, step: quizStep } = stepData;

    handleTrackStep(quizStep, quiz.length, uuid);
    dispatch({ type: Actions.NEXT_STEP });
  };

  /**
   * Handles the previous step action in the gift finder form.
   */
  const handlePrevStep = (e: FormEvent) => {
    e.preventDefault();
    resetError();
    dispatch({ type: Actions.PREV_STEP });
  };

  /**
   * Handles jumping to step in the gift finder form.
   */
  const handleSetStep = (step: number) => {
    resetError();
    dispatch({ type: Actions.SET_STEP, payload: step });
  };

  /**
   * Handles flag for when to skip invoking the scroll callback (useInView hook)
   * to avoid multiple triggers to the callback (e.g. when jumping to step)
   */
  const handleSetSkipScrollCallback = (shouldSkip: boolean) => {
    dispatch({ type: Actions.SET_SKIP_SCROLL_CALLBACK, payload: shouldSkip });
  };

  /**
   * Handles the quiz card track whether to enable/disable touch swipe
   * @param payload - boolean flag to enable/disable
   */
  const handleTrackSwipable = (payload: boolean) => {
    dispatch({ type: Actions.SET_TRACK_SWIPABLE, payload });
  };

  /**
   * Handles the form submission event and dispatches the appropriate action based on the input type.
   */
  const handleAnswer = useCallback((e: FormEvent, answerOverride?: QuizOption['uuid']) => {
    resetError();

    const target = e.target as HTMLInputElement;
    const { type, name, value } = target;

    const payload = { name, value };

    switch (type) {
      case 'radio':
        if (!answerOverride) {
          dispatch({ type: Actions.CLEAR_SINGLE_ANSWER, payload });
          break;
        }

        dispatch({
          type: Actions.SET_SINGLE_ANSWER,
          payload: { ...payload, value: answerOverride || payload },
        });
        break;
      case 'checkbox':
        dispatch({ type: Actions.SET_MULTIPLE_ANSWER, payload });
        break;
      case 'range':
        dispatch({ type: Actions.SET_RANGE_ANSWER, payload });
        break;
      default:
        break;
    }
  }, []);

  /**
   * generate results page query parameters
   */
  const resultsParams: string = useMemo(() => {
    const params = new URLSearchParams();

    Object.entries(answers).forEach(([key, value]) => {
      if (value?.length) {
        const values: string = value.join(',');
        params.set(key, values);
      } else {
        console.error('[useGiftFinder]: Gift Finder answer values must be an array');
      }
    });

    const paramsString = params.toString();
    return paramsString ? `?${paramsString}` : '';
  }, [answers]);

  /**
   * Handles the form submission for the gift finder and either pushes the user to the
   * required route using the NextJS router or returns the formatted string.
   */
  const handleSubmit = useCallback((): void => {
    resetError();
    handleSetResultsCookie(resultsParams, resultsCookieName);

    const finalStepData = quiz.at(-1);
    handleTrackStep(finalStepData.step, quiz.length, finalStepData.uuid);

    let trackingAnswer = '';

    quiz.forEach((question) => {
      if (question.componentProps.type === 'range') {
        const fromAnswer = answers?.[`${question.uuid}.from`];
        const toAnswer = answers?.[`${question.uuid}.to`];

        trackingAnswer = `${fromAnswer} - ${toAnswer}`;
      } else {
        const answer = answers?.[question.uuid];

        trackingAnswer = answer?.join(', ') || '';
      }

      handleTrackAnswer(question.uuid, trackingAnswer);
    });

    handleTrackSubmit();
    dispatch({ type: Actions.CLEAR_ANSWERS });
  }, [answers, quiz, resultsParams]);

  return {
    finalStep,
    resultsParams,
    handleClose,
    handleOpen,
    handleTrackSwipable,
    handleNextStep,
    handlePrevStep,
    handleSetStep,
    handleSetSkipScrollCallback,
    handleAnswer,
    handleSubmit,
    handleReopenQuiz,
    handleDeleteResultsCookie,
  };
};
