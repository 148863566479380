import type { SvgElementProps } from '../../../../types';

const IconMobileCamera = ({
  width = '1em',
  height = '1em',
  fill = 'var(--text-neutral)',
  themeColor,
  strokeWidth = '1.5',
  ...rest
}: SvgElementProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.75549 4.98991C5.60779 5.22368 5.41072 5.42231 5.17812 5.57185C4.94552 5.72139 4.68302 5.81822 4.40903 5.85555C4.09723 5.89986 3.7879 5.94745 3.47856 5.99914C2.61456 6.14273 2 6.90335 2 7.77884V14.6925C2 15.1821 2.1945 15.6517 2.54073 15.9979C2.88695 16.3441 3.35652 16.5386 3.84615 16.5386H16.1538C16.6435 16.5386 17.1131 16.3441 17.4593 15.9979C17.8055 15.6517 18 15.1821 18 14.6925V7.77884C18 6.90335 17.3846 6.14273 16.5214 5.99914C16.2119 5.94756 15.9017 5.8997 15.591 5.85555C15.3171 5.81811 15.0548 5.72122 14.8223 5.57169C14.5899 5.42215 14.3929 5.22359 14.2453 4.98991L13.5709 3.91012C13.4194 3.66404 13.2109 3.45809 12.9629 3.3097C12.715 3.1613 12.4349 3.07483 12.1465 3.0576C10.7165 2.9808 9.28348 2.9808 7.85354 3.0576C7.56509 3.07483 7.28504 3.1613 7.0371 3.3097C6.78915 3.45809 6.58061 3.66404 6.42913 3.91012L5.75549 4.98991Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6922 10.3848C13.6922 11.3641 13.3032 12.3032 12.6108 12.9957C11.9183 13.6881 10.9792 14.0771 9.99992 14.0771C9.02066 14.0771 8.08151 13.6881 7.38907 12.9957C6.69663 12.3032 6.30762 11.3641 6.30762 10.3848C6.30762 9.40555 6.69663 8.4664 7.38907 7.77396C8.08151 7.08152 9.02066 6.6925 9.99992 6.6925C10.9792 6.6925 11.9183 7.08152 12.6108 7.77396C13.3032 8.4664 13.6922 9.40555 13.6922 10.3848ZM15.5384 8.53866H15.5449V8.54522H15.5384V8.53866Z"
      stroke={themeColor || fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMobileCamera;
