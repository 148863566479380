import { useEffect, useState } from 'react';

export const useMediaQuery = (query: number): boolean => {
  const queryString = `(min-width: ${query})`;
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const matchMedia = window.matchMedia(queryString);
    const handleChange = () => setMatches(!!matchMedia.matches);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia?.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
    } else {
      // For Safari and IE support
      matchMedia.addListener(handleChange);
    }

    return () => matchMedia.removeEventListener('change', handleChange);
  }, [queryString]);

  return matches;
};
