export const sizeConfig = {
  mobile: {
    full: 'children:w-full',
    default: 'children:w-[calc(100%_-_80px)] children:max-w-[278px] children:min-w-none',
    small: 'children:w-[calc(60%_-_16px)] children:max-w-[256px] children:min-w-[160px]',
    large: 'children:w-[calc(80%_-_16px)] children:max-w-[320px] children:min-w-[256px]',
  },
  tablet: {
    1: 'children:w-full',
    2: 'children:md:w-[calc((100%_-_16px)_/_2)] children:md:max-w-none children:md:min-w-none',
    3: 'children:md:w-[calc((100%_-_32px)_/_3)] children:md:max-w-none children:md:min-w-none',
    4: 'children:md:w-[calc((100%_-_48px)_/_4)] children:md:max-w-none children:md:min-w-none',
    5: 'children:md:w-[calc((100%_-_64px)_/_5)] children:md:max-w-none children:md:min-w-none',
    6: 'children:md:w-[calc((100%_-_80px)_/_6)] children:md:max-w-none children:md:min-w-none',
  },
  desktop: {
    1: 'children:w-full',
    2: 'children:lg:w-[calc((100%_-_16px)_/_2)] children:lg:max-w-none children:lg:min-w-none',
    3: 'children:lg:w-[calc((100%_-_32px)_/_3)] children:lg:max-w-none children:lg:min-w-none',
    4: 'children:lg:w-[calc((100%_-_48px)_/_4)] children:lg:max-w-none children:lg:min-w-none',
    5: 'children:lg:w-[calc((100%_-_64px)_/_5)] children:lg:max-w-none children:lg:min-w-none',
    6: 'children:lg:w-[calc((100%_-_80px)_/_6)] children:lg:max-w-none children:lg:min-w-none',
  },
  wide: {
    1: 'children:w-full',
    2: 'children:xl:w-[calc((100%_-_16px)_/_2)]',
    3: 'children:xl:w-[calc((100%_-_32px)_/_3)]',
    4: 'children:xl:w-[calc((100%_-_48px)_/_4)]',
    5: 'children:xl:w-[calc((100%_-_64px)_/_5)]',
    6: 'children:xl:w-[calc((100%_-_80px)_/_6)]',
    7: 'children:xl:w-[calc((100%_-_96px)_/_7)]',
    8: 'children:xl:w-[calc((100%_-_112px)_/_8)]',
  },
};
