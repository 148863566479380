import {
  forwardRef,
  useRef,
  useEffect,
  ReactNode,
  ComponentPropsWithRef,
  useImperativeHandle,
} from 'react';

import { useInView } from '../../../../hooks/useInView';
import { cn } from '../../../../utils';

type Props = ComponentPropsWithRef<'li'> & {
  children: ReactNode;
  index?: number;
  handleCurrentIndex?: (index: number) => void;
  disabledObserver?: boolean;
  isProductCarousel?: boolean;
  className?: string;
};

export const Item = forwardRef(
  (
    {
      children,
      index,
      handleCurrentIndex,
      disabledObserver,
      isProductCarousel,
      className,
      ...props
    }: Props,
    forwardedRef
  ) => {
    const internalRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedRef = useRef<any>(null);

    // Update mergedRef whenever either forwardedRef or internalRef changes
    useEffect(() => {
      if (!forwardedRef || typeof forwardedRef !== 'object') return;
      mergedRef.current = forwardedRef?.current;
    }, [forwardedRef]);

    useEffect(() => {
      if (!internalRef?.current) return;
      mergedRef.current = internalRef.current;
    }, [internalRef]);

    useInView({
      elementRef: mergedRef,
      options: { threshold: 0.9 },
      callbackFn: (intersecting) => {
        if (intersecting && index) handleCurrentIndex?.(index);
      },
      disabled: disabledObserver,
    });

    useImperativeHandle(forwardedRef, () => internalRef.current);

    return (
      <li
        ref={internalRef}
        className={cn(
          'list-none first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0',
          isProductCarousel && 'max-w-[232px] sm:max-w-[320px] md:max-w-[unset]',
          className
        )}
        {...props}
      >
        {children}
      </li>
    );
  }
);
Item.displayName = 'Item';
