import { createLinkedPath } from './createLinkedPath';

/**
 * These are minimal types, just meant as a contract between Prismic and what we
 * use in our UI.
 */
export type PrismicWebLink = {
  link_type: 'Web';
  url: string;
};
export type PrismicDocumentLink = {
  link_type: 'Document';
  type: string; // document's content type
  uid: string; // document slug
};
export type PrismicMediaLink = {
  link_type: 'Media';
  url: string;
};
export type PrismicLink = PrismicWebLink | PrismicDocumentLink | PrismicMediaLink;

/**
 * Takes a Prismic Link (as in, a field with type: "Link" on a custom type or
 * slice) and extracts the corresponding path, useful for handling "Link to
 * Document" and "Link to Media" uniformly.
 */
export function getPrismicLinkPath(link: PrismicLink): string | null {
  switch (link?.link_type) {
    case 'Web':
      return link.url;
    case 'Document':
      return createLinkedPath(link.type, link.uid);
    case 'Media':
      return link.url;
    default:
      return null;
  }
}
